<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :defaults.sync="defaults"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportRanking()">导出
        </el-button>
      </template>
      <template slot="dateRangeSearch">
        <el-date-picker
            v-model="search.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getRankingList } from '@/api/crm/merchantcomment'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import { exportExcel } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/merchantcomment',
      form: {},
      search: {},
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        delBtn: false,
        viewBtn: false,
        editBtn: false,
        menu: false,
        selection: true,
        searchMenuSpan: 120,
        indexLabel: '序号',
        column: [
          {
            label: '所属市场',
            prop: 'deptId',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            cascader: ['merchantId'],
            search: true,
            hide: true
          },
          {
            label: '所属商户',
            prop: 'merchantId',
            type: 'select',
            filterable: true,
            search: true,
            dicUrl: '/crm/merchant/dict?deptId={{key}}',
            rules: [{
              required: true,
              message: '请输入所属商户'
            }]
          },
          {
            label: '商户用户',
            prop: 'merchantUserName',
            width: 150
          }, {
            label: '摊位',
            prop: 'boothName',
            width: 150
          },
          {
            label: '点评次数',
            prop: 'countNum',
            width: 150
          },
          {
            label: '星级评定（总星数/点评次数）',
            prop: 'aveScore'
          },
          {
            label: '时间',
            prop: 'dateRange',
            search: true,
            searchSpan: 12,
            searchSlot: true,
            hide: true,
            viewDisplay: false,
            editDisplay: false
          }
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        viewBtn: this.permission.mall_merchantcommentstat_view
      }
    },
    ...mapGetters([
      'userInfo'
    ])
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
  },
  methods: {
    onLoad () {
      if (!this.search.deptId && this.userInfo.userType !== 30) {
        this.form = null
        this.$message({
          type: 'warning',
          message: '选择所属市场后查看数据'
        })
      } else {
        if (this.search.dateRange && this.search.dateRange.length === 2) {
          this.search.start = this.search.dateRange[0]
          this.search.end = this.search.dateRange[1]
        }
        getRankingList(this.page.currentPage, this.page.pageSize, this.search).then(res => {
          const data = res.data
          this.page.total = data.total
          if (data.total === 0) {
            this.data = []
          } else {
            this.data = data.records
          }
          this.$nextTick(() => {
            this.$refs.crud.dicInit('cascader')
          })
        })
      }
    },
    exportRanking () {
      this.handleExport('商户评价统计导出')
    },
    handleExport () {
      if (!this.search.deptId) {
        this.$message({
          type: 'warning',
          message: '选择所属市场后导出数据'
        })
      } else {
        exportExcel(this.module + '/ranking', this.page.currentPage, this.page.pageSize, this.search).then(res => {
          FileSaver.saveAs(res, '商户评价排行导出' + '.xlsx')
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }
        )
      }
    },
    searchReset () {
      this.search = {
        start: undefined,
        end: undefined,
        dateRange: []
      }
      this.data = []
      this.page.total = 0
      this.onLoad(this.page)
    }
  }
}
</script>
