<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.sys_devicetime_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/devicetime',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '市场id',
            prop: 'marketId',
            rules: [{
              required: true,
              message: '请输入市场id'
            }]
          },
          {
            label: 'tenant_id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入tenant_id'
            }]
          },
          {
            label: '商户id',
            prop: 'merchantId',
            rules: [{
              required: true,
              message: '请输入商户id'
            }]
          },
          {
            label: '设备id',
            prop: 'deviceId',
            rules: [{
              required: true,
              message: '请输入设备id'
            }]
          },
          {
            label: '机器时间',
            prop: 'deviceDate',
            rules: [{
              required: true,
              message: '请输入机器时间'
            }]
          },
          {
            label: '本地时间',
            prop: 'localDate',
            rules: [{
              required: true,
              message: '请输入本地时间'
            }]
          },
          {
            label: '时间差',
            prop: 'timeDif',
            rules: [{
              required: true,
              message: '请输入时间差'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.sys_devicetime_add,
        viewBtn: this.permission.sys_devicetime_view,
        delBtn: this.permission.sys_devicetime_delete,
        editBtn: this.permission.sys_devicetime_edit
      }
    }
  },
  methods: {
  }
}
</script>
