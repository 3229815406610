import { request } from '@/util/http'

export const resetPassword = (userIds) => {
  return request({
    url: '/sys/user/reset-password',
    method: 'post',
    params: {
      userIds
    }
  })
}
export const getList = (current, size, params) => {
  return request({
    url: '/sys/user/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const remove = (ids) => {
  return request({
    url: '/sys/user/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/sys/user/submit',
    method: 'post',
    data: row
  })
}
export const initPwd = (pwd) => {
  return request({
    url: '/sys/user/init/pwd',
    method: 'post',
    params: {
      pwd
    }
  })
}

export const getUser = (id) => {
  return request({
    url: '/sys/user/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getUserInfo = (params) => {
  return request({
    url: '/sys/user/info',
    method: 'get',
    params
  })
}

export const update = (row) => {
  return request({
    url: '/sys/user/update',
    method: 'post',
    data: row
  })
}
export const updateSelf = (row) => {
  return request({
    url: '/sys/user/update/self',
    method: 'post',
    data: row
  })
}

export const updatePassword = (oldPassword, newPassword, newPassword1) => {
  return request({
    url: '/sys/user/update/password',
    method: 'post',
    params: {
      oldPassword,
      newPassword,
      newPassword1
    }
  })
}

export const isValidUsername = (username, id) => {
  return request({
    url: '/sys/user/check/username',
    method: 'get',
    params: {
      username,
      id
    }
  })
}

export const isValidMobile = (mobile, id) => {
  return request({
    url: '/sys/user/check/mobile',
    method: 'get',
    params: {
      mobile,
      id
    }
  })
}

export const bindOpen = (code, appId) => {
  return request({
    url: '/sys/user/bind/open',
    method: 'post',
    params: {
      code,
      appId
    }
  })
}
export const cancelOpen = () => {
  return request({
    url: '/sys/user/cancel/open',
    method: 'post'
  })
}
