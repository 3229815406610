<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.mall_carthistory_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/carthistory',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '数量',
            prop: 'size',
            rules: [{
              required: true,
              message: '请输入数量'
            }]
          },
          {
            label: '总价',
            prop: 'totalPrice',
            rules: [{
              required: true,
              message: '请输入总价'
            }]
          },
          {
            label: '类型：1->挂单；2->清屏',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入类型：1->挂单；2->清屏'
            }]
          },
          {
            label: '商家ID',
            prop: 'deviceId',
            rules: [{
              required: true,
              message: '请输入商家ID'
            }]
          },
          {
            label: '商家ID',
            prop: 'merchantId',
            rules: [{
              required: true,
              message: '请输入商家ID'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_carthistory_add,
        viewBtn: this.permission.mall_carthistory_view,
        delBtn: this.permission.mall_carthistory_delete,
        editBtn: this.permission.mall_carthistory_edit
      }
    }
  },
  methods: {}
}
</script>
