<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.process_task_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'process/task',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '设备名称',
            prop: 'taskName',
            rules: [{
              required: true,
              message: '请输入设备名称'
            }]
          },
          {
            label: '故障描述',
            prop: 'taskContent',
            rules: [{
              required: true,
              message: '请输入故障描述'
            }]
          },
          {
            label: '工单单号',
            prop: 'no',
            rules: [{
              required: true,
              message: '请输入工单单号'
            }]
          },
          {
            label: '审批意见',
            prop: 'comment',
            rules: [{
              required: true,
              message: '请输入审批意见'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态'
            }]
          },
          {
            label: '部门id',
            prop: 'deptId',
            rules: [{
              required: true,
              message: '请输入部门id'
            }]
          },
          {
            label: '租户id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入租户id'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.process_task_add,
        viewBtn: this.permission.process_task_view,
        delBtn: this.permission.process_task_delete,
        editBtn: this.permission.process_task_edit
      }
    }
  },
  methods: {}
}
</script>
