<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.crm_inspectfirefighting_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/inspectfirefighting',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '租户Id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入租户Id'
            }]
          },
          {
            label: '市场Id',
            prop: 'deptId',
            rules: [{
              required: true,
              message: '请输入市场Id'
            }]
          },
          {
            label: '巡检部位Id',
            prop: 'spotId',
            rules: [{
              required: true,
              message: '请输入巡检部位Id'
            }]
          },
          {
            label: '消防栓起泵按钮状态，0：不正常，1：正常',
            prop: 'hydrantButton',
            rules: [{
              required: true,
              message: '请输入消防栓起泵按钮状态，0：不正常，1：正常'
            }]
          },
          {
            label: '消防栓配套器材状态，0：故障，1：完好',
            prop: 'hydrantUniform',
            rules: [{
              required: true,
              message: '请输入消防栓配套器材状态，0：故障，1：完好'
            }]
          },
          {
            label: '灭火器压力状态，0：不正常，1：正常',
            prop: 'extinguisherPress',
            rules: [{
              required: true,
              message: '请输入灭火器压力状态，0：不正常，1：正常'
            }]
          },
          {
            label: '灭火器部件状态，0：故障，1：完好',
            prop: 'extinguisherUniform',
            rules: [{
              required: true,
              message: '请输入灭火器部件状态，0：故障，1：完好'
            }]
          },
          {
            label: '灭火器外观状态，0：故障，1：完好',
            prop: 'extinguisherSurface',
            rules: [{
              required: true,
              message: '请输入灭火器外观状态，0：故障，1：完好'
            }]
          },
          {
            label: '巡检照片',
            prop: 'pics',
            rules: [{
              required: true,
              message: '请输入巡检照片'
            }]
          },
          {
            label: '发现火灾隐患及处理状态，0：待生效，1：已生效，2：已失效',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入发现火灾隐患及处理状态，0：待生效，1：已生效，2：已失效'
            }]
          },
          {
            label: '处理措施',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入处理措施'
            }]
          },
          {
            label: '巡检日期',
            prop: 'inspectDate',
            rules: [{
              required: true,
              message: '请输入巡检日期'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_inspectfirefighting_add,
        viewBtn: this.permission.crm_inspectfirefighting_view,
        delBtn: this.permission.crm_inspectfirefighting_delete,
        editBtn: this.permission.crm_inspectfirefighting_edit
      }
    }
  },
  methods: {
  }
}
</script>
