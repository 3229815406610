<template>
  <basic-container>

    <el-form :inline="true" :model="search" class="demo-form-inline">
      <el-form-item label="电子账号：">
        <el-input v-model="search.balanceAcctId" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchChange()">查询</el-button>
        <el-button @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>

    <!-- 返=回-->
    <div class="back">
      <el-button class="back" type="success" @click="back()">返回</el-button>
    </div>
    <!--表格数据-->
    <div class="tables">
      <el-table :data="tableData" border>
        <el-table-column label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="180" align="center"/>
        <el-table-column prop="tradeId" label="系统订单号" min-width="180" align="center"/>
        <el-table-column prop="outOrderNo" label="请求单号" min-width="180" align="center"/>
        <el-table-column prop="tradeType" label="交易类型" min-width="80" align="center"/>
<!--        <el-table-column prop="txnType" label="业务类型" min-width="80" align="center"/>-->
        <el-table-column prop="settledAmount" label="可提现金额" min-width="80" align="center"/>
        <el-table-column prop="pendingAmount" label="在途金额" min-width="80" align="center"/>
        <el-table-column prop="expensingAmount" label="不可用金额" min-width="80" align="center"/>
        <el-table-column prop="settledBalance" label="交易后可提现余额" min-width="80" align="center"/>
        <el-table-column prop="pendingBalance" label="交易后在途余额" min-width="80" align="center"/>
        <el-table-column prop="expensingBalance" label="交易后不可用余额" min-width="80" align="center"/>
        <el-table-column prop="balanceAcctId" label="原系统订单号" min-width="180" align="center"/>
<!--        <el-table-column prop="origTradeId" label="原交易号" min-width="180" align="center"/>-->
<!--        <el-table-column prop="origOutOrderNo" label="流水号" min-width="180" align="center"/>-->
        <el-table-column prop="succeededAt" label="成功日期" min-width="180" align="center"/>
        <el-table-column prop="remark" label="备注" min-width="180" align="center" :show-overflow-tooltip='true'/>
      </el-table>
    </div>
    <!--分页-->
    <div class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10,20,30,50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </div>
  </basic-container>
</template>
<script>
import { selectBookRunning } from '@/api/szbank/szbank'

export default {
  data () {
    return {
      search: {
        balanceAcctId: this.$route.query.balanceAcctId,
        page: 1,
        size: 10
      },
      tableData: [],
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
      this.search.page = this.page.pageNo
      this.search.pageSize = this.page.pageSize
      this.parames = { ...this.search }
      const res = await selectBookRunning(this.parames)
      if (res.success) {
        this.page.total = res.data.total * 1
        this.tableData = res.data.ssBalanceTransactions
      }
    },
    searchChange () {
      this.loadData()
    },
    cancel () {
      this.search.balanceAcctId = undefined
      this.tableData = []
    },
    currentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.tables {
  margin-top: 15px;
}
.back {
  margin-top: 10px;
}
.pagination-container {
  margin-top: 15px;
}

</style>
