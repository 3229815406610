<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-update="updateGoodsFix"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            size="small"
            icon="el-icon-bottom"
            @click.prevent="handleExport"
        >导 出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList, submit } from '@/api/mall/goodsfix'
import FileSaver from 'file-saver'
import { exportExcel } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/goods',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        cellBtn: true,
        viewBtn: false,
        delBtn: false,
        editBtnText: '关键矫正',
        selection: true,
        menuWidth: 190,
        searchMenuSpan: 150,
        indexLabel: '序号',
        column: [
          {
            label: '所属公司',
            prop: 'tenantId',
            type: 'select',
            dataType: 'string',
            filterable: true,
            width: 130,
            dicUrl: '/sys/dept/dict/tenant',
            search: true
          },
          {
            label: '商品名称',
            prop: 'name',
            width: 130,
            search: true
          },
          {
            label: '大分类',
            prop: 'level1Id',
            type: 'select',
            cell: true,
            filterable: true,
            search: true,
            dicUrl: '/mall/level1/dict',
            cascader: ['level2Id', 'level3Id'],
            rules: [{
              required: true,
              message: '请选择大分类'
            }]
          },
          {
            label: '中分类',
            prop: 'level2Id',
            type: 'select',
            search: true,
            cell: true,
            cascader: ['level3Id'],
            dicUrl: '/mall/level2/dict?level1Id={{key}}'
          },
          {
            label: '标准名',
            prop: 'level3Id',
            type: 'select',
            search: true,
            cell: true,
            filterable: true,
            dicUrl: '/mall/level3/dict?level2Id={{key}}'
          },
          {
            label: '矫正状态',
            prop: 'fixStatus',
            type: 'select',
            dataType: 'number',
            dicUrl: '/sys/dict/code/yes_no',
            search: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goods_add,
        viewBtn: this.permission.mall_goods_view,
        delBtn: this.permission.mall_goods_delete,
        editBtn: this.permission.mall_goods_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        this.$nextTick(() => {
          this.$refs.crud.dicInit('cascader')
        })
      })
    },
    handleExport () {
      this.module = 'mall/goods/fix'
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '关联矫正导出.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    updateGoodsFix (row, index, done, loading) {
      submit(row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    }
  }
}
</script>
