<template >
  <basic-container>
    <!--搜索-->
    <el-form :inline="true" :model="search" class="demo-form-inline">
      <el-form-item label="电子账簿号：">
        <el-input v-model="search.balanceAcctId" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchChange()">查询</el-button>
        <el-button  @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>

    <!--表格数据-->
    <div class=“table” style="margin-top: 25px">
      <el-table :data="tableData" border style="width: 100%" :row-style="{height:10}">
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="eventId" label="事件号" width="180"/>
        <el-table-column prop="eventType" label="事件类型" width="180"/>
        <el-table-column prop="applicationStatus" label="进件状态" width="180"/>
        <el-table-column prop="balanceAcctId" label="电子账簿号" width="180"/>
        <el-table-column prop="failureMsgs" label="驳回消息详情" :show-overflow-tooltip="true" width="180"/>
        <el-table-column prop="outRequestNo" label="平台订单号" width="180"/>
        <el-table-column prop="mchApplicationId" label="系统订单号" width="180"/>
        <el-table-column prop="relAcctNo" label="电子账簿账簿号" width="180"/>
        <el-table-column prop="settleAcctId" label="绑定账户号" width="180"/>
        <el-table-column prop="createdTime" label="创建时间" width="180"/>
      </el-table>
    </div>
    <!--分页-->
    <div class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10,20,30,50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </div>
  </basic-container>
</template>

<script>
import { getMchNotice } from '@/api/szbank/szbank'

export default {
  data () {
    return {
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      search: {
        id: undefined,
        eventId: undefined,
        eventType: undefined,
        applicationStatus: undefined,
        failureMsgs: undefined,
        outRequestNo: undefined,
        mchApplicationId: undefined,
        balanceAcctId: undefined,
        relAcctNo: undefined,
        settleAcctId: undefined,
        createdTime: undefined,
        pageSize: 10,
        pageNo: 1
      },
      tableData: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    currentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
    async loadData () {
      this.search.pageNo = this.page.pageNo
      this.search.pageSize = this.page.pageSize
      const res = await getMchNotice(this.search)
      this.page.total = res.data.total
      this.tableData = res.data.records
    },
    // 查询
    async searchChange () {
      this.loadData()
    },
    cancel () {
      this.reset()
      this.loadData()
    },
    reset () {
      this.search = {
        id: undefined,
        eventId: undefined,
        eventType: undefined,
        applicationStatus: undefined,
        failureMsgs: undefined,
        outRequestNo: undefined,
        mchApplicationId: undefined,
        balanceAcctId: undefined,
        relAcctNo: undefined,
        settleAcctId: undefined,
        createdTime: undefined,
        pageSize: 10,
        pageNo: 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 15px;
}
</style>
