import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/mall/orderitem/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/orderitem/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/orderitem/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/orderitem/submit',
    method: 'post',
    data: row
  })
}

export const getItem = (orderSn) => {
  return request({
    url: '/mall/orderitem/itemlist',
    method: 'get',
    params: {
      orderSn
    }
  })
}

export const exportExcel = (params) => {
  return request({
    url: '/mall/orderitem/export',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

