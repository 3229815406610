<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.base_contract_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/contract',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '合同编号',
            prop: 'sn',
            rules: [{
              required: true,
              message: '请输入合同编号'
            }]
          },
          {
            label: '合同名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入合同名称'
            }]
          },
          {
            label: '合同甲方编号',
            prop: 'ownerId',
            rules: [{
              required: true,
              message: '请输入合同甲方编号'
            }]
          },
          {
            label: '合同甲方类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位',
            prop: 'ownerType',
            rules: [{
              required: true,
              message: '请输入合同甲方类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位'
            }]
          },
          {
            label: '合同已方',
            prop: 'oppositeId',
            rules: [{
              required: true,
              message: '请输入合同已方'
            }]
          },
          {
            label: '合同已方类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位',
            prop: 'oppositeType',
            rules: [{
              required: true,
              message: '请输入合同已方类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位'
            }]
          },
          {
            label: '合同标的编号',
            prop: 'objectId',
            rules: [{
              required: true,
              message: '请输入合同标的编号'
            }]
          },
          {
            label: '关联标的',
            prop: 'relativeIds',
            rules: [{
              required: true,
              message: '请输入关联标的'
            }]
          },
          {
            label: '合同标的类型，参见字典表object_type，101：摊位，501：平价菜',
            prop: 'objectType',
            rules: [{
              required: true,
              message: '请输入合同标的类型，参见字典表object_type，101：摊位，501：平价菜'
            }]
          },
          {
            label: '合同类型，参见字典表annex_type，401：市场管理合同，402：租赁合同，403：平价菜合同',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入合同类型，参见字典表annex_type，401：市场管理合同，402：租赁合同，403：平价菜合同'
            }]
          },
          {
            label: '合同附件',
            prop: 'url',
            rules: [{
              required: true,
              message: '请输入合同附件'
            }]
          },
          {
            label: '合同起始日期',
            prop: 'validFrom',
            rules: [{
              required: true,
              message: '请输入合同起始日期'
            }]
          },
          {
            label: '合同截止日期',
            prop: 'validTo',
            rules: [{
              required: true,
              message: '请输入合同截止日期'
            }]
          },
          {
            label: '合同金额',
            prop: 'amount',
            rules: [{
              required: true,
              message: '请输入合同金额'
            }]
          },
          {
            label: '支付方式，参见字典表pay_type，0：聚合码 1：数币聚合码 2：现金 ',
            prop: 'payType',
            rules: [{
              required: true,
              message: '请输入支付方式，参见字典表pay_type，0：聚合码 1：数币聚合码 2：现金 '
            }]
          },
          {
            label: '支付周期，参见字典表pay_period_type，0：月结 1：季度结 2：半年度结 3：一年度结 ',
            prop: 'payPeriod',
            rules: [{
              required: true,
              message: '请输入支付周期，参见字典表pay_period_type，0：月结 1：季度结 2：半年度结 3：一年度结 '
            }]
          },
          {
            label: '备注信息',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注信息'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_contract_add,
        viewBtn: this.permission.base_contract_view,
        delBtn: this.permission.base_contract_delete,
        editBtn: this.permission.base_contract_edit
      }
    }
  },
  methods: {
  }
}
</script>
