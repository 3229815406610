<template>
  <basic-container>
    <el-dialog
        title="提示"
        top="5vh"
        append-to-body
        :visible.sync="show"
        @close="cancel"
        width="65%">
      <div class="el-dialog-div">
        <div v-for="(item, index) in lists" :key="index" class="containerBox">
          <div style="display: flex; align-items: center; margin-bottom: 10px;">
            <p style="font-weight: bold;">{{ item.name }}</p>
            <p class="unchecked" :class="{ checked : item.checked }" @click="checkItem(item, index, '', '', '')"></p>
          </div>
          <div v-for="(item2, index2) in item.children" :key="index2" class="roleBox" style="text-indent:24px;">
            <div class="parent">
              <p style="color: #999999;">{{ item2.name }}</p>
              <p style="margin-top: 3px;" class="unchecked" :class="{ checked : item2.checked }" @click="checkItem(item2, index, index2, '', '')"></p>
            </div>
            <div class="child">
              <div :class="[ item3.children ? 'has-son' : 'no-son' ]" v-for="(item3, index3) in item2.children" :key="index3">
                <div class="son">
                  <div class="item">
                    <div style="display: flex;" :class="[ item3.children ? 'lock-width' : '' ]">
                      <p>{{ item3.name }}</p>
                      <p class="unchecked" :class="{ checked : item3.checked }" @click="checkItem(item3, index, index2, index3, '')"></p>
                    </div>
                    <div style="display: flex;" >
                      <div class="item" v-for="(item4, index4) in item3.children" :key="index4">
                        <p>{{ item4.name }}</p>
                        <p class="unchecked" :class="{ checked : item4.checked }" @click="checkItem(item4, index, index2, index3, index4)"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
          slot="footer"
          class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button
            type="primary"
            v-if="permissions.sys_role_edit"
            @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { grant } from '@/api/sys/role'
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  name: 'RoleBasic',
  data () {
    return {
      module: 'sys/role',
      lists: [],
      currentKeys: [],
      show: false
    }
  },
  created () {
  },
  mounted () {
  },
  watch: {
    lists: {
      handler (val) {
        this.show = true
        this.lists = this.roleList
        if (this.lists) {
          this.updateObj(this.lists)
        }
        if (!this.currentKeys) {
          this.currentKeys = this.defaultKeys
        }
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    forms: {
      type: Object
    },
    roleList: {
      type: Array
    },
    permissions: {
      type: Object
    },
    defaultKeys: {
      type: Array
    },
    pages: {
      type: Object
    }
  },
  computed: {

  },
  methods: {
    changeShow () {
      this.show = !this.show
    },
    cancel () {
      this.show = false
      this.$emit('cancel')
    },
    submit () {
      const menuList = this.currentKeys.join(',')
      grant(this.forms.id, menuList).then((res) => {
        this.show = false
        if (res.success) {
          this.onLoad(this.pages)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    checkItem (val, i, j, k, s) {
      this.currentKeys = []
      // 1级
      if (!this.isUndefined(i) && this.isUndefined(j) && this.isUndefined(k) && this.isUndefined(s)) {
        this.$set(this.lists[i], 'checked', !this.lists[i].checked)
        // 更新下面子项
        if (this.lists[i].children) {
          this.lists[i].children.forEach(item => {
            item.checked = this.lists[i].checked
            if (item.children) {
              item.children.forEach(c => {
                c.checked = this.lists[i].checked
                if (c.children) {
                  c.children.forEach(d => {
                    d.checked = this.lists[i].checked
                  })
                }
              })
            }
          })
        }
        this.updateObj(this.lists)
        return
      }
      if (!this.isUndefined(i) && !this.isUndefined(j) && !this.isUndefined(k) && !this.isUndefined(s)) {
        // 4级
        this.$set(this.lists[i].children[j].children[k].children[s], 'checked', !this.lists[i].children[j].children[k].children[s].checked)
        this.updateObj(this.lists)
      } else if (!this.isUndefined(i) && !this.isUndefined(j) && !this.isUndefined(k) && this.isUndefined(s)) {
        // 3级
        this.$set(this.lists[i].children[j].children[k], 'checked', !this.lists[i].children[j].children[k].checked)
        // 若有4级则更新
        if (this.lists[i].children[j].children[k].children) {
          this.lists[i].children[j].children[k].children.forEach(item => {
            item.checked = this.lists[i].children[j].children[k].checked
          })
        }
        this.updateObj(this.lists)
      } else {
        // 2级
        this.$set(this.lists[i].children[j], 'checked', !this.lists[i].children[j].checked)
        // 更新1级和下级
        if (this.lists[i].children[j].children) {
          this.lists[i].children[j].children.forEach(item => {
            item.checked = this.lists[i].children[j].checked
            if (item.children) {
              item.children.forEach(s => {
                s.checked = this.lists[i].children[j].checked
              })
            }
          })
        }
        // 2级存在任意有效勾选，则选中1级
        let count = 0
        this.lists[i].children.forEach(item => {
          if (item.checked) {
            count++
          }
        })
        if (count > 0) {
          this.$set(this.lists[i], 'checked', true)
        } else {
          this.$set(this.lists[i], 'checked', false)
        }
        this.updateObj(this.lists)
      }
    },
    isUndefined (val) {
      if (val === '') {
        return true
      } else {
        return false
      }
    },
    updateObj (obj) {
      const currentKeys = this.currentKeys
      for (const key in obj) {
        if (key === 'checked' && obj[key] === true) {
          this.$set(this, 'currentKeys', currentKeys.concat(obj.id))
        }
        if (typeof obj[key] === 'object') {
          this.updateObj(obj[key])
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .el-dialog-div {
    height: 70vh;
    overflow-x: hidden;
  }
  .containerBox {
    margin-bottom: 20px;
    p {
      display: flex;
    }
  }
  .roleBox {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #EBEEF5;
    margin-bottom: 6px;
    .parent {
      display: flex;
      align-items: top;
      width: 15%;
      margin-bottom: 10px;
    }

    .child {
      display: flex;
      flex-wrap: wrap;
      width: 85%;
    }

    .son {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      min-height: 32px;
      .item {
        display: flex;
        width: auto;
        min-width: 80px;
        padding: 0 8px;
        box-sizing: border-box;
        margin-bottom: 10px;
      }
    }

    .has-son {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .no-son {
      display: flex;
    }

    .lock-width {
      width: 180px;
    }
  }
  .unchecked {
    width: 12px;
    height: 12px;
    margin-left: 8px;
    margin-top: 4px;
    border: 1px solid #cccccc;
  }
  .checked {
    width: 12px;
    height: 12px;
    line-height: 12px;
    border: 1px solid #409eff;
    background-color: #409eff;
    position: relative;
    cursor: pointer;
  }

  .checked::before {
    position: absolute;
    content: " ";
    width: 2px;
    height: 5px;
    transform: rotate(45deg);
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    top: 1px;
    left: 4px;
  }
</style>
