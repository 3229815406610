import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/sys/role/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const grantTree = () => {
  return request({
    url: '/sys/menu/tree',
    method: 'get'
  })
}

export const grant = (roleId, menuIds) => {
  return request({
    url: '/sys/role/grant',
    method: 'post',
    data: {
      roleId,
      menuIds
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/sys/role/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const add = (row) => {
  return request({
    url: '/sys/role/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/sys/role/submit',
    method: 'post',
    data: row
  })
}

export const getByRole = (roleId) => {
  return request({
    url: '/sys/menu/role',
    method: 'get',
    params: {
      roleId
    }
  })
}

export const getRoleTree = () => {
  return request({
    url: '/sys/role/tree',
    method: 'get'
  })
}

export const isValidateCode = (code, id) => {
  return request({
    url: '/sys/role/check/code',
    method: 'get',
    params: {
      code,
      id
    }
  })
}
