<template >
  <basic-container>
    <!--搜索-->
    <el-form :inline="true" :model="search" class="demo-form-inline">
      <el-form-item label="电子账簿号：">
        <el-input v-model="search.balanceAcctId" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchChange()">查询</el-button>
        <el-button  @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>

    <div class="exports">
      <el-button type="primary" @click="dialogFormVisible = true">导出流水</el-button>
    </div>
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="导出流水日期">
          <el-date-picker
              v-model="form.value"
              type="date"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="exports()">确 定</el-button>
      </div>
    </el-dialog>
    <!--表格数据-->
    <div class=“table”>
      <el-table :data="tableData" border style="width: 100%" :row-style="{height:10}">
        <el-table-column label="序号" width="50">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="eventId" label="事件号" width="180"/>
        <el-table-column prop="eventType" label="事件类型" width="180"/>
        <el-table-column prop="tradeId" label="系统订单号" width="180"/>
        <el-table-column prop="balanceAcctId" label="电子账簿号" width="180"/>
        <el-table-column prop="balanceAcctNo" label="关联银行账号" width="180"/>
        <el-table-column prop="bindAcctName" label="商户名称" width="180"/>
        <el-table-column prop="amount" label="金额" width="180"/>
        <el-table-column prop="payBankAcctNo" label="转出方银行账号" width="180"/>
        <el-table-column prop="payBankAcctName" label="转出方银行账户名称" width="180"/>
<!--        <el-table-column prop="payBankBankCode" label="转出方银行编号" width="180"/>-->
<!--        <el-table-column prop="transactionNo" label="银行流水号" width="180"/>-->
        <el-table-column prop="payBankBranchCode" label="转出方银行编号" width="180"/>
        <el-table-column prop="tradeType" label="交易类型" width="180"/>
<!--        <el-table-column prop="bankMemo" label="银行附言" width="180"/>-->
        <el-table-column prop="createdTime" label="创建时间" width="180"/>
      </el-table>
    </div>
    <!--分页-->
    <div class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10,20,30,50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </div>
  </basic-container>
</template>

<script>
import { getNotice, downloadTradeBills } from '@/api/szbank/szbank'

export default {
  data () {
    return {
      dialogFormVisible: false,
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      search: {
        amount: undefined,
        balanceAcctId: undefined,
        balanceAcctNo: undefined,
        bankMemo: undefined,
        createdAt: undefined,
        createdTime: undefined,
        eventId: undefined,
        eventType: undefined,
        id: undefined,
        payBankAcctName: undefined,
        payBankAcctNo: undefined,
        payBankBankCode: undefined,
        recordedAt: undefined,
        tradeId: undefined,
        tradeType: undefined,
        transactionNo: undefined,
        pageSize: 10,
        pageNo: 1
      },
      tableData: [],
      form: {
        value: undefined
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    currentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
    async loadData () {
      this.search.pageNo = this.page.pageNo
      this.search.pageSize = this.page.pageSize
      const res = await getNotice(this.search)
      this.page.total = res.data.total
      this.tableData = res.data.records
    },
    // 查询
    async searchChange () {
      this.loadData()
    },
    // 导出流水
    async exports () {
      this.dialogFormVisible = false
      const res = await downloadTradeBills(this.form.value)
      const blob = new Blob(['\ufeff', res])
      const fileName = 'lll.csv'
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob.fileName)
      }
    },
    cancel () {
      this.reset()
      this.loadData()
    },
    reset () {
      this.search = {
        amount: undefined,
        balanceAcctId: undefined,
        balanceAcctNo: undefined,
        bankMemo: undefined,
        createdAt: undefined,
        createdTime: undefined,
        eventId: undefined,
        eventType: undefined,
        id: undefined,
        payBankAcctName: undefined,
        payBankAcctNo: undefined,
        payBankBankCode: undefined,
        recordedAt: undefined,
        tradeId: undefined,
        tradeType: undefined,
        transactionNo: undefined,
        pageSize: 10,
        pageNo: 1
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 15px;
  margin-bottom: 20px;
}
.exports {
  margin-bottom: 15px;
}
</style>
