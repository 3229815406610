<template>
  <basic-container>
    <el-card class="good-card">
      <div slot="header">
        <span v-if="type" style="font-size: 15px">编辑二级商户进件</span>
        <span v-else style="font-size: 15px" >新增二级商户进件</span>
      </div>
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
        >
          <span class="title"><i class="el-icon-s-flag"></i>基本信息</span>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="营业执照扫描件：" prop="businessLicenseCopyHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.businessLicenseCopyHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :prefix="'szbank'"
                      :initData="form.businessLicenseCopyHttp"
                      :uploadClass="'upload-pic'"
                      :canClose="false"
                      @updateList="deleteBusinessLicense"
                      @handleSuccess="handleBusinessLicense"
                  >
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="银行卡正面：" prop="legalPersonIdCardNationalHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.bankCardHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :initData="form.bankCardHttp"
                      :prefix="'szbank'"
                      :uploadClass="'upload-pic'"
                      @updateList="deleteBankCard"
                      @handleSuccess="handleBankCard">
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="商户简称：" prop="shortName">
                <el-input v-model="form.shortName"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="组织代码有效期限：" prop="organizationCertValidTime">
                <el-input v-model="form.organizationCertValidTime"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="税务登记号：" prop="taxRegistrationCertNumber">
                <el-input v-model="form.taxRegistrationCertNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="营业执照公司名称：" prop="businessLicenseCompanyName">
                <el-input v-model="form.businessLicenseCompanyName"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="组织机构代码号：" prop="organizationCertNumber">
                <el-input v-model="form.organizationCertNumber"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="银行开户名称：" prop="settleAcctName">
                <el-input v-model="form.settleAcctName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="营业执照注册号：" prop="businessLicenseNumber">
                <el-input v-model="form.businessLicenseNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="营业期限：" prop="businessLicenseValidTime">
                <el-input v-model="form.businessLicenseValidTime"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="银行帐号：" prop="settleAcctBankAcctNo">
                <el-input v-model="form.settleAcctBankAcctNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
             <el-col :span="8">
               <el-form-item label="营业执照注册地址：" prop="businessLicenseCompanyAddress">
                 <el-input v-model="form.businessLicenseCompanyAddress"></el-input>
               </el-form-item>
             </el-col>
            <el-col :span="8">
              <el-form-item label="开户银行联行号：" prop="settleAcctBankBranchCode">
                <el-input v-model="form.settleAcctBankBranchCode"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="主体类型：" prop="organizationType">
                <el-radio-group v-model="form.organizationType">
                  <span style="margin-right: 30px" @click="checkPublic"><el-radio :label="1">企业</el-radio></span>
                  <span><el-radio :label="2">个体工商户</el-radio></span>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="账户类型：" prop="settleAcctType">
                <el-radio-group v-model="form.settleAcctType">
                  <span style="margin-right: 30px" v-if="form.organizationType===2" @click="chageBankName(2)"><el-radio :label="1" >对私账户</el-radio></span>
                  <span @click="chageBankName(1)"><el-radio :label="2" >对公账户</el-radio></span>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <span class="title"><i class="el-icon-s-flag"></i>联系人</span>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="联系人证件类型：" prop="legalPersonIdCardType">
                <el-radio-group v-model="form.contactIdCardType">
                  <el-radio :label="1">身份证</el-radio>
                  <el-radio :label="2">护照</el-radio>
                  <el-radio :label="3">中国香港居民</el-radio>
                  <el-radio :label="4">中国澳门居民</el-radio>
                  <el-radio :label="5">中国台湾居民</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人证键人像面：" prop="contactIdCardCopyHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.contactIdCardCopyHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :prefix="'szbank'"
                      :initData="form.contactIdCardCopyHttp"
                      :uploadClass="'upload-pic'"
                      :canClose="false"
                      @updateList="deleteContactImageFace"
                      @handleSuccess="handleContactIRecognizeFace"
                  >
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人证件国徽面：" prop="contactIdCardNationalHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.contactIdCardNationalHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :initData="form.contactIdCardNationalHttp"
                      :prefix="'szbank'"
                      :uploadClass="'upload-pic'"
                      @updateList="deleteContactImageBack"
                      @handleSuccess="handleContactRecognizeBack">
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人姓名：" prop="contactIdCardName">
                <el-input v-model="form.contactIdCardName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人证件号码：" prop="contactIdCardNumber">
                <el-input v-model="form.contactIdCardNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人证件有效期：" prop="contactIdCardValidTime">
                <el-input v-model="form.contactIdCardValidTime"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="联系人手机号：" prop="contactMobileNumber">
                <el-input v-model="form.contactMobileNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系人邮箱：" prop="contactEmail">
                <el-input v-model="form.contactEmail"/>
              </el-form-item>
            </el-col>
          </el-row>

          <span class="title"><i class="el-icon-s-flag"></i>法人</span>
          <el-divider></el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="法人证件类型：" prop="legalPersonIdCardType">
                <el-radio-group v-model="form.legalPersonIdCardType">
                  <el-radio :label="1">身份证</el-radio>
                  <el-radio :label="2">护照</el-radio>
                  <el-radio :label="3">中国香港居民</el-radio>
                  <el-radio :label="4">中国澳门居民</el-radio>
                  <el-radio :label="5">中国台湾居民</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人证件人像面：" prop="legalPersonIdCardCopyHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.legalPersonIdCardCopyHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :prefix="'szbank'"
                      :initData="form.legalPersonIdCardCopyHttp"
                      :uploadClass="'upload-pic'"
                      :canClose="false"
                      @updateList="deleteLegalImageFace"
                      @handleSuccess="handleLegalIRecognizeFace"
                  >
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件国徽面：" prop="legalPersonIdCardNationalHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.legalPersonIdCardNationalHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :initData="form.legalPersonIdCardNationalHttp"
                      :prefix="'szbank'"
                      :uploadClass="'upload-pic'"
                      @updateList="deleteLegalImageBack"
                      @handleSuccess="handleLegalRecognizeBack">
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20" >
            <el-col :span="8">
              <el-form-item label="法人姓名：" prop="legalPersonIdCardName">
                <el-input v-model="form.legalPersonIdCardName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件号码：" prop="legalPersonIdCardNumber">
                <el-input v-model="form.legalPersonIdCardNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="法人证件有效期：" prop="legalPersonIdCardValidTime">
                <el-input v-model="form.legalPersonIdCardValidTime"/>
              </el-form-item>
            </el-col>
          </el-row>

<!--          <el-row :gutter="20">-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="开户银行编码：" prop="settleAcctBankCode">-->
<!--                <el-input v-model="form.settleAcctBankCode"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="开户银行省市编码：" prop="settleAcctBankAddressCode">-->
<!--                <el-input v-model="form.settleAcctBankAddressCode"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-row :gutter="20">-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="开户银行联行号：" prop="settleAcctBankBranchCode">-->
<!--                <el-input v-model="form.settleAcctBankBranchCode"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="开户银行联行号：" prop="settleAcctBankBranchCode">-->
<!--                <el-input v-model="form.settleAcctBankBranchCode"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="法人手机号：" prop="legalPersonMobileNumber">
                <el-input v-model="form.legalPersonMobileNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="短信验证码：" prop="smsCode">
                <el-input v-model="form.smsCode"/>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-button
                  type="primary"
                  :disabled="sendModel.countFlag"
                  @click="getCode">
                {{ sendModel.btnMsg == null ? sendModel.countNum + 's后重新发送' : sendModel.btnMsg }}
              </el-button>
            </el-col>
          </el-row>

<!--          <span class="title"><i class="el-icon-s-flag"></i>企业信息(非必填)</span>-->
<!--          <el-divider></el-divider>-->
<!--          <el-row :gutter="20">-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="企业分类大类：" prop="businessInfoType">-->
<!--                <el-input v-model="form.businessInfoType"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="企业分类细分：" prop="businessInfoSubType">-->
<!--                <el-input v-model="form.businessInfoSubType"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="企业经济成分：" prop="businessInfoEconomy">-->
<!--                <el-input v-model="form.businessInfoEconomy"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-row :gutter="20">-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="企业规模：" prop="businessInfoScale">-->
<!--                <el-input v-model="form.businessInfoScale"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="实际企业名称：" prop="holdingCompanyName">-->
<!--                <el-input v-model="form.holdingCompanyName"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="股东类型：" prop="holdingCompanyType">-->
<!--                <el-radio-group v-model="form.holdingCompanyType">-->
<!--                  <el-radio :label="1">企业</el-radio>-->
<!--                  <el-radio :label="2">个人</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-row :gutter="20">-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="企业营业执照号：" prop="holdingCompanyLicenseNumber">-->
<!--                <el-input v-model="form.holdingCompanyLicenseNumber"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="企业营业期限：" prop="holdingCompanyLicenseValidTime">-->
<!--                <el-input v-model="form.holdingCompanyLicenseValidTime"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-row :gutter="20">-->
<!--            <el-col :span="24">-->
<!--              <el-form-item label="补充说明图片：" prop="additionImgsHttp">-->
<!--                <div class="start top">-->
<!--                  <good-cropper-->
<!--                      v-model="form.additionImgsHttp"-->
<!--                      :multiple="true"-->
<!--                      :showFileList="false"-->
<!--                      :bucket="'zhsn-scale'"-->
<!--                      :initData="form.additionImgsHttp"-->
<!--                      :uploadClass="'upload-pic'">-->
<!--                  </good-cropper>-->
<!--                </div>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-row :gutter="20">-->
<!--&lt;!&ndash;            <el-col :span="8">&ndash;&gt;-->
<!--&lt;!&ndash;              <el-form-item label="交易授权码：" prop="password">&ndash;&gt;-->
<!--&lt;!&ndash;                <el-input v-model="form.password"></el-input>&ndash;&gt;-->
<!--&lt;!&ndash;              </el-form-item>&ndash;&gt;-->
<!--&lt;!&ndash;            </el-col>&ndash;&gt;-->
<!--&lt;!&ndash;            <el-col :span="8">&ndash;&gt;-->
<!--&lt;!&ndash;              <el-form-item label="合同编号：" prop="extraContractNo">&ndash;&gt;-->
<!--&lt;!&ndash;                <el-input v-model="form.extraContractNo"/>&ndash;&gt;-->
<!--&lt;!&ndash;              </el-form-item>&ndash;&gt;-->
<!--&lt;!&ndash;            </el-col>&ndash;&gt;-->
<!--            <el-col :span="24">-->
<!--              <el-form-item label="补充说明文字：" prop="additionDesc">-->
<!--                <el-input v-model="form.additionDesc"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

          <el-form-item>
            <el-button type="primary" @click="save()">保存</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </basic-container>
</template>

<script>
import GoodCropper from '@/components/good-cropper/index'
import { isEmail } from '@/util/validate'
import {
  getphone,
  createdmch,
  selectMchById,
  updatamch,
  getImage,
  bankcardIdentification,
  recognizeBusiness,
  recognize
} from '@/api/szbank/szbank'

export default {
  components: { GoodCropper },
  data () {
    return {
      sendModel: {
        // 倒计时周期
        countNum: 60,
        // 用于倒计时标记，true-正在倒计时
        countFlag: false,
        // 定时器
        intervalBtn: {},
        // 默认按钮的值
        btnMsg: '获取验证码'
      },
      imgVisible: false,
      form: {
        // 营业执照扫描件
        businessLicenseCopyHttp: undefined,
        businessLicenseCopy: undefined,
        // 商户简称
        shortName: undefined,
        // 组织代码有效期限
        organizationCertValidTime: undefined,
        // 税务登记号
        taxRegistrationCertNumber: undefined,
        // 营业执照公司名称
        businessLicenseCompanyName: undefined,
        // 组织机构代码
        organizationCertNumber: undefined,
        // 营业执照注册地址
        businessLicenseCompanyAddress: undefined,
        // 营业执照注册号
        businessLicenseNumber: undefined,
        // 营业期限
        businessLicenseValidTime: undefined,
        // 主体类型
        organizationType: 1,
        // 账户类型
        settleAcctType: 2,
        // 联系人证件类型
        contactIdCardType: 1,
        // 联系人证键人像面
        contactIdCardCopyHttp: undefined,
        contactIdCardCopy: undefined,
        // 联系人证件国徽面
        contactIdCardNationalHttp: undefined,
        contactIdCardNational: undefined,
        // 联系人证件号码
        contactIdCardNumber: undefined,
        // 联系人证件有效期
        contactIdCardValidTime: undefined,
        // 联系人手机号
        contactMobileNumber: undefined,
        // 联系人姓名
        contactIdCardName: undefined,
        // 联系人邮箱
        contactEmail: undefined,
        // 法人证件类型
        legalPersonIdCardType: 1,
        // 法人证件人像面
        legalPersonIdCardCopyHttp: undefined,
        legalPersonIdCardCopy: undefined,
        // 法人证件国徽面
        legalPersonIdCardNationalHttp: undefined,
        legalPersonIdCardNational: undefined,
        // 银行卡正面
        bankCardHttp: undefined,
        // 法人姓名
        legalPersonIdCardName: undefined,
        // 法人证件有效期
        legalPersonIdCardValidTime: undefined,
        // 银行帐号
        settleAcctBankAcctNo: undefined,
        // 法人证件号码
        legalPersonIdCardNumber: undefined,
        // 银行开户名称
        settleAcctName: undefined,
        // 法人手机号
        legalPersonMobileNumber: undefined,
        // 短信验证码
        smsCode: undefined
      },
      updateFrom: {},
      rules: {
        contactIdCardCopyHttp: [{ required: true, trigger: 'blur', message: '请上传联系人身份证人面' }],
        contactIdCardNationalHttp: [{ required: true, trigger: 'blur', message: '请上传联系人身份证国徽面' }],
        legalPersonIdCardCopyHttp: [{ required: true, trigger: 'blur', message: '请上传法人身份证国徽面' }],
        legalPersonIdCardNationalHttp: [{ required: true, trigger: 'blur', message: '请上传法人身份证国徽面' }],
        bankCardHttp: [{ required: true, trigger: 'blur', message: '请上传银行正面照' }]
      },
      // 查看是 新增 还是编辑 进件
      type: undefined,
      // 法人姓名
      legealPerNmae: undefined
    }
  },
  created () {
    this.type = this.$route.query.type
    this.getMercInfo()
  },

  methods: {

    async getMercInfo () {
      // 如果类型为1 编辑进件查询赋值
      if (this.type === '1') {
        const res = await selectMchById(this.$route.query.id)
        this.form = res.data
        this.form.organizationType = res.data.organizationType * 1
        this.form.contactIdCardType = res.data.contactIdCardType * 1
        this.form.settleAcctType = res.data.settleAcctType * 1
        this.form.legalPersonIdCardType = res.data.legalPersonIdCardType * 1
        this.form.smsCode = undefined
      }
    },

    save () {
      this.$refs.form.validate(async (valid) => {
        if (!isEmail(this.form.contactEmail)) {
          this.$message({
            type: 'error',
            message: '邮箱格式错误'
          })
          return
        }
        if (valid) {
          if (this.type === '1') {
            this.updateFrom = { ...this.form }
            this.$set(this.updateFrom, 'mchId', this.$route.query.mchId)
            const res = await updatamch(this.updateFrom)
            if (res.success) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.$router.push({ path: '/szbank/mchapplicationsdetails' })
            }
          } else {
            const res = await createdmch(this.form)
            if (res.success) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.$router.push({ path: '/szbank/mchapplicationsdetails' })
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          }
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/szbank/mchapplicationsdetails' })
    },
    async waitphone () {
      try {
        const res = await getphone(this.form.mobileNumber)
        if (res.success) {
          this.$message({
            type: 'success',
            message: res.msg
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      } catch (res) {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },

    countDown () {
      // 设置btn倒计时时显示的信息
      this.sendModel.btnMsg = null
      // 更改btn状态
      this.sendModel.countFlag = !this.sendModel.countFlag
      this.waitphone()
      // 设置倒计时
      this.sendModel.intervalBtn = setInterval(() => {
        if (this.sendModel.countNum <= 0) {
          // 重置btn提示信息
          this.sendModel.btnMsg = '获取验证码'
          // 清除定时器
          clearInterval(this.sendModel.intervalBtn)
          // 更改btn状态
          this.sendModel.countFlag = !this.sendModel.countFlag
          // 重置倒计时状态
          this.sendModel.countNum = 60
        }
        // 倒计时
        this.sendModel.countNum--
      }, 1000)
    },
    getCode () {
      this.countDown()
    },
    handleContactIRecognizeFace (imageUrl, bucket) {
      this.handleContactRecognize(imageUrl, 'face', bucket)
    },
    handleContactRecognizeBack (imageUrl, bucket) {
      this.handleContactRecognize(imageUrl, 'back', bucket)
    },
    async handleContactRecognize (imageUrl, side, bucket) {
      const resImage = await getImage(imageUrl)
      if (resImage.success) {
        recognize(imageUrl, side, bucket).then(res => {
          if (res.success) {
            if (res.data !== undefined) {
              if (side === 'face') {
                this.form.contactIdCardCopy = resImage.data
                this.form.contactIdCardNumber = res.data.idCardNo
                this.form.contactIdCardName = res.data.name
              }
              if (side === 'back') {
                this.form = { ...this.form }
                this.form.contactIdCardNational = resImage.data
                this.form.contactIdCardValidTime = res.data.validFrom + ',' + res.data.validTo
              }
              this.$message({
                type: 'success',
                message: '成功',
                duration: 2000
              })
            } else {
              this.$message({
                type: 'error',
                message: '识别结果为空',
                duration: 2000
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        }).catch((e) => {

        })
      } else {
        if (side === 'face') {
          this.deleteContactImageFace()
        }
        if (side === 'back') {
          this.deleteContactImageBack()
        }
        this.$message({
          type: 'error',
          message: '请重新上传',
          duration: 2000
        })
      }
    },
    deleteContactImageFace () {
      this.form.contactIdCardNumber = undefined
      this.form.contactIdCardCopyHttp = undefined
      this.form.contactIdCardName = undefined
      this.form.contactIdCardCopy = undefined
    },
    deleteContactImageBack () {
      this.form.contactIdCardValidTime = undefined
      this.form.contactIdCardNationalHttp = undefined
      this.form.contactIdCardNational = undefined
    },
    handleLegalIRecognizeFace (imageUrl, bucket) {
      this.handleLegalRecognize(imageUrl, 'face', bucket)
    },
    handleLegalRecognizeBack (imageUrl, bucket) {
      this.handleLegalRecognize(imageUrl, 'back', bucket)
    },
    async handleLegalRecognize (imageUrl, side, bucket) {
      const resImage = await getImage(imageUrl)
      if (resImage.success) {
        recognize(imageUrl, side, bucket).then(res => {
          if (res.success) {
            if (res.data !== undefined) {
              if (side === 'face') {
                this.form.legalPersonIdCardCopy = resImage.data
                this.form.legalPersonIdCardNumber = res.data.idCardNo
                this.form.legalPersonIdCardName = res.data.name
              }
              if (side === 'back') {
                this.form = { ...this.form }
                this.form.legalPersonIdCardNational = resImage.data
                this.form.legalPersonIdCardValidTime = res.data.validFrom + ',' + res.data.validTo
              }
              this.$message({
                type: 'success',
                message: '成功',
                duration: 2000
              })
            } else {
              this.$message({
                type: 'error',
                message: '识别结果为空',
                duration: 2000
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        }).catch((e) => {

        })
      } else {
        if (side === 'face') {
          this.deleteLegalImageFace()
        }
        if (side === 'back') {
          this.deleteLegalImageBack()
        }
        this.$message({
          type: 'error',
          message: '请重新上传',
          duration: 2000
        })
      }
    },
    deleteLegalImageFace () {
      this.form.legalPersonIdCardNumber = undefined
      this.form.legalPersonIdCardCopyHttp = undefined
      this.form.legalPersonIdCardCopy = undefined
      this.form.legalPersonIdCardName = undefined
    },
    deleteLegalImageBack () {
      this.form.legalPersonIdCardNational = undefined
      this.form.legalPersonIdCardNationalHttp = undefined
      this.form.legalPersonIdCardValidTime = undefined
    },
    async handleBankCard (imageUrl, bucket) {
      try {
        const res = await bankcardIdentification(imageUrl, bucket)
        this.form.settleAcctBankAcctNo = res.data
      } catch (e) {
        this.form.bankCardHttp = undefined
        this.$message({
          type: 'error',
          message: '请重新上传',
          duration: 2000
        })
      }
    },
    deleteBankCard () {
      this.form.settleAcctBankAcctNo = undefined
    },
    // 处理营业执照
    async handleBusinessLicense (imageUrl, bucket) {
      const resImage = await getImage(imageUrl)
      if (resImage.success) {
        recognizeBusiness(imageUrl, bucket).then((res) => {
          if (res.success) {
            this.form.shortName = res.data.fullName
            this.form.businessLicenseCompanyName = res.data.fullName
            this.form.businessLicenseNumber = res.data.creditCode
            this.form.taxRegistrationCertNumber = res.data.creditCode
            this.form.organizationCertNumber = res.data.creditCode
            this.form.businessLicenseValidTime = res.data.beginAt + ',' + res.data.operatingTo
            this.form.organizationCertValidTime = res.data.beginAt + ',' + res.data.operatingTo
            this.form.settleAcctName = res.data.fullName
            this.legealPerNmae = res.data.legalPersonName
            this.form.businessLicenseCopy = resImage.data
            this.form.businessLicenseCompanyAddress = res.data.address
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: e
          })
        })
      } else {
        this.deleteBusinessLicense()
        this.$message({
          type: 'error',
          message: '请重新上传',
          duration: 2000
        })
      }
    },
    // 清除营业执照信息
    deleteBusinessLicense () {
      this.form.shortName = undefined
      this.form.businessLicenseCompanyName = undefined
      this.form.businessLicenseNumber = undefined
      this.form.taxRegistrationCertNumber = undefined
      this.form.organizationCertNumber = undefined
      this.form.businessLicenseValidTime = undefined
      this.form.organizationCertValidTime = undefined
      this.form.settleAcctName = undefined
      this.legealPerNmae = undefined
      this.form.businessLicenseCopy = undefined
      this.form.businessLicenseCompanyAddress = undefined
    },
    chageBankName (e) {
      if (e === 1) {
        this.form.settleAcctName = this.form.businessLicenseCompanyName
      } else {
        this.form.settleAcctName = this.legealPerNmae
      }
    },
    checkPublic () {
      this.form.settleAcctType = 2
      this.form.settleAcctName = this.form.businessLicenseCompanyName
    }
  }
}
</script>

<style lang="scss">
.choose-img {
  margin-left: 15px;
}

.title {
  i {
    margin-right: 15px;
  }

  font-size: 24px;
}

.img-box {
  width: 200px;
  height: 200px;
  margin: 15px;
  border: 2px solid #eee;

  img {
    height: 100%;
  }
}
</style>
