import { request } from '@/util/http'

export const modelList = (current, size, params) => {
  return request({
    url: '/flow/model/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const managerList = (current, size, params) => {
  return request({
    url: '/flow/manager/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const followList = (current, size, params) => {
  return request({
    url: '/flow/follow/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const removeModel = (ids) => {
  return request({
    url: '/flow/model/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const deployModel = (params) => {
  return request({
    url: '/flow/model/deploy',
    method: 'post',
    params
  })
}

export const changeState = (params) => {
  return request({
    url: '/flow/manager/change-state',
    method: 'post',
    params
  })
}

export const deployUpload = (category, files) => {
  const formData = new FormData()
  formData.append('category', category)
  files.forEach(file => {
    formData.append('files', file)
  })
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/flow/manager/deploy-upload',
    method: 'post',
    data: formData
  })
}

export const deleteDeployment = (deploymentIds) => {
  return request({
    url: '/flow/manager/delete-deployment',
    method: 'post',
    params: {
      deploymentIds
    }
  })
}

export const deleteProcessInstance = (params) => {
  return request({
    url: '/flow/follow/delete-process-instance',
    method: 'post',
    params
  })
}
