<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.crm_devicetype_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { oss } from '@/config'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/devicetype',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '分类编码',
            prop: 'code',
            rules: [{
              required: true,
              message: '请输入分类编码'
            }],
            row: true
          },
          {
            label: '分类名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入分类名称'
            }],
            order: 10,
            row: true
          },
          {
            label: '父类',
            prop: 'parentId',
            type: 'tree',
            dicUrl: '/crm/devicetype/tree',
            props: {
              label: 'name',
              value: 'id'
            },
            order: 9,
            row: true
          },
          {
            label: '标志图片',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            oss: oss,
            tip: '上传图片不超过500kb',
            cropperOption: {
              autoCropWidth: 100,
              autoCropHeight: 100,
              fixed: true,
              fixedNumber: [100, 100]
            },
            order: 1,
            row: true
          },
          {
            label: '说明',
            prop: 'description',
            order: 9,
            row: true
          },
          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            value: 1,
            min: 1,
            order: 8,
            row: true
          },
          {
            label: '是否为外接设备',
            prop: 'isAuxiliary',
            type: 'radio',
            rules: [{
              required: true,
              message: '请输入是否为外接设备， 0：否， 1：是'
            }],
            dicData: [{
              label: '是',
              value: 1
            }, {
              label: '否',
              value: 0
            }],
            order: 8,
            row: true
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }],
            order: 8,
            row: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_devicetype_add,
        viewBtn: this.permission.crm_devicetype_view,
        delBtn: this.permission.crm_devicetype_delete,
        editBtn: this.permission.crm_devicetype_edit
      }
    }
  },
  methods: {}
}
</script>
