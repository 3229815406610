<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.base_booth_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/booth',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: 'sys_tenant.id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入sys_tenant.id'
            }]
          },
          {
            label: 'sys_dept.id',
            prop: 'deptId',
            rules: [{
              required: true,
              message: '请输入sys_dept.id'
            }]
          },
          {
            label: '区域id',
            prop: 'regionId',
            rules: [{
              required: true,
              message: '请输入区域id'
            }]
          },
          {
            label: '摊位名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入摊位名称'
            }]
          },
          {
            label: '编号',
            prop: 'sn',
            rules: [{
              required: true,
              message: '请输入编号'
            }]
          },
          {
            label: '状态，0：建设中，1：正常，2：异常，9：封闭维修',
            prop: 'buildingStatus',
            rules: [{
              required: true,
              message: '请输入状态，0：建设中，1：正常，2：异常，9：封闭维修'
            }]
          },
          {
            label: '租赁状态，0：未租赁，1：已租赁',
            prop: 'leaseStatus',
            rules: [{
              required: true,
              message: '请输入租赁状态，0：未租赁，1：已租赁'
            }]
          },
          {
            label: '照片，以逗号分隔',
            prop: 'pics',
            rules: [{
              required: true,
              message: '请输入照片，以逗号分隔'
            }]
          },
          {
            label: '',
            prop: 'sort',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '删除标志',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入删除标志'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_booth_add,
        viewBtn: this.permission.base_booth_view,
        delBtn: this.permission.base_booth_delete,
        editBtn: this.permission.base_booth_edit
      }
    }
  },
  methods: {
  }
}
</script>
