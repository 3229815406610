<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
        <template slot="dateRangeSearch">
            <el-date-picker
                    v-model="search.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
            </el-date-picker>
        </template>
    </avue-crud>

  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/crm/scaleversioninfo'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/scaleversioninfo',
      form: {},
      search: {
        dateRange: []
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        menu: false,
        addBtn: false,
        selection: true,
        searchLabelWidth: 100,
        indexLabel: '序号',
        column: [
          {
            label: '市场',
            prop: 'marketId',
            type: 'select',
            filterable: true,
            search: true,
            dicUrl: '/sys/dept/dict/market'
          },
          // {
          //   label: '商户',
          //   prop: 'merchantId',
          //   type: 'select',
          //   filterable: true,
          //   select: true,
          //   search: true,
          //   dicUrl: `/crm/merchant/dict`
          // },
          // {
          //   label: '设备号',
          //   prop: 'sn',
          //   search: true,
          //   filterable: true,
          //   type: 'select',
          //   select: true,
          //   dicUrl: 'crm/scaleversioninfo/snVersiondict'
          // },
          // {
          //   label: '市场',
          //   prop: 'marketName',
          //   search: true,
          //   filterable: true
          // },
          {
            label: '商户',
            prop: 'merchantName',
            search: true,
            filterable: true
          },
          {
            label: '商户手机号',
            prop: 'mobile',
            search: true,
            filterable: true
          },
          {
            label: '设备号',
            prop: 'sn',
            search: true,
            filterable: true
          },
          {
            label: '系统版本号',
            prop: 'romVersion',
            overHidden: true,
            search: true,
            filterable: true,
            type: 'select',
            select: true,
            dicUrl: 'crm/scaleversioninfo/sysVersiondict'
          },
          {
            label: '版本编号',
            prop: 'versionCode',
            search: true,
            filterable: true,
            type: 'select',
            select: true,
            dicUrl: 'crm/scaleversioninfo/versionCodedict'
          },

          {
            label: '版本名称',
            prop: 'versionName',
            search: true,
            filterable: true,
            type: 'select',
            select: true,
            dicUrl: 'crm/scaleversioninfo/versionNamedict'

          },
          {
            label: 'ai版本名称',
            prop: 'aiVersionName',
            search: true,
            filterable: true,
            type: 'select',
            select: true,
            dicUrl: 'crm/scaleversioninfo/aiVersiondict'

          },
          {
            label: '网络类型',
            prop: 'netWorkType'

          },
          {
            label: '时间',
            prop: 'dateRange',
            search: true,
            searchSpan: 12,
            searchSlot: true,
            hide: true,
            viewDisplay: false,
            editDisplay: false
          },
          {
            label: '更新时间',
            prop: 'updateTime'

          }
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_scaleversioninfo_add,
        viewBtn: this.permission.crm_scaleversioninfo_view,
        delBtn: this.permission.crm_scaleversioninfo_delete,
        editBtn: this.permission.crm_scaleversioninfo_edit
      }
    }
  },
  methods: {
    onLoad () {
      if (this.search.dateRange && this.search.dateRange.length === 2) {
        this.search.start = this.search.dateRange[0]
        this.search.end = this.search.dateRange[1]
      }
      getList(this.page.currentPage, this.page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        if (data.total === 0) {
          this.data = []
        } else {
          this.data = data.records
        }
      })
    }

  }
}
</script>
