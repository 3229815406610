<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.crm_inspectfoodsecurity_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/inspectfoodsecurity',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '租户Id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入租户Id'
            }]
          },
          {
            label: '市场Id',
            prop: 'deptId',
            rules: [{
              required: true,
              message: '请输入市场Id'
            }]
          },
          {
            label: '人员健康状态，0：不正常，1：正常',
            prop: 'humanHealthStatus',
            rules: [{
              required: true,
              message: '请输入人员健康状态，0：不正常，1：正常'
            }]
          },
          {
            label: '人员健康不符说明',
            prop: 'humanHealthRemark',
            rules: [{
              required: true,
              message: '请输入人员健康不符说明'
            }]
          },
          {
            label: '安全管理人员配置状态，0：不正常，1：正常',
            prop: 'safetyOfficerStatus',
            rules: [{
              required: true,
              message: '请输入安全管理人员配置状态，0：不正常，1：正常'
            }]
          },
          {
            label: '经营场所环境状态，0：不正常，1：正常',
            prop: 'surroundingStatus',
            rules: [{
              required: true,
              message: '请输入经营场所环境状态，0：不正常，1：正常'
            }]
          },
          {
            label: '安全违法行为制止及报告状态，0：故障，1：完好',
            prop: 'violationsReportStatus',
            rules: [{
              required: true,
              message: '请输入安全违法行为制止及报告状态，0：故障，1：完好'
            }]
          },
          {
            label: '冷冻设备状态，0：故障，1：完好',
            prop: 'refrigStatus',
            rules: [{
              required: true,
              message: '请输入冷冻设备状态，0：故障，1：完好'
            }]
          },
          {
            label: '抽样检查数量',
            prop: 'pickTest',
            rules: [{
              required: true,
              message: '请输入抽样检查数量'
            }]
          },
          {
            label: '进货查验状态，0：不正常，1：正常',
            prop: 'incomeInspectStatus',
            rules: [{
              required: true,
              message: '请输入进货查验状态，0：不正常，1：正常'
            }]
          },
          {
            label: '进货查验不正常说明',
            prop: 'incomeInspectRemark',
            rules: [{
              required: true,
              message: '请输入进货查验不正常说明'
            }]
          },
          {
            label: '冷冻设备状态，0：不正常，1：正常',
            prop: 'sensoryInspectStatus',
            rules: [{
              required: true,
              message: '请输入冷冻设备状态，0：不正常，1：正常'
            }]
          },
          {
            label: '野生动物交易状态，0：不正常，1：正常',
            prop: 'wildlifeTradeStatus',
            rules: [{
              required: true,
              message: '请输入野生动物交易状态，0：不正常，1：正常'
            }]
          },
          {
            label: '长江鱼鲜交易状态，0：否，1：是',
            prop: 'yangtzeFishTradeStatus',
            rules: [{
              required: true,
              message: '请输入长江鱼鲜交易状态，0：否，1：是'
            }]
          },
          {
            label: '信息公示状态，0：否，1：是',
            prop: 'infoDisclosureStatus',
            rules: [{
              required: true,
              message: '请输入信息公示状态，0：否，1：是'
            }]
          },
          {
            label: '正常经营户数',
            prop: 'normalOperation',
            rules: [{
              required: true,
              message: '请输入正常经营户数'
            }]
          },
          {
            label: '未经营户数',
            prop: 'noneOperation',
            rules: [{
              required: true,
              message: '请输入未经营户数'
            }]
          },
          {
            label: '巡检照片',
            prop: 'pics',
            rules: [{
              required: true,
              message: '请输入巡检照片'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          },
          {
            label: '巡检日期',
            prop: 'inspectDate',
            rules: [{
              required: true,
              message: '请输入巡检日期'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_inspectfoodsecurity_add,
        viewBtn: this.permission.crm_inspectfoodsecurity_view,
        delBtn: this.permission.crm_inspectfoodsecurity_delete,
        editBtn: this.permission.crm_inspectfoodsecurity_edit
      }
    }
  },
  methods: {
  }
}
</script>
