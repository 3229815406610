<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/tenant',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        searchMenuSpan: 150,
        indexLabel: '序号',
        column: [
          {
            label: '租户编号',
            prop: 'id',
            search: true,
            searchSpan: 12,
            placeholder: '请输入租户编号',
            editDisabled: true,
            rules: [{
              required: true
            }]
          },
          {
            label: '租户名',
            prop: 'tenantName',
            search: true,
            placeholder: '请设置租户名',
            rules: [{
              required: true
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.sys_tenant_add,
        viewBtn: this.permission.sys_tenant_view,
        delBtn: this.permission.sys_tenant_delete,
        editBtn: this.permission.sys_tenant_edit
      }
    }
  },
  methods: {
  }
}
</script>
