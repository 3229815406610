<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.crm_devicebrand_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/devicebrand',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        align: 'center',
        column: [
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }],
            order: 10,
            row: true
          },
          {
            label: '品牌代码',
            prop: 'code',
            rules: [{
              required: true,
              message: '请设置品牌代码'
            }],
            order: 10,
            row: true
          },
          {
            label: '标志图片',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            oss: oss,
            tip: '上传图片不超过500kb',
            cropperOption: {
              autoCropWidth: 100,
              autoCropHeight: 100,
              fixed: true,
              fixedNumber: [100, 100]
            },
            order: 1,
            row: true
          },
          {
            label: '说明',
            prop: 'description',
            order: 9,
            row: true
          },
          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            value: 1,
            min: 1,
            order: 8,
            row: true
          },
          {
            label: '备注',
            prop: 'remark',
            order: 7,
            row: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_devicebrand_add,
        viewBtn: this.permission.crm_devicebrand_view,
        delBtn: this.permission.crm_devicebrand_delete,
        editBtn: this.permission.crm_devicebrand_edit
      }
    }
  },
  methods: {
  }
}
</script>
