<template>
  <basic-container>
    <!--搜索-->
    <el-form :inline="true" :model="search" class="demo-form-inline">
      <el-form-item label="法人姓名：">
        <el-input v-model="search.legalPersonIdCardName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchChange()">查询</el-button>
        <el-button  @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- 新增-->
    <div class="addmch">
      <el-button type="primary" @click="addmch">新增</el-button>
    </div>
    <!--表格数据-->
    <div class=“table”>
      <el-table :data="tableData" border>
        <el-table-column label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="shortName" :show-overflow-tooltip="true" label="商户简称" min-width="100" align="center"/>
        <el-table-column prop="businessLicenseNumber" label="营业执照注册号" min-width="150" align="center"/>
        <el-table-column prop="contactIdCardName" label="联系人姓名" min-width="80" align="center"/>
        <el-table-column prop="contactMobileNumber" label="联系人手机号" min-width="100" align="center"/>
        <el-table-column prop="legalPersonIdCardName" label="法人姓名" min-width="80" align="center"/>
        <el-table-column prop="legalPersonMobileNumber" label="法人手机号" min-width="100" align="center"/>
        <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="100" align="center"/>
        <el-table-column prop="settleAcctId" label="绑定账户号" min-width="100" align="center"/>
        <el-table-column prop="mchId" label="二级商户号" min-width="100" align="center"/>
        <el-table-column prop="merchantId" label="商户号" min-width="100" align="center"/>
        <el-table-column label="进件状态" min-width="80" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.applicationStatus==='succeeded'" style="color: green">{{ dict.succeeded }}</span>
            <span v-if="scope.row.applicationStatus==='failed'" style="color: red">{{ dict.failed }}</span>
            <span v-if="scope.row.applicationStatus==='signing'" style="color: red">{{ dict.signing }}</span>
            <span v-if="scope.row.applicationStatus==='processing'" style="color: red">{{ dict.processing }}</span>
            <span v-if="scope.row.applicationStatus==='auditing'" style="color: red">{{ dict.auditing }}</span>
            <span
                v-if="scope.row.applicationStatus==='account_need_verify'"
                style="color: red">{{ dict.account_need_verify }}</span>
            <span v-if="scope.row.applicationStatus==='checking'" style="color: red">{{ dict.checking }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" min-width="150" align="center"/>
        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button type="text" size="small" @click="see(scope.row)">查看</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="deluser(scope.row)">删除</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button type="text" size="small" @click="balce(scope.row)">提现</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="flow(scope.row)">流水</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="bindmerch(scope.row)">商户号</el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <div class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10,20,30,50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </div>

    <el-dialog title="查看详情" :visible.sync="dialogFormVisible" width="80%">
      <el-form :model="form" label-position="right" label-width="120px">
        <span style="font-weight: lighter">基本信息</span>
       <div class="detail">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="商户简称">
              <el-input v-model="form.shortName" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="营业执照公司名称" label-width="125px">
              <el-input v-model="form.businessLicenseCompanyName" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="营业执照注册号">
              <el-input v-model="form.businessLicenseNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="营业期限" >
              <el-input v-model="form.businessLicenseValidTime" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="营业执照注册地址" label-width="125px">
              <el-input v-model="form.businessLicenseCompanyAddress" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组织机构代码">
              <el-input v-model="form.organizationCertNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="组织代码有效期限" label-width="125px">
              <el-input v-model="form.organizationCertValidTime" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="税务登记号" >
              <el-input v-model="form.taxRegistrationCertNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="银行开户名称">
              <el-input v-model="form.settleAcctName" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="银行帐号">
              <el-input v-model="form.settleAcctBankAcctNo" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="银行簿记账户">
              <el-input v-model="form.bindAcctNo" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="电子账簿账簿号">
              <el-input v-model="form.relAcctNo" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
       </div>

        <span style="font-weight: lighter">人员信息</span>
        <div class="detail">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="联系人姓名">
              <el-input v-model="form.contactIdCardName" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系人证件号码">
              <el-input v-model="form.contactIdCardNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系人证件有效期" label-width="125px">
              <el-input v-model="form.contactIdCardValidTime" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系人手机号">
              <el-input v-model="form.contactMobileNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="联系人邮箱">
                <el-input v-model="form.contactEmail" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人姓名">
                <el-input v-model="form.legalPersonIdCardName" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人证件号码">
                <el-input v-model="form.legalPersonIdCardNumber" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="法人手机号">
                <el-input v-model="form.legalPersonMobileNumber" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
         </div>

        <span style="font-weight: lighter">平台信息</span>
        <div class="detail">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="二级商户号">
                <el-input v-model="form.mchId" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="商户号">
                <el-input v-model="form.merchantId" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="电子账簿号">
                <el-input v-model="form.balanceAcctId" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="绑定账户号">
                <el-input v-model="form.settleAcctId" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="平台号">
                <el-input v-model="form.outRequestNo" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="创建时间">
                <el-input v-model="form.createdTime" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进件状态">
                <el-input v-model="form.applicationStatus" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="验证类型">
                <el-input v-model="form.acctValidationType" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="付款户名">
                <el-input v-model="form.acctValidationAcctName" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="付款账户号">
                <el-input v-model="form.acctValidationAcctNo" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款账户名">
                <el-input v-model="form.acctValidationDestAcctName" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款账户号">
                <el-input v-model="form.acctValidationDestAcctNo" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="汇款金额">
                <el-input v-model="form.acctValidationPayAmount" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="账户银行联行号" >
                <el-input v-model="form.acctValidationDestAcctBankBranchCode" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款账户省市信息" label-width="125px">
                <el-input v-model="form.acctValidationDestAcctBankAddressCode" :disabled="true"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款账户备注信息"  label-width="125px">
                <el-input v-model="form.acctValidationMemo" :disabled="true"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <span style="font-weight: lighter">图片信息</span>
        <div class="detail"></div>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="营业执照扫描件">
              <el-image
                  :src="form.businessLicenseCopyHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.businessLicenseCopyHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="银行卡正面">
              <el-image
                  :src="form.bankCardHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.bankCardHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="法人证件人像面">
              <el-image
                  :src="form.legalPersonIdCardCopyHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.legalPersonIdCardCopyHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="法人证件国徽面">
              <el-image
                  :src="form.legalPersonIdCardNationalHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.legalPersonIdCardNationalHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="联系人证键人像面" label-width="125px">
              <el-image
                  :src="form.contactIdCardCopyHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.contactIdCardCopyHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系人证件国徽面"  label-width="125px">
              <el-image
                  :src="form.contactIdCardNationalHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.contactIdCardNationalHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="电子账簿信息和提现" :visible.sync="blance">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>电子账簿详情</span>
        </div>
        <el-table :data="blanceFrom" border>
          <el-table-column label="序号" width="80" align="center">{{1}} </el-table-column>
          <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="180" align="center"/>
          <el-table-column prop="settledAmount" label="可提现余额" min-width="180" align="center"/>
          <el-table-column prop="pendingAmount" label="在途余额" min-width="180" align="center"/>
          <el-table-column prop="expensingAmount" label="不可用余额" min-width="180" align="center"/>
          <el-table-column  label="电子账簿是否被冻结" min-width="180" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.isFrozen==='false'" style="color: green">未冻结</span>
              <span v-else-if="scope.row.isFrozen==='true'" style="color: red">已冻结</span>
            </template>
          </el-table-column>
          <el-table-column prop="frozenSettledAmount" label="冻结余额" min-width="180" align="center"/>
          <el-table-column prop="remark" label="备注" min-width="100" align="center"/>
        </el-table>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>提现</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="demo-input-suffix">
              <el-col :span="6">
                <span style="position: absolute;top: 5px;">提现金额：</span>
              </el-col>
              <el-col :span="18">
                <el-input v-model="withdrawals.amount"/>
              </el-col>
            </div>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" @click="Withdrawal">提现</el-button>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" v-show="walsuc">
        <div slot="header" class="clearfix">
          <span>提现结果</span>
        </div>
        <el-table :data="withdrawalsResult" border>
          <el-table-column label="序号" width="80" align="center">{{1}} </el-table-column>
          <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="180" align="center"/>
          <el-table-column prop="status" label="交易状态" min-width="180" align="center"/>
          <el-table-column prop="amount" label="金额" min-width="180" align="center"/>
          <el-table-column prop="serviceFee" label="平台手续费" min-width="180" align="center"/>
          <el-table-column prop="businessType" label="业务类型" min-width="180" align="center"/>
        </el-table>
      </el-card>

    </el-dialog>

    <el-dialog title="绑定商户号" :visible.sync="bindmerchSign">
      <el-card class="box-card">
        <el-form :model="paramesdetails" label-position="right" label-width="100px">
          <el-row :gutter="12">
            <el-col :span="12">
              <el-form-item label="商户名">
                <el-input v-model="paramesdetails.idCardName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商户号">
                <el-input v-model="paramesdetails.merchantId"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bingSaveMerch">确 定</el-button>
        <el-button  @click="bindmerchSign = false">关 闭</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  getMchInfo,
  selectbalance,
  createdWithdrawal,
  bindMchmerchantId,
  deleteMcchInfo
} from '@/api/szbank/szbank'

export default {
  data () {
    return {
      bindmerchSign: false,
      blance: false,
      dialogFormVisible: false,
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      search: {
        id: undefined,
        // 营业执照扫描件
        businessLicenseCopyHttp: undefined,
        // 商户简称
        shortName: undefined,
        // 组织代码有效期限
        organizationCertValidTime: undefined,
        // 税务登记号
        taxRegistrationCertNumber: undefined,
        // 营业执照公司名称
        businessLicenseCompanyName: undefined,
        // 组织机构代码
        organizationCertNumber: undefined,
        // 营业执照注册地址
        businessLicenseCompanyAddress: undefined,
        // 营业执照注册号
        businessLicenseNumber: undefined,
        // 营业期限
        businessLicenseValidTime: undefined,
        // 主体类型
        organizationType: undefined,
        // 账户类型
        settleAcctType: undefined,
        // 联系人证件类型
        contactIdCardType: undefined,
        // 联系人证键人像面
        contactIdCardCopyHttp: undefined,
        // 联系人证件国徽面
        contactIdCardNationalHttp: undefined,
        // 联系人证件号码
        contactIdCardNumber: undefined,
        // 联系人证件有效期
        contactIdCardValidTime: undefined,
        // 联系人手机号
        contactMobileNumber: undefined,
        // 联系人姓名
        contactIdCardName: undefined,
        // 联系人邮箱
        contactEmail: undefined,
        // 法人证件类型
        legalPersonIdCardType: undefined,
        // 法人证件人像面
        legalPersonIdCardCopyHttp: undefined,
        // 法人证件国徽面
        legalPersonIdCardNationalHttp: undefined,
        // 银行卡正面
        bankCardHttp: undefined,
        // 法人姓名
        legalPersonIdCardName: undefined,
        // 法人证件有效期
        legalPersonIdCardValidTime: undefined,
        // 银行帐号
        settleAcctBankAcctNo: undefined,
        // 法人证件号码
        legalPersonIdCardNumber: undefined,
        // 银行开户名称
        settleAcctName: undefined,
        // 法人手机号
        legalPersonMobileNumber: undefined,
        // 平台订单号
        mchApplicationId: undefined,
        // 进件状态
        applicationStatus: undefined,
        // 二级商户号
        mchId: undefined,
        // 验证类型
        acctValidationType: undefined,
        // 付款户名
        acctValidationAcctName: undefined,
        // 付款账户号
        acctValidationAcctNo: undefined,
        // 汇款金额
        acctValidationPayAmount: undefined,
        // 收款账户账户名
        acctValidationDestAcctName: undefined,
        // 收款账户账户号
        acctValidationDestAcctNo: undefined,
        // 收款账户开户银行联行号
        acctValidationDestAcctBankBranchCode: undefined,
        // 收款账户省市信息
        acctValidationDestAcctBankAddressCode: undefined,
        // 收款账户备注信息
        acctValidationMemo: undefined,
        // 电子账簿账簿号
        relAcctNo: undefined,
        // 银行簿记账户
        bindAcctNo: undefined,
        // 银行生成的户名
        bindAcctName: undefined,
        // 电子账簿ID
        balanceAcctId: undefined,
        // 绑定账户ID
        settleAcctId: undefined,
        // 创建时间
        createdTime: undefined,
        // 商户号
        merchantId: undefined,
        pageSize: 10,
        pageNo: 1
      },
      tableData: [],
      form: {},
      blanceFrom: [],
      viewList: [],
      dict: {
        checking: '资料校验',
        account_need_verify: '待账户验证（四要素鉴权）',
        auditing: '审核',
        processing: '处理中',
        signing: '电子签约中',
        succeeded: '已通过',
        failed: '被驳回'
      },
      withdrawals: {
        amount: undefined,
        serviceFee: undefined,
        balanceAcctId: undefined,
        bankAcctNo: undefined,
        bankMemo: undefined,
        password: undefined,
        remark: undefined,
        metadata: undefined,
        idCardName: undefined
      },
      walsuc: false,
      withdrawalsResult: [],
      parames: {
        outRequestNo: undefined,
        merchantId: undefined
      },
      paramesdetails: {
        outRequestNo: undefined,
        merchantId: undefined,
        idCardName: undefined
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    currentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
    async loadData () {
      this.search.pageNo = this.page.pageNo
      this.search.pageSize = this.page.pageSize
      const res = await getMchInfo(this.search)
      this.page.total = res.data.total
      this.tableData = res.data.records
    },
    // 查询
    searchChange () {
      this.loadData()
    },
    addmch () {
      this.$router.push({ path: '/szbank/mchapplications' })
    },
    see (e) {
      this.form = e
      this.dialogFormVisible = true
    },
    edit (row) {
      this.$router.push({
        path: '/szbank/mchapplications',
        query: { id: row.id, type: 1, mchId: row.mchId }
      })
    },
    view (e) {
      this.viewList = []
      this.viewList.push(e)
    },
    async balce (e) {
      this.walsuc = false
      this.blanceFrom = []
      const res = await selectbalance(e.balanceAcctId)
      this.blanceFrom.push(res.data)
      this.withdrawals.idCardName = e.settleAcctName
      this.withdrawals.balanceAcctId = e.balanceAcctId
      this.withdrawals.bankAcctNo = e.settleAcctBankAcctNo
      this.withdrawals.amount = res.data.settledAmount
      this.blance = true
    },
    async Withdrawal () {
      const res = await createdWithdrawal(this.withdrawals)
      if (res.success) {
        this.withdrawalsResult = []
        const value = res.data
        value.amount = res.data.amount * 1 / 100
        value.serviceFee = res.data.serviceFee * 1 / 100
        this.withdrawalsResult.push(value)
      }
      this.walsuc = true
    },
    async deluser (e) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteMcchInfo(e.outRequestNo, e.balanceAcctId)
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.loadData()
        } else {
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    flow (e) {
      this.$router.push({
        path: '/szbank/flowingwater',
        query: { balanceAcctId: e.balanceAcctId }
      })
    },
    cancel () {
      this.reset()
      this.loadData()
    },
    reset () {
      this.search = {
        id: undefined,
        businessLicenseCopyHttp: undefined,
        shortName: undefined,
        organizationCertValidTime: undefined,
        taxRegistrationCertNumber: undefined,
        businessLicenseCompanyName: undefined,
        organizationCertNumber: undefined,
        businessLicenseCompanyAddress: undefined,
        businessLicenseNumber: undefined,
        businessLicenseValidTime: undefined,
        organizationType: undefined,
        settleAcctType: undefined,
        contactIdCardType: undefined,
        contactIdCardCopyHttp: undefined,
        contactIdCardNationalHttp: undefined,
        contactIdCardNumber: undefined,
        contactIdCardValidTime: undefined,
        contactMobileNumber: undefined,
        contactIdCardName: undefined,
        contactEmail: undefined,
        legalPersonIdCardType: undefined,
        legalPersonIdCardCopyHttp: undefined,
        legalPersonIdCardNationalHttp: undefined,
        bankCardHttp: undefined,
        legalPersonIdCardName: undefined,
        legalPersonIdCardValidTime: undefined,
        settleAcctBankAcctNo: undefined,
        legalPersonIdCardNumber: undefined,
        settleAcctName: undefined,
        legalPersonMobileNumber: undefined,
        mchApplicationId: undefined,
        applicationStatus: undefined,
        mchId: undefined,
        acctValidationType: undefined,
        acctValidationAcctName: undefined,
        acctValidationAcctNo: undefined,
        acctValidationPayAmount: undefined,
        acctValidationDestAcctName: undefined,
        acctValidationDestAcctNo: undefined,
        acctValidationDestAcctBankBranchCode: undefined,
        acctValidationDestAcctBankAddressCode: undefined,
        acctValidationMemo: undefined,
        relAcctNo: undefined,
        bindAcctNo: undefined,
        bindAcctName: undefined,
        balanceAcctId: undefined,
        settleAcctId: undefined,
        pageSize: 10,
        pageNo: 1
      }
    },
    bindmerch (e) {
      this.parames.outRequestNo = e.outRequestNo
      this.paramesdetails.idCardName = e.settleAcctName
      this.bindmerchSign = true
    },
    async bingSaveMerch () {
      this.parames.merchantId = this.paramesdetails.merchantId
      const res = await bindMchmerchantId(this.parames)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '成功'
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
      this.bindmerchSign = false
      this.loadData()
    }
  }

}
</script>

<style lang="scss" scoped>
.addmch {
  margin-top: 20px;
  margin-bottom: 10px;
}

.pagination-container {
  margin-top: 15px;
}

.demo-input-suffix {
  display: flex;
}
.detail{
  margin-top: 5px;
  padding-top: 10px;
  border-top:1px solid #E6E6E6;
}
</style>
