<template>
  <div>
    <basic-container>
      <el-tabs v-model="activeName">
        <el-tab-pane label="大分类" name="bigLevel">
          <avue-crud
              rules="nameRules"
              ref="crud1"
              :data="dataBig"
              :search.sync="searchBig"
              :option="optionBig"
              :page.sync="pageBig"
              v-model="formBig"
              :permission="permissionList"
              @search-change="searchChangeBig"
              @search-reset="searchResetBig"
              @row-del="rowDelBig"
              @on-load="onLoadBig"
              @row-update="rowUpdateBig"
              @row-save="rowSaveBig">
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="中分类" name="middleLevel">
          <avue-crud
              rules="nameRules"
              ref="crud2"
              :data="dataMiddle"
              :search.sync="searchMiddle"
              :option="optionMiddle"
              :permission="permissionList"
              :page.sync="pageMiddle"
              v-model="formMiddle"
              @search-change="searchChangeMiddle"
              @search-reset="searchResetMiddle"
              @row-del="rowDelMiddle"
              @on-load="onLoadMiddle"
              @row-update="rowUpdateMiddle"
              @row-save="rowSaveMiddle">
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="标准名" name="standard">
          <avue-crud
              rules="nameRules"
              ref="crud3"
              :data="dataStandard"
              :search.sync="searchStandard"
              :permission="permissionList"
              :option="optionStandard"
              :page.sync="pageStandard"
              v-model="formStandard"
              @search-change="searchChangeStandard"
              @search-reset="searchResetStandard"
              @row-del="rowDelStandard"
              @on-load="onLoadStandard"
              @row-update="rowUpdateStandard"
              @row-save="rowSaveStandard">
          </avue-crud>
        </el-tab-pane>
      </el-tabs>
    </basic-container>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import {
  getBig, getMiddle, getStandard, isValidBigName, isValidMiddleName, isValidStandardName, removeBig, removeMiddle,
  removeStandard, submitBig, submitMiddle, submitStandard
} from '@/api/mall/level3'

export default {
  mixins: [mixin],
  data () {
    const validateBigName = (rule, value, callback) => {
      const id = this.formBig.id
      isValidBigName(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('大分类不能重复'))
        } else {
          callback()
        }
      })
    }
    const validateMiddleName = (rule, value, callback) => {
      const id = this.formMiddle.id
      isValidMiddleName(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('中分类不能重复'))
        } else {
          callback()
        }
      })
    }
    const validateStandardName = (rule, value, callback) => {
      const id = this.formStandard.id
      isValidStandardName(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('标准名不能重复'))
        } else {
          callback()
        }
      })
    }
    return {
      nameRules: {
        bigName: [{ required: true, trigger: 'blur', validator: validateBigName }],
        middleName: [{ required: true, trigger: 'blur', validator: validateMiddleName }],
        standardName: [{ required: true, trigger: 'blur', validator: validateStandardName }]
      },
      moduleBig: 'mall/level1',
      moduleMiddle: 'mall/level2',
      moduleStandard: 'mall/level3',
      activeName: 'bigLevel',
      formBig: {},
      formMiddle: {},
      formStandard: {},
      searchBig: {},
      searchMiddle: {},
      searchStandard: {},
      pageBig: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      pageMiddle: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      pageStandard: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },

      selectionBigList: [],
      selectionMiddleList: [],
      selectionStandardList: [],
      optionBig: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        searchMenuSpan: 150,
        column: [
          {
            label: '大分类',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              validator: validateBigName
            }]
          }
        ]
      },
      optionMiddle: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        searchMenuSpan: 150,
        column: [
          {
            label: '大分类',
            prop: 'level1Id',
            type: 'select',
            dicUrl: '/mall/level1/dict',
            filterable: true,
            search: true,
            // multiple: true,
            width: 130,
            rules: [{
              required: true,
              message: '请输入大分类'
            }]
          },
          {
            label: '中分类',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              validator: validateMiddleName
            }]
          }
        ]
      },
      optionStandard: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        searchMenuSpan: 150,
        column: [
          {
            label: '大分类',
            prop: 'level1Id',
            type: 'select',
            dicUrl: '/mall/level1/dict',
            cascader: ['level2Id'],
            filterable: true,
            width: 130,
            search: true,
            rules: [{
              required: true,
              message: '请输入大分类'
            }]
          },
          {
            label: '中分类',
            prop: 'level2Id',
            type: 'select',
            dicUrl: '/mall/level2/dict?level1Id={{key}}',
            filterable: true,
            search: true,
            width: 130,
            rules: [{
              required: true,
              message: '请输入中分类'
            }]
          },
          {
            label: '标准名',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              validator: validateStandardName
            }]
          }
        ]
      },
      dataBig: [],
      dataMiddle: [],
      dataStandard: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_level_add,
        viewBtn: this.permission.mall_level_view,
        delBtn: this.permission.mall_level_delete,
        editBtn: this.permission.mall_level_edit
      }
    }
  },

  methods: {
    onLoadBig (pageBig) {
      getBig(pageBig.currentPage, pageBig.pageSize, this.searchBig).then(res => {
        const data = res.data
        this.pageBig.total = data.total
        this.dataBig = data.records
        this.$nextTick(() => {
          this.$refs.crud1.dicInit('cascader')
        })
      })
    },
    onLoadMiddle (pageMiddle) {
      getMiddle(pageMiddle.currentPage, pageMiddle.pageSize, this.searchMiddle).then(res => {
        const data = res.data
        this.pageMiddle.total = data.total
        this.dataMiddle = data.records
        this.$nextTick(() => {
          this.$refs.crud2.dicInit('cascader')
        })
      })
    },
    onLoadStandard (pageStandard) {
      getStandard(pageStandard.currentPage, pageStandard.pageSize, this.searchStandard).then(res => {
        const data = res.data
        this.pageStandard.total = data.total
        this.dataStandard = data.records
        this.$nextTick(() => {
          this.$refs.crud3.dicInit('cascader')
        })
      })
    },
    rowDelBig (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return removeBig(row.id)
        })
        .then((res) => {
          if (res.success) {
            this.onLoadBig(this.pageBig)
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
    },
    rowDelMiddle (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return removeMiddle(row.id)
        })
        .then((res) => {
          if (res.success) {
            this.onLoadMiddle(this.pageMiddle)
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
    },
    rowDelStandard (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return removeStandard(row.id, row.level2Id)
        })
        .then((res) => {
          if (res.success) {
            this.onLoadStandard(this.pageStandard)
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
    },
    rowUpdateBig (row, index, done, loading) {
      submitBig(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadBig(this.pageBig)
          this.$refs.crud2.updateDic('level1Id')
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowSaveBig (row, done, loading) {
      submitBig(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadBig(this.pageBig)
          this.$refs.crud2.updateDic('level1Id')
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowUpdateMiddle (row, index, done, loading) {
      submitMiddle(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadMiddle(this.pageMiddle)
          this.$refs.crud3.updateDic('level2Id')
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowSaveMiddle (row, done, loading) {
      submitMiddle(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadMiddle(this.pageMiddle)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowUpdateStandard (row, index, done, loading) {
      submitStandard(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadStandard(this.pageStandard)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowSaveStandard (row, done, loading) {
      submitStandard(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadStandard(this.pageStandard)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    searchChangeBig (params, done) {
      this.searchBig = params
      this.onLoadBig(this.pageBig)
      done()
    },
    searchResetBig () {
      this.searchBig = {}
      this.onLoadBig(this.pageBig)
    },
    searchChangeMiddle (params, done) {
      this.searchMiddle = params
      this.onLoadMiddle(this.pageMiddle)
      done()
    },
    searchResetMiddle () {
      this.searchMiddle = {}
      this.onLoadMiddle(this.pageMiddle)
    },
    searchChangeStandard (params, done) {
      this.searchStandard = params
      this.onLoadStandard(this.pageStandard)
      done()
    },
    searchResetStandard () {
      this.searchStandard = {}
      this.onLoadStandard(this.pageStandard)
    }
  }
}
</script>
