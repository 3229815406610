<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
        <template slot="dateRangeSearch">
            <el-date-picker
                    v-model="search.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
            </el-date-picker>
        </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/control/controlmarketlog'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'control/controlmarketlog',
      form: {},
      search: {
        dateRange: []
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '市场名称',
            prop: 'marketId',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            search: true
          },
          {
            label: '用户',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/user/userList',
            prop: 'userId',
            search: true
          },
          {
            label: '监控内容',
            prop: 'data',
            overHidden: true
          },
          {
            label: '监控内容',
            prop: 'contentType',
            type: 'select',
            search: true,
            span: 24,
            dicUrl: '/control/controluserinfo/ctrContentType'
          },
          {
            label: '监控名称',
            prop: 'contentTypeName'
          },
          {
            label: '查看',
            prop: 'show',
            clearable: false,
            dataType: 'number',
            dicData: [{
              label: '未看过',
              value: '0'
            }, {
              label: '看过',
              value: '1'
            }],
            search: true
          },
          {
            label: '状态',
            prop: 'status',
            clearable: false,
            dataType: 'number',
            dicData: [{
              label: '显示',
              value: '0'
            }, {
              label: '不显示',
              value: '1'
            }],
            search: true
          },
          {
            label: '执行实体类',
            prop: 'serviceType'
          },
          {
            label: '监控类型名称',
            prop: 'typeName'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '时间',
            prop: 'dateRange',
            search: true,
            searchSpan: 12,
            searchSlot: true,
            hide: true,
            viewDisplay: false,
            editDisplay: false
          }

        ]
      },
      data: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.control_controlmarketlog_add,
        viewBtn: this.permission.control_controlmarketlog_view,
        delBtn: this.permission.control_controlmarketlog_delete,
        editBtn: this.permission.control_controlmarketlog_edit
      }
    }
  },
  methods: {

    onLoad () {
      if (this.search.dateRange && this.search.dateRange.length === 2) {
        this.search.start = this.search.dateRange[0]
        this.search.end = this.search.dateRange[1]
      }
      getList(this.page.currentPage, this.page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        if (data.total === 0) {
          this.data = []
        } else {
          this.data = data.records
        }
      })
    }
  }
}
</script>
