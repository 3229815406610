<template>
  <div>
    <basic-container>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="电子秤" name="scale">
          <avue-crud
              ref="crud"
              v-model="scaleForm"
              :before-open="beforeScaleOpen"
              :data="data"
              :defaults.sync="scaleDefaults"
              :option="scaleOption"
              :page.sync="page"
              :permission="permissionList"
              :search.sync="search"
              :summary-method="summaryMethod"
              @row-del="rowDel"
              @row-update="rowUpdate"
              @row-save="rowSave"
              @search-change="searchChange"
              @search-reset="searchReset"
              @refresh-change="refreshChange"
              @selection-change="selectionChange"
              @current-change="currentChange"
              @on-load="onScaleLoad">
            <template slot="menu" slot-scope="{row}">
              <el-button
                  icon="el-icon-edit"
                  size="small"
                  type="text"
                  @click.stop="toEdit(row)">编辑
              </el-button>
            </template>
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="水表" name="waterMeter">
          <avue-crud
              ref="waterMeterCrud"
              v-model="form"
              :before-open="beforeOpen"
              :data="data"
              :defaults.sync="meterDefaults"
              :option="meterOption"
              :page.sync="page"
              :permission="permissionList"
              :search.sync="search"
              @row-del="rowDel"
              @row-update="rowUpdate"
              @row-save="rowSave"
              @search-change="searchChange"
              @search-reset="searchReset"
              @refresh-change="refreshChange"
              @selection-change="selectionChange"
              @current-change="currentChange">
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="电表" name="electricMeter">
          <avue-crud
              ref="electricMeterCrud"
              v-model="form"
              :before-open="beforeOpen"
              :data="data"
              :defaults.sync="meterDefaults"
              :option="meterOption"
              :page.sync="page"
              :permission="permissionList"
              :search.sync="search"
              @row-del="rowDel"
              @row-update="rowUpdate"
              @row-save="rowSave"
              @search-change="searchChange"
              @search-reset="searchReset"
              @refresh-change="refreshChange"
              @selection-change="selectionChange"
              @current-change="currentChange">
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="客流仪" name="camera">
          <mall-eyes/>
        </el-tab-pane>
        <el-tab-pane label="数据大屏" name="dataScreen">
          <avue-crud
              ref="dataScreenCrud"
              v-model="form"
              :before-open="beforeOpen"
              :data="data"
              :defaults.sync="screenDefaults"
              :option="screenOption"
              :page.sync="page"
              :permission="permissionList"
              :search.sync="search"
              @row-del="rowDel"
              @row-update="rowUpdate"
              @row-save="rowSave"
              @search-change="searchChange"
              @search-reset="searchReset"
              @refresh-change="refreshChange"
              @selection-change="selectionChange"
              @current-change="currentChange"
              @size-change="sizeChange">
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="通道显示屏" name="channelScreen">
          <avue-crud
              ref="channelScreenCrud"
              v-model="form"
              :before-open="beforeOpen"
              :data="data"
              :defaults.sync="screenDefaults"
              :option="screenOption"
              :page.sync="page"
              :permission="permissionList"
              :search.sync="search"
              @row-del="rowDel"
              @row-update="rowUpdate"
              @row-save="rowSave"
              @search-change="searchChange"
              @search-reset="searchReset"
              @refresh-change="refreshChange"
              @selection-change="selectionChange"
              @current-change="currentChange"
              @size-change="sizeChange">
          </avue-crud>
        </el-tab-pane>
        <el-tab-pane label="导购屏" name="guideScreen">
          <avue-crud
              ref="guideScreenCrud"
              v-model="form"
              :before-open="beforeOpen"
              :data="data"
              :defaults.sync="screenDefaults"
              :option="screenOption"
              :page.sync="page"
              :permission="permissionList"
              :search.sync="search"
              @row-del="rowDel"
              @row-update="rowUpdate"
              @row-save="rowSave"
              @search-change="searchChange"
              @search-reset="searchReset"
              @refresh-change="refreshChange"
              @selection-change="selectionChange"
              @current-change="currentChange"
              @size-change="sizeChange">
          </avue-crud>
        </el-tab-pane>
      </el-tabs>
    </basic-container>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import { count, getList, unbind } from '@/api/crm/device'
import { getDetail } from '@/api/common'
import { mapGetters } from 'vuex'
import MallEyes from '@/components/mall-eyes'

export default {
  mixins: [mixin],
  components: { MallEyes },
  data () {
    return {
      module: 'crm/device',
      scaleForm: {},
      waterForm: {},
      electricForm: {},
      cameraForm: {},
      scaleDefaults: {},
      meterDefaults: {},
      cameraDefaults: {},
      screenDefaults: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      activeName: 'scale',
      used: undefined,
      unused: undefined,
      scaleOption: {
        tip: false,
        border: true,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        showSummary: true,
        column: [
          {
            label: '采购批次',
            prop: 'purchaseBatchNo',
            search: true,
            width: 200,
            fixed: true
          },
          {
            label: '序列号',
            prop: 'sn',
            search: true,
            width: 200
          },
          {
            label: '市场',
            prop: 'deptId',
            width: 200,
            search: true,
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            rules: [{
              required: true,
              message: '请输入所属市场'
            }]
          },
          {
            label: '商户',
            prop: 'merchantFullName',
            width: 200,
            search: true
          },
          {
            label: '摊位',
            prop: 'boothSns',
            width: 80,
            search: true
          },
          {
            label: '绑定状态',
            prop: 'bindStatus',
            search: true,
            type: 'select',
            disabled: true,
            select: true,
            dicUrl: 'sys/dict/code/bind_status',
            dataType: 'string'
          },
          {
            label: '设备型号',
            prop: 'modelId',
            width: 100,
            search: true,
            type: 'select',
            filterable: true,
            select: true,
            dicUrl: '/crm/devicemodel/getDict'
          },
          {
            label: '状态',
            prop: 'stockStatus',
            search: true,
            disabled: true,
            type: 'select',
            select: true,
            dicUrl: 'sys/dict/code/stock_status',
            dataType: 'string'
          },
          {
            label: '备注信息',
            prop: 'remark',
            width: 150
          }
        ]
      },
      meterOption: {
        tip: false,
        border: true,
        index: false,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        showSummary: true,
        column: [
          {
            label: '序列号',
            prop: 'sn',
            search: true,
            width: 200
          },
          {
            label: '市场',
            prop: 'marketName',
            search: true
          },
          {
            label: '商户',
            prop: 'merchantFullName',
            search: true
          },
          {
            label: '摊位',
            prop: 'boothSn',
            search: true
          },
          {
            label: '设备型号',
            prop: 'name'
          },
          {
            label: '设备绑定',
            prop: 'bindStatus',
            search: true,
            type: 'select',
            filterable: true,
            select: true,
            dicUrl: 'sys/dict/code/bind_status',
            dataType: 'string'
          },
          {
            label: '表计模式',
            prop: 'pattern',
            search: true,
            type: 'select',
            disabled: true,
            select: true,
            dicData: [{ value: 1, label: '一直开闸' }, { value: 2, label: '一直关闸' }, {
              value: 3,
              label: '没钱关闸'
            }, { value: 4, label: '没钱不关闸' }],
            dataType: 'string'
          },
          {
            label: '备注信息',
            prop: 'remark',
            width: 150
          }
        ]
      },
      screenOption: {
        tip: false,
        border: true,
        index: false,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        showSummary: true,
        column: [
          {
            label: '采购批次',
            prop: 'purchaseBatchNo',
            search: true,
            width: 200
          },
          {
            label: '序列号',
            prop: 'sn',
            search: true
          },
          {
            label: '市场',
            prop: 'marketName',
            search: true
          },
          {
            label: '设备型号',
            prop: 'name'
          }
        ]
      },
      data: [],
      tempScale: {},
      tempWater: {},
      tempElc: {},
      tempCamera: {},
      tempDataScreen: {},
      tempChannelScreen: {},
      tempGuideScreen: {}
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_device_add && !this.permission.role_admin,
        viewBtn: this.permission.crm_device_view,
        delBtn: this.permission.crm_device_delete && !this.permission.role_admin,
        editBtn: this.permission.crm_device_edit && !this.permission.role_admin
      }
    },
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {
    'scaleForm.merchantId' (val) {
      if (val) {
        this.scaleForm.bindStatus = 1
      } else {
        this.scaleForm.bindStatus = 0
      }
    }
  },
  async created () {
    this.tempScale = this.scaleOption
    this.tempWater = this.meterOption
    this.tempElc = this.meterOption
    this.tempCamera = this.cameraOption
    this.tempDataScreen = this.screenOption
    this.tempChannelScreen = this.screenOption
    this.tempGuideScreen = this.screenOption
    this.onLoad(this.page)
  },
  methods: {
    handleClick (tab) {
      if (this.activeName !== tab.$options.propsData.name) {
        this.$set(this.page, 'currentPage', 1)
      }
      if (tab.$options.propsData.name !== 'camera') {
        this.search = { typeCode: this.search.typeCode }
        const tabName = tab.name
        this.activeName = tabName
        switch (tabName) {
          case 'scale':
            this.scaleOption = this.tempScale
            this.meterOption = {}
            this.cameraOption = {}
            this.screenOption = {}
            break
          case 'waterMeter':
            this.meterOption = this.tempWater
            this.cameraOption = {}
            this.scaleOption = {}
            this.screenOption = {}
            break
          case 'electricMeter':
            this.meterOption = this.tempElc
            this.cameraOption = {}
            this.scaleOption = {}
            this.screenOption = {}
            break
          case 'camera':
            this.cameraOption = this.tempCamera
            this.meterOption = {}
            this.scaleOption = {}
            this.screenOption = {}
            break
          case 'dataScreen':
            this.screenOption = this.tempDataScreen
            this.meterOption = {}
            this.scaleOption = {}
            this.cameraOption = {}
            break
          case 'channelScreen':
            this.screenOption = this.tempChannelScreen
            this.meterOption = {}
            this.scaleOption = {}
            this.cameraOption = {}
            break
          case 'guideScreen':
            this.screenOption = this.tempGuideScreen
            this.meterOption = {}
            this.scaleOption = {}
            this.cameraOption = {}
            break
        }
        this.searchReset()
      }
    },
    async onScaleLoad (page) {
      this.search.typeCode = this.activeName
      this.search.used = 1
      await count(this.search).then(res => {
        if (res.success) {
          this.used = '已用' + res.data + '台'
        } else {
          this.$message.error(res.msg)
        }
      })
      this.search.used = 0
      await count(this.search).then(res => {
        if (res.success) {
          this.unused = '未用' + res.data + '台'
          this.search.used = undefined
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onLoad (page) {
      this.search.typeCode = this.activeName
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        this.$nextTick(() => {
          this.$refs.crud.dicInit('cascader')
        })
        if (this.activeName === 'scale') {
          this.onScaleLoad(page)
        }
      })
    },
    summaryMethod () {
      const sums = []
      sums[1] = '使用情况:'
      sums[2] = this.used
      sums[3] = this.unused
      return sums
    },
    beforeScaleOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.module, this.scaleForm.id).then(res => {
          this.scaleForm = res.data
        })
      }
      done()
    },
    toAdd () {
      this.$refs.crud.rowAdd(this.scaleForm)
    },
    toBeiAn () {
      this.$router.push({ path: '/crm/devicemodel' })
    },
    toEdit (row) {
      this.$refs.crud.rowEdit(row)
    },
    unbind (row) {
      let ids = this.ids
      if (row.id) {
        ids = row.id
      }
      if (!ids || ids.length === 0) {
        this.$message.warning('请选择至少一个设备')
        return
      }
      this.$confirm('确定要解绑该设备?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        unbind(ids).then(res => {
          if (res.success) {
            this.$message.success('解绑成功')
            this.onLoad(this.page)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    toAddDeviceType () {
      this.$router.push({
        path: '/crm/devicetype'
      })
    }
  }
}
</script>
