<template>
  <basic-container>
    <avue-crud
        :rules="userRules"
        :option="option"
        :data="data"
        :defaults.sync="defaults"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        :page.sync="page"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.sys_user_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button
            type="primary"
            size="small"
            v-if="permission.sys_user_reset"
            icon="el-icon-refresh"
            @click="handleReset">密码重置
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { isValidMobile, isValidUsername, resetPassword } from '@/api/sys/user'
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import { isValidatePassword } from '@/util/validate'
import { getDetail } from '@/api/common'
export default {
  mixins: [mixin],
  data () {
    const validatePass = (rule, value, callback) => {
      const id = this.form.id
      if (!id && !isValidatePassword(value)[0]) {
        callback(new Error(isValidatePassword(value)[1]))
      } else {
        callback()
      }
    }
    const validateUsername = (rule, value, callback) => {
      const id = this.form.id
      isValidUsername(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('用户名不能重复'))
        } else {
          callback()
        }
      })
    }
    const validateMobile = (rule, value, callback) => {
      const id = this.form.id
      isValidMobile(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('手机号不能重复'))
        } else {
          callback()
        }
      })
    }
    return {
      userRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        mobile: [{ required: true, trigger: 'blur', validator: validateMobile }]
      },
      module: 'sys/user',
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      init: {
        roleTree: [],
        deptTree: []
      },
      defaults: {},
      tenants: [{ value: -1, label: '+' }],
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        dialogHeight: 450,
        searchMenuSpan: 5,
        searchIndex: 2,
        searchIcon: true,
        indexLabel: '序号',
        align: 'center',
        column: [
          {
            label: '用户名',
            prop: 'username',
            search: true,
            searchSpan: 7,
            searchRange: true,
            rules: [{
              required: true,
              validator: validateUsername
            }]
          },
          {
            label: '密码',
            prop: 'password',
            showPassword: true,
            hide: true,
            viewDisplay: false,
            placeholder: '密码至少六位，默认Good123123',
            rules: [{ validator: validatePass, trigger: 'blur' }]
          },
          {
            label: '手机号码',
            prop: 'mobile',
            search: true,
            searchSpan: 7,
            searchRange: true,
            maxlength: 11,
            showWordLimit: true,
            rules: [{
              required: true,
              validator: validateMobile
            }]
          },
          {
            label: '用户昵称',
            prop: 'name',
            search: true,
            searchSpan: 7,
            searchRange: true,
            rules: [{
              required: true,
              message: '请输入用户昵称'
            }]
          },
          {
            label: '角色',
            prop: 'roleIdList',
            multiple: true,
            search: true,
            searchSpan: 7,
            searchRange: true,
            hide: true,
            type: 'select',
            dicUrl: '/sys/role/dict'
          },
          {
            label: '角色',
            prop: 'roleNames',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '所属组织',
            prop: 'deptId',
            type: 'tree',
            searchSpan: 7,
            searchRange: true,
            dicUrl: '/sys/dept/tree?type=0',
            dataType: 'string',
            search: true,
            props: {
              value: 'id',
              label: 'name'
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_user_add, false),
        viewBtn: this.vaildData(this.permission.sys_user_view, false),
        delBtn: this.vaildData(this.permission.sys_user_delete, false),
        editBtn: this.vaildData(this.permission.sys_user_edit, false)
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.module, this.form.id).then(res => {
          this.form = res.data
          this.form.identity = null
        })
      }
      done()
    },
    handleReset () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择账号密码重置为123123?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return resetPassword(this.ids)
      }).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.$refs.crud.toggleSelection()
      })
    }
  }
}
</script>

<style>
</style>
