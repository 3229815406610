<template>
  <basic-container>
    <!--搜索-->
    <el-form :inline="true" :model="search" class="demo-form-inline">
      <el-form-item label="证件名称：">
        <el-input v-model="search.idCardName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchChange()">查询</el-button>
        <el-button  @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- 新增-->
    <div class="addcus">
      <el-button type="primary" @click="addcus">新增</el-button>
    </div>
    <!--表格数据-->
    <div class=“table”>
      <el-table :data="tableData" border>
        <el-table-column label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ (page.pageNo - 1) * page.pageSize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="idCardName" label="证件名称" min-width="80" align="center"/>
        <el-table-column prop="idCardNumber" label="证件号码" min-width="180" align="center"/>
        <el-table-column prop="mobileNumber" label="手机号码" min-width="180" align="center"/>
        <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="180" align="center"/>
        <el-table-column prop="cusApplicationId" label="系统订单号" min-width="180" align="center"/>
        <el-table-column prop="merchantId" label="商户号" min-width="180" align="center"/>
        <el-table-column label="进件状态" min-width="80" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.applicationStatus==='succeeded'" style="color: green">{{ dict.succeeded }}</span>
            <span v-if="scope.row.applicationStatus==='failed'" style="color: red">{{ dict.failed }}</span>
            <span v-if="scope.row.applicationStatus==='signing'" style="color: red">{{ dict.signing }}</span>
            <span v-if="scope.row.applicationStatus==='processing'" style="color: red">{{ dict.processing }}</span>
            <span v-if="scope.row.applicationStatus==='auditing'" style="color: red">{{ dict.auditing }}</span>
            <span
                v-if="scope.row.applicationStatus==='account_need_verify'"
                style="color: red">{{ dict.account_need_verify }}</span>
            <span v-if="scope.row.applicationStatus==='checking'" style="color: red">{{ dict.checking }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" min-width="180" align="center"/>
        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button type="text" size="small" @click="see(scope.row)">查看</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="deluser(scope.row)">删除</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button type="text" size="small" @click="balce(scope.row)">提现</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="flow(scope.row)">流水</el-button>
              </el-col>
              <el-col :span="8">
                <el-button type="text" size="small" @click="bindmerch(scope.row)">商户号</el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <div class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :page-sizes="[10,20,30,50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </div>

    <el-dialog title="查看详情" :visible.sync="dialogFormVisible">
      <el-form :model="form" label-position="right" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="证件人像面">
              <el-image
                  :src="form.idCardCopyHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.idCardCopyHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件国徽面">
              <el-image
                  :src="form.idCardNationalHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.idCardNationalHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="银行卡正面照">
              <el-image
                  :src="form.bankCardHttp"
                  fit="fill"
                  style="width: 220px; height: 160px"
                  @click="view(form.bankCardHttp)"
                  :preview-src-list="viewList"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账号">
              <el-input v-model="form.settleAcctBankAcctNo" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="证件有效期">
              <el-input v-model="form.idCardValidTime" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件名称">
              <el-input v-model="form.idCardName" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="证件号码">
              <el-input v-model="form.idCardNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码">
              <el-input v-model="form.mobileNumber" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电子账簿号">
              <el-input v-model="form.balanceAcctId" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="平台订单号">
              <el-input v-model="form.outRequestNo" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电子账簿账簿号">
              <el-input v-model="form.relAcctNo" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="个人用户号">
              <el-input v-model="form.cusId" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="系统订单号">
              <el-input v-model="form.cusApplicationId" :disabled="true"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户号">
              <el-input v-model="form.merchantId" :disabled="true"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="电子账簿信息和提现" :visible.sync="blance">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>电子账簿详情</span>
        </div>
        <el-table :data="blanceFrom" border>
          <el-table-column label="序号" width="80" align="center">{{1}} </el-table-column>
          <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="180" align="center"/>
          <el-table-column prop="settledAmount" label="可提现余额" min-width="180" align="center"/>
          <el-table-column prop="pendingAmount" label="在途余额" min-width="180" align="center"/>
          <el-table-column prop="expensingAmount" label="不可用余额" min-width="180" align="center"/>
          <el-table-column  label="电子账簿是否被冻结" min-width="180" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.isFrozen==='false'" style="color: green">未冻结</span>
                <span v-else-if="scope.row.isFrozen==='true'" style="color: red">已冻结</span>
              </template>
          </el-table-column>
          <el-table-column prop="frozenSettledAmount" label="冻结余额" min-width="180" align="center"/>
          <el-table-column prop="remark" label="备注" min-width="100" align="center"/>
        </el-table>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>提现</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="demo-input-suffix">
              <el-col :span="6">
                <span style="position: absolute;top: 5px;">提现金额：</span>
              </el-col>
              <el-col :span="18">
                <el-input v-model="withdrawals.amount"/>
              </el-col>
            </div>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" @click="Withdrawal">提现</el-button>
          </el-col>
        </el-row>
      </el-card>
        <el-card class="box-card" v-show="walsuc">
          <div slot="header" class="clearfix">
            <span>提现结果</span>
          </div>
          <el-table :data="withdrawalsResult" border>
            <el-table-column label="序号" width="80" align="center">{{1}} </el-table-column>
            <el-table-column prop="balanceAcctId" label="电子账簿号" min-width="180" align="center"/>
            <el-table-column prop="status" label="交易状态" min-width="180" align="center"/>
            <el-table-column prop="amount" label="金额" min-width="180" align="center"/>
            <el-table-column prop="serviceFee" label="平台手续费" min-width="180" align="center"/>
            <el-table-column prop="businessType" label="业务类型" min-width="180" align="center"/>
          </el-table>
        </el-card>
    </el-dialog>

    <el-dialog title="绑定商户号" :visible.sync="bindmerchSign">
      <el-card class="box-card">
        <el-form :model="paramesdetails" label-position="right" label-width="100px">
          <el-row :gutter="12">
            <el-col :span="12">
              <el-form-item label="商户名">
                <el-input v-model="paramesdetails.idCardName"></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="12">
              <el-form-item label="商户号">
                <el-input v-model="paramesdetails.merchantId"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bingSaveMerch">确 定</el-button>
        <el-button  @click="bindmerchSign = false">关 闭</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getUserInfo, selectbalance, createdWithdrawal, deleteUserInfo, bindmerchantId } from '@/api/szbank/szbank'

export default {
  data () {
    return {
      bindmerchSign: false,
      blance: false,
      dialogFormVisible: false,
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      search: {
        id: undefined,
        // 平台订单号
        outRequestNo: undefined,
        // 证件人像面照片http
        idCardCopyHttp: undefined,
        // 证件国徽面照片http
        idCardNationalHttp: undefined,
        // 证件号码
        idCardNumber: undefined,
        // 证件名称
        idCardName: undefined,
        // 证件有效期
        idCardValidTime: undefined,
        // 手机号码
        mobileNumber: undefined,
        // 开户银行编码
        settleAcctBankCode: undefined,
        // 开户名称
        settleAcctName: undefined,
        // 开户银行省编码
        settleAcctBankAddressCode: undefined,
        // 开户银行联行号
        settleAcctBankBranchCode: undefined,
        // 银行账号
        settleAcctBankAcctNo: undefined,
        // 交易授权码
        password: undefined,
        // 自定义参数
        metadata: undefined,
        // 合同编号
        extraContractNo: undefined,
        // 创建时间
        createdTime: undefined,
        // 进件状态
        applicationStatus: undefined,
        // 个人用户id
        cusId: undefined,
        // 电子账簿账簿号
        relAcctNo: undefined,
        // 银行簿记账户
        bindAcctNo: undefined,
        // 银行生成的户名
        bindAcctName: undefined,
        // 电子账簿ID
        balanceAcctId: undefined,
        // 绑定账户ID
        settleAcctId: undefined,
        // 系统订单号
        cusApplicationId: undefined,
        // 商户号
        merchantId: undefined,
        // 银行卡图片
        bankCardHttp: undefined,
        pageSize: 10,
        pageNo: 1
      },
      tableData: [],
      form: {},
      blanceFrom: [],
      viewList: [],
      dict: {
        checking: '资料校验',
        account_need_verify: '待账户验证（四要素鉴权）',
        auditing: '审核',
        processing: '处理中',
        signing: '电子签约中',
        succeeded: '已通过',
        failed: '被驳回'
      },
      withdrawals: {
        amount: undefined,
        serviceFee: undefined,
        balanceAcctId: undefined,
        bankAcctNo: undefined,
        bankMemo: undefined,
        password: undefined,
        remark: undefined,
        metadata: undefined,
        idCardName: undefined
      },
      walsuc: false,
      withdrawalsResult: [],
      parames: {
        outRequestNo: undefined,
        merchantId: undefined
      },
      paramesdetails: {
        outRequestNo: undefined,
        merchantId: undefined,
        idCardName: undefined
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    currentChange (val) {
      this.page.pageNo = val
      this.loadData()
    },
    sizeChange (val) {
      this.page.pageSize = val
      this.loadData()
    },
    async loadData () {
      this.search.pageNo = this.page.pageNo
      this.search.pageSize = this.page.pageSize
      const res = await getUserInfo(this.search)
      this.page.total = res.data.total
      this.tableData = res.data.records
    },
    // 查询
    searchChange () {
      this.loadData()
    },
    addcus () {
      this.$router.push({ path: '/szbank/cusapplications' })
    },
    see (e) {
      this.form = e
      this.dialogFormVisible = true
    },
    edit (row) {
      this.$router.push({
        path: '/szbank/cusapplications',
        query: { id: row.id, type: 1, cusId: row.cusId }
      })
    },
    view (e) {
      this.viewList = []
      this.viewList.push(e)
    },
    async balce (e) {
      this.walsuc = false
      this.blanceFrom = []
      const res = await selectbalance(e.balanceAcctId)
      if (res.success === false) {
        this.$message({
          type: 'error',
          message: '电子账号不存在'
        })
        return
      }
      this.blanceFrom.push(res.data)
      this.withdrawals.idCardName = e.idCardName
      this.withdrawals.balanceAcctId = e.balanceAcctId
      this.withdrawals.bankAcctNo = e.settleAcctBankAcctNo
      this.withdrawals.amount = res.data.settledAmount
      this.blance = true
    },
    async Withdrawal () {
      const res = await createdWithdrawal(this.withdrawals)
      if (res.success) {
        this.withdrawalsResult = []
        const value = res.data
        value.amount = res.data.amount * 1 / 100
        value.serviceFee = res.data.serviceFee * 1 / 100
        this.withdrawalsResult.push(value)
        this.walsuc = true
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },
    async deluser (e) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteUserInfo(e.outRequestNo, e.balanceAcctId)
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.loadData()
        } else {
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    flow (e) {
      this.$router.push({
        path: '/szbank/flowingwater',
        query: { balanceAcctId: e.balanceAcctId }
      })
    },
    cancel () {
      this.reset()
      this.loadData()
    },
    reset () {
      this.search = {
        id: undefined,
        outRequestNo: undefined,
        idCardCopyHttp: undefined,
        idCardNationalHttp: undefined,
        idCardNumber: undefined,
        idCardName: undefined,
        idCardValidTime: undefined,
        mobileNumber: undefined,
        settleAcctBankCode: undefined,
        settleAcctName: undefined,
        settleAcctBankAddressCode: undefined,
        settleAcctBankBranchCode: undefined,
        settleAcctBankAcctNo: undefined,
        password: undefined,
        metadata: undefined,
        extraContractNo: undefined,
        createdTime: undefined,
        applicationStatus: undefined,
        cusId: undefined,
        relAcctNo: undefined,
        bindAcctNo: undefined,
        bindAcctName: undefined,
        balanceAcctId: undefined,
        settleAcctId: undefined,
        cusApplicationId: undefined,
        pageSize: 10,
        pageNo: 1
      }
    },
    bindmerch (e) {
      this.parames.outRequestNo = e.outRequestNo
      this.paramesdetails.idCardName = e.idCardName
      this.bindmerchSign = true
    },
    async bingSaveMerch () {
      this.parames.merchantId = this.paramesdetails.merchantId
      const res = await bindmerchantId(this.parames)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '成功'
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message
        })
      }
      this.bindmerchSign = false
      this.loadData()
    }
  }

}
</script>

<style lang="scss" scoped>
.addcus {
  margin-top: 20px;
  margin-bottom: 10px;
}

.pagination-container {
  margin-top: 15px;
}

.demo-input-suffix {
  display: flex;
}
</style>
