<template>
  <basic-container>
    <avue-form :option="option" v-model="form" @submit="handleSubmit"/>
  </basic-container>
</template>

<script>
import { startProcess } from '@/api/process/repairs'

export default {
  data () {
    return {
      form: {},
      option: {
        group: [
          {
            icon: 'el-icon-info',
            label: '基础信息',
            prop: 'group',
            column: [
              {
                label: '审批人员',
                prop: 'taskUser',
                type: 'select',
                dicUrl: `/sys/user/dict`,
                span: 24,
                rules: [
                  {
                    required: true,
                    message: '请选择审批人员',
                    trigger: 'blur'
                  }
                ]
              },
              {
                label: '设备名称',
                prop: 'deviceName',
                rules: [{
                  required: true,
                  message: '请输入设备名称',
                  trigger: 'blur'
                }]
              },
              {
                label: '故障描述',
                prop: 'malfunction',
                rules: [{
                  required: true,
                  message: '请输入故障描述',
                  trigger: 'blur'
                }]
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit () {
      const params = {
        processDefinitionId: this.$route.params.processDefinitionId,
        ...this.form
      }
      startProcess(params).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.$router.push({ path: '/work/start' })
        } else {
          this.$message.error(res.msg || '提交失败')
        }
      })
    }
  }
}
</script>
