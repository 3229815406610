import { request } from '@/util/http'

export const getphone = (phone) => {
  return request({
    url: `/szbank/phonecode?phone=${phone}`,
    method: 'post'
  })
}
export const createdcus = (parmes) => {
  return request({
    url: `/szbank/createdcus`,
    method: 'post',
    timeout: 100000,
    data: parmes
  })
}
export const getUserInfo = (parmes) => {
  return request({
    url: `/szbank/getUserInfo`,
    method: 'post',
    data: parmes
  })
}

export const getNotice = (parmes) => {
  return request({
    url: `/szbank/getNotice`,
    method: 'post',
    data: parmes
  })
}

export const downloadTradeBills = (date) => {
  return request({
    url: `/szbank/downloadTradeBills?date=${date}`,
    method: 'post',
    responseType: 'blob',
    dataType: 'json',
    headers: { 'Content-type': 'application/json;' }
  })
}
export const selectbalance = (sn) => {
  return request({
    url: `/szbank/selectbalance?sn=${sn}`,
    method: 'post'
  })
}
export const createdWithdrawal = (parmes) => {
  return request({
    url: `/szbank/createdWithdrawal`,
    method: 'post',
    data: parmes
  })
}

export const updatecus = (parmes) => {
  return request({
    url: `/szbank/updatecus`,
    method: 'post',
    timeout: 100000,
    data: parmes
  })
}
export const selectUserInfoById = (id) => {
  return request({
    url: `/szbank/selectUserInfoById?id=${id}`,
    method: 'post'
  })
}
export const deleteUserInfo = (outNo, balanceId) => {
  return request({
    url: `/szbank/deleteUserInfo?outNo=${outNo}&&balanceId=${balanceId}`,
    method: 'post'
  })
}

export const getImage = (url) => {
  return request({
    url: `/szbank/image?url=${url}`,
    method: 'post',
    timeout: 100000
  })
}

export const selectBookRunning = (parames) => {
  return request({
    url: `/szbank/selectBookRunning`,
    method: 'post',
    data: parames
  })
}

export const bindmerchantId = (parames) => {
  return request({
    url: `/szbank/bindmerchantId`,
    method: 'post',
    data: parames
  })
}

export const bankcardIdentification = (url, bucket) => {
  return request({
    url: `/szbank/bankcardId`,
    method: 'post',
    params: {
      url,
      bucket
    }
  })
}

export const recognizeBusiness = (path, bucket) => {
  return request({
    url: '/base/enterprise/ocr',
    method: 'post',
    data: { path, bucket }
  })
}

export const createdmch = (parmes) => {
  return request({
    url: `/szbank/createdmch`,
    method: 'post',
    timeout: 100000,
    data: parmes
  })
}

export const getMchInfo = (parmes) => {
  return request({
    url: `/szbank/getMchInfo`,
    method: 'post',
    data: parmes
  })
}

export const selectMchById = (id) => {
  return request({
    url: `/szbank/selectMchById?id=${id}`,
    method: 'post'
  })
}
export const updatamch = (parmes) => {
  return request({
    url: `/szbank/updatamch`,
    method: 'post',
    timeout: 100000,
    data: parmes
  })
}

export const deleteMcchInfo = (outNo, balanceId) => {
  return request({
    url: `/szbank/deleteMcchInfo?outNo=${outNo}&&balanceId=${balanceId}`,
    method: 'post'
  })
}

export const bindMchmerchantId = (parames) => {
  return request({
    url: `/szbank/bindMchmerchantId`,
    method: 'post',
    data: parames
  })
}
export const getMchNotice = (parmes) => {
  return request({
    url: `/szbank/getMchNotice`,
    method: 'post',
    data: parmes
  })
}
export const getPayInfo = (parmes) => {
  return request({
    url: `/szbank/getPayInfo`,
    method: 'post',
    data: parmes
  })
}

export const recognize = (url, side, bucket) => {
  side = side.substr(0, 1).toUpperCase() + side.substr(1)
  const path = '/szbank/ocr' + side
  return request({
    url: path,
    method: 'post',
    data: { path: url, bucket: bucket }
  })
}
