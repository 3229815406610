<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowSave"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.mp_msgrelease_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { submit, getList } from '@/api/mp/msgrelease'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mp/msgrelease',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '消息内容',
            prop: 'msgData',
            type: 'textarea',
            minRows: 3,
            maxRows: 5,
            labelWidth: 150,
            width: 400,
            row: true,
            span: 24,
            maxlength: 2000,
            showWordLimit: true,
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入消息内容'
            }]
          },
          {
            label: '公众号',
            prop: 'appid',
            type: 'select',
            dicUrl: '/sys/userunion/dict',
            props: {
              label: 'label',
              value: 'value'
            },
            labelWidth: 150,
            cascader: ['openIds'],
            cascaderIndex: 1,
            dataType: 'string',
            rules: [
              {
                required: true,
                message: '请选择公众号',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '消息接受者',
            prop: 'openIds',
            type: 'select',
            dataType: 'string',
            multiple: true,
            dicUrl: `/sys/userunion/userdict?appid={{appid}}`,
            props: {
              label: 'label',
              value: 'value'
            },
            overHidden: true,
            labelWidth: 150,
            rules: [
              {
                required: true,
                message: '请选择接收者',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '推送频率(min)',
            prop: 'pushRate',
            type: 'number',
            min: 0,
            labelWidth: 150,
            width: 100
          },
          {
            label: '推送次数',
            prop: 'pushTime',
            type: 'number',
            labelWidth: 150,
            width: 100
          },
          {
            label: '推送执行时间',
            prop: 'pushDelayTime',
            type: 'datetime',
            labelWidth: 150,
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            span: 12,
            row: true,
            disabledDate (time) {
              return time.getTime() > Date.now()
            }
          },
          {
            label: '启用状态',
            prop: 'status',
            type: 'switch',
            row: true,
            labelWidth: 150,
            dataType: 'string',
            dicData: [{
              label: '关',
              value: 0
            }, {
              label: '开',
              value: 1
            }],
            value: 1
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mp_msgrelease_add,
        viewBtn: this.permission.mp_msgrelease_view,
        delBtn: this.permission.mp_msgrelease_delete,
        editBtn: this.permission.mp_msgrelease_edit
      }
    }
  },

  created () {
    getList(this.page.currentPage, this.page.pageSize, this.search).then(res => {
      const data = res.data
      this.page.total = data.total
      this.data = data.records
      this.$nextTick(() => {
        this.$refs.crud.dicInit('cascader')
      })
    })
  },
  methods: {
    rowSave (row, done, loading) {
      if (row.pushDelayTime !== undefined && row.id === undefined) {
        const delayTime = Date.parse(row.pushDelayTime)
        const currentDate = new Date()
        const currentDateTime = currentDate.getDate() + currentDate.getTime() + currentDate.getMilliseconds()
        if (delayTime < currentDateTime) {
          this.$message({
            type: 'error',
            message: '操作失败，执行日期不小于当前时间'
          })
          loading()
          return false
        }
      }

      submit(row).then(res => {
        if (row.id === undefined) {
          done()
        }

        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })

          getList(this.page.currentPage, this.page.pageSize, this.search).then(res => {
            const data = res.data
            this.page.total = data.total
            this.data = data.records
            this.$nextTick(() => {
              this.$refs.crud.dicInit('cascader')
            })
          })
          if (row.id !== undefined) {
            loading()
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    }
  }
}
</script>
