<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :defaults.sync="defaults"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            icon="el-icon-s-order"
            size="small"
            type="primary"
            @click.stop="toType"
        >类型备案
        </el-button>
        <el-button
            icon="el-icon-picture"
            size="small"
            type="primary"
            @click.stop="toBrand"
        >品牌备案
        </el-button>
        <el-button
            icon="el-icon-camera"
            size="small"
            type="primary"
            @click.stop="toModel"
        >型号备案
        </el-button>
      </template>
      <template slot="menu" slot-scope="{row}">
        <el-button
            v-if="(row.status > 0) || row.typeCode === 'scale'"
            icon="el-icon-download"
            size="small"
            type="text"
            @click.stop="toExport(row)">导出
        </el-button>
        <el-upload
            v-if="row.level === 1 && row.status === 0 && row.typeCode === 'scale'"
            :action="handleRowImport(row.id)"
            :headers="uploadHeaders()"
            :on-success="uploadSuc"
            :show-file-list="false"
            class="filter-item">
          <el-button icon="el-icon-film" type="text">入库</el-button>
        </el-upload>
        <el-button
            v-if="row.level === 1 && row.status === 0 && row.typeCode !== 'scale'"
            icon="el-icon-s-promotion"
            size="small"
            type="text"
            @click.stop="toStockIn(row)">
          <el-button icon="el-icon-film" type="text">入库</el-button>
        </el-button>
        <el-button
            v-if="row.stock > 0 && row.status == 1"
            icon="el-icon-s-promotion"
            size="small"
            type="text"
            @click.stop="toStockOut(row)">出库
        </el-button>
        <el-upload
            :action="handleIotConfig(row.id)"
            :headers="uploadHeaders()"
            :on-success="uploadSuc"
            :show-file-list="false"
            class="filter-item">
          <el-button icon="el-icon-s-cooperation" type="text">算法配置</el-button>
        </el-upload>
      </template>
    </avue-crud>
    <el-dialog :visible.sync="stockInVisible" title="入库" width="38%">
      <avue-crud
          ref="stockInCrud"
          v-model="form"
          :before-open="beforeStockInOpen"
          :data="form.deviceList"
          :defaults.sync="stockInDefaults"
          :option="stockInOption"
          :page.sync="stockInPage"
          :permission="permissionList"
          @row-update="stockInSave"
          @row-save="stockInSave">
      </avue-crud>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { baseUrl } from '@/config'
import { formatDate } from '@/util/date'
import { confirm, stockIn, tree } from '@/api/crm/devicepurchase'
import { getDetail as getModel } from '@/api/crm/devicemodel'
import { exportExcel, getDetail } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/devicepurchase',
      form: {},
      search: {},
      typeId: undefined,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      defaults: {},
      option: {
        tip: false,
        border: true,
        tree: true,
        defaultExpandAll: true,
        index: false,
        viewBtn: false,
        addBtn: true,
        editBtn: false,
        delBtn: false,
        selection: false,
        align: 'center',
        labelWidth: 120,
        addBtnText: '采购',
        indexLabel: '序号',
        column: [
          {
            label: '批次号',
            prop: 'batchNo',
            width: 240,
            search: true,
            align: 'left',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '日期',
            prop: 'batchDate',
            width: 100,
            type: 'date',
            format: 'yyyy-MM-dd',
            value: formatDate(new Date(), 'yyyy-MM-dd'),
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              required: true,
              message: '请设置采购批次日期'
            }]
          },
          {
            label: '型号',
            prop: 'modelName',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '型号',
            prop: 'modelId',
            type: 'select',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            search: true,
            select: true,
            dicUrl: '/crm/devicemodel/getDict?excludeTypeCodes=waterMeter,electricMeter,camera',
            change: (e) => {
              getModel(e.value).then((res) => {
                if (res.success && res.data) {
                  this.form.brandId = res.data.brandId
                  this.defaults.brandId.disabled = true
                  this.form.typeId = res.data.typeId
                  this.defaults.typeId.disabled = true
                  if (res.data.typeCode === 'scale') {
                    this.defaults.licenceType.rules = [{
                      required: true,
                      message: '请设置算法'
                    }]
                  } else {
                    this.defaults.licenceType.rules = []
                  }
                }
              })
            },
            placeholder: '请选择设备型号',
            rules: [{
              required: true,
              message: '请选择设备型号'
            }]
          },
          {
            label: '品牌',
            prop: 'brandName',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '品牌',
            prop: 'brandId',
            type: 'select',
            hide: true,
            disabled: true,
            search: true,
            addDisplay: true,
            editDisplay: true,
            filterable: true,
            select: true,
            dicUrl: '/crm/devicebrand/getDict'
          },
          {
            label: '类型',
            prop: 'typeName',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '类型',
            prop: 'typeId',
            type: 'select',
            hide: true,
            disabled: true,
            search: true,
            addDisplay: true,
            editDisplay: true,
            select: true,
            dicUrl: '/crm/devicetype/getDict'
          },
          {
            label: '算法提供厂家',
            prop: 'licenceType',
            type: 'select',
            display: true,
            dicData: [{ value: '阿里', label: '阿里' }, { value: 'TRE', label: 'TRE' }],
            rules: [{
              required: true,
              message: '请设置算法'
            }]
          },
          {
            label: '开发包',
            prop: 'sdk',
            hide: true
          },
          {
            label: '数量',
            prop: 'amount',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入采购数量'
            }]
          },
          {
            label: '库存数量',
            prop: 'stock',
            type: 'number',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '在途库存',
            prop: 'stocking',
            type: 'number',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '已出库数量',
            prop: 'amountOut',
            type: 'number',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '采购人',
            prop: 'director'
          },
          {
            label: '采购人电话',
            prop: 'directorMobile',
            maxlength: 11,
            width: 130,
            showWordLimit: true
          },
          {
            label: '设备单位',
            prop: 'unit'
          },
          {
            label: '已绑定',
            prop: 'amountUsed',
            type: 'number',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            dicData: [
              { value: 0, label: '待入库' },
              { value: 1, label: '已入库' },
              { value: 2, label: '出库中' },
              { value: 3, label: '已出库' }
            ],
            addDisplay: false,
            editDisplay: false
          }
        ]
      },
      data: [],
      stockInVisible: false,
      stockInDefaults: {},
      stockInPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      listLoading: false,
      stockInOption: {
        align: 'center',
        index: true,
        indexLabel: '序号',
        addBtn: false,
        delBtn: false,
        cellBtn: true,
        editBtnText: '入库',
        saveBtnText: '确定',
        refreshBtn: false,
        columnBtn: false,
        column: [
          {
            label: '序列号',
            prop: 'sn',
            cell: true
          }
        ]
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_devicepurchase_add,
        viewBtn: this.permission.crm_devicepurchase_view,
        delBtn: this.permission.crm_devicepurchase_delete,
        editBtn: this.permission.crm_devicepurchase_edit
      }
    }
  },
  methods: {
    handleConfirm (row) {
      confirm(row.id).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '收货成功!'
          })
          this.onLoad()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onLoad () {
      tree(this.search).then(res => {
        this.data = res.data
      })
    },
    toModel () {
      this.$router.push({ path: '/crm/devicemodel' })
    },
    toBrand () {
      this.$router.push({ path: '/crm/devicebrand' })
    },
    toType () {
      this.$router.push({
        path: '/crm/devicetype'
      })
    },
    toStockIn (row) {
      this.stockInVisible = true
      getDetail(this.module, row.id).then(res => {
        if (res.success) {
          this.form = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    beforeStockInOpen (done, type) {
      if (type === 'edit') {
        this.form.deviceList.forEach(item => {
          item.$cellEdit = true
        })
      }
      done()
    },
    stockInSave (device) {
      stockIn(device).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.stockInVisible = false
          this.onLoad()
        } else {
          this.$message.error('入库失败')
        }
      })
    },
    toStockOut (row) {
      this.$router.push({
        path: '/crm/devicepurchaseedit',
        query: {
          id: row.id,
          level: row.level
        }
      })
    },
    toExport (row) {
      this.search.id = row.id
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        if (res.size) {
          FileSaver.saveAs(res, '设备采购单' + row.batchNo + '.xlsx')
          this.$message({
            type: 'success',
            message: '导出成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        this.search.id = null
      })
    },
    handleRowImport (id) {
      return baseUrl + '/' + this.module + '/import/' + id
    },
    handleIotConfig (id) {
      return baseUrl + '/' + this.module + '/config/' + id
    }
  }
}
</script>
