<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            :defaults.sync="defaults"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad"
            @tree-load="treeLoad" >
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.sys_area_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getTree, getDetail } from '@/api/sys/area'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/area',
      form: {},
      search: {},
      defaults: {
        parentId: undefined
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        tree: true,
        lazy: true,
        border: true,
        align: 'center',
        index: true,
        viewBtn: true,
        indexLabel: '序号',
        selection: true,
        labelWidth: 120,
        areas: [],
        column: [
          {
            label: '名称',
            prop: 'name',
            align: 'left',
            rules: [{
              required: true,
              message: '请输入名称'
            }]
          },
          {
            label: '上级行政区划',
            prop: 'parentId',
            type: 'tree',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            lazy: true,
            props: {
              value: 'id',
              label: 'name'
            },
            dataType: 'string',
            value: '120000',
            placeholder: '请设置上级行政区划',
            treeLoad: (node, resolve) => {
              // 新增区域时，设置父节点为数字0(非字符0)，仅在窗口打开时初始化出根节点
              if (this.form.parentId === 0) {
                return
              }
              console.log('节点', node)
              const data = node.data || {}
              const parentId = data.id
              let list = []
              const callback = () => {
                resolve((list || []).map(ele => {
                  return Object.assign(ele, {
                    leaf: ele.leaf
                  })
                }))
              }
              getTree({ parentId: parentId }).then(res => {
                if (res.success) {
                  list = res.data
                  callback()
                  this.defaults.parentId.dicData = list
                }
              })
            }
          },
          {
            label: '区域数字编码',
            prop: 'codeNum',
            type: 'number',
            placeholder: '请设置区域数字编码',
            rules: [{
              required: true,
              message: '请设置区域数字编码'
            }]
          },
          {
            label: '区域级别',
            prop: 'level',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            placeholder: '请输入排序'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.sys_area_add,
        viewBtn: this.permission.sys_area_view,
        delBtn: this.permission.sys_area_delete,
        editBtn: this.permission.sys_area_edit
      }
    }
  },
  methods: {
    onLoad () {
      getTree().then(res => {
        this.data = res.data
      })
    },
    treeLoad (tree, treeNode, resolve) {
      getTree({ parentId: tree.id }).then((res) => {
        resolve(res.data)
      })
    },
    beforeOpen (done, type) {
      // 新增时，仅加载根节点
      if (type === 'add') {
        this.form.parentId = 0
        this.defaults.parentId.dicUrl = '/sys/area/tree'
        this.$refs.crud.updateDic('parentId')
        done()
      } else if (['edit', 'view'].includes(type)) {
        getDetail()
      }
      done()
    }
  }
}
</script>
