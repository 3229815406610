<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.crm_inspectspot_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/inspectspot',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '市场Id',
            prop: 'deptId',
            rules: [{
              required: true,
              message: '请输入市场Id'
            }]
          },
          {
            label: '巡检点类型，1：消防疏导点，2：厕所，3：垃圾房',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入巡检点类型，1：消防疏导点，2：厕所，3：垃圾房'
            }]
          },
          {
            label: '巡检点名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入巡检点名称'
            }]
          },
          {
            label: '巡检点照片',
            prop: 'pics',
            rules: [{
              required: true,
              message: '请输入巡检点照片'
            }]
          },
          {
            label: '状态，0：无效，1：有效',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态，0：无效，1：有效'
            }]
          },
          {
            label: '说明',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入说明'
            }]
          },
          {
            label: '删除标志',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入删除标志'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_inspectspot_add,
        viewBtn: this.permission.crm_inspectspot_view,
        delBtn: this.permission.crm_inspectspot_delete,
        editBtn: this.permission.crm_inspectspot_edit
      }
    }
  },
  methods: {}
}
</script>
