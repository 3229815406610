<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.ad_releaseitem_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'ad/releaseitem',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '广告位推送id',
            prop: 'releaseId',
            rules: [{
              required: true,
              message: '请输入广告位推送id'
            }]
          },
          {
            label: '配置id',
            prop: 'configId',
            rules: [{
              required: true,
              message: '请输入配置id'
            }]
          },
          {
            label: '图片/轮播图/视频',
            prop: 'customMedia',
            rules: [{
              required: true,
              message: '请输入图片/轮播图/视频'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.ad_releaseitem_add,
        viewBtn: this.permission.ad_releaseitem_view,
        delBtn: this.permission.ad_releaseitem_delete,
        editBtn: this.permission.ad_releaseitem_edit
      }
    }
  },
  methods: {
  }
}
</script>
