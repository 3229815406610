<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        :before-close="beforeClose"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @refresh-change="refreshChange"
        @search-reset="searchReset"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.dept_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row,index}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-plus"
            size="small"
            @click="addDept(row,index)">新增</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import { getDeptTree, submit } from '@/api/sys/dept'
import { remove } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/dept',
      form: {},
      search: {
        type: 0
      },
      disableParent: false,
      selectionList: [],
      option: {
        tip: false,
        tree: true,
        border: true,
        viewBtn: true,
        searchMenuSpan: 5,
        menuWidth: 300,
        column: [
          {
            label: '上级',
            prop: 'parentId',
            dicUrl: '/sys/dept/tree?type=0',
            type: 'tree',
            hide: true,
            props: {
              value: 'id',
              label: 'name'
            },
            disabled: false,
            rules: [{
              required: false,
              message: '请选择上级',
              trigger: 'click'
            }]
          },
          {
            label: '名称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入部门名称'
            }]
          },
          {
            label: '全称',
            prop: 'fullName',
            search: true,
            rules: [{
              required: true,
              message: '请输入部门全称'
            }]
          },
          {
            label: '负责人',
            prop: 'primaryPerson',
            remote: true,
            dataType: 'string',
            type: 'select',
            dicUrl: `/sys/user/dict?name={{key}}`,
            hide: true,
            addDisplay: false
          },
          {
            label: '副负责人',
            prop: 'deputyPerson',
            type: 'select',
            dicUrl: `/sys/user/dict`,
            hide: true,
            addDisplay: false
          },
          {
            label: '排序',
            prop: 'sort',
            type: 'number'
          },
          {
            label: '备注',
            prop: 'remark',
            span: 24,
            hide: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_dept_add, false),
        viewBtn: this.vaildData(this.permission.sys_dept_view, false),
        delBtn: this.vaildData(this.permission.sys_dept_delete, false),
        editBtn: this.vaildData(this.permission.sys_dept_edit, false)
      }
    }
  },
  watch: {
    disableParent () {
      const parentId = this.findObject(this.option.column, 'parentId')
      parentId.disabled = this.disableParent
    }
  },
  methods: {
    rowSave (row, done, loading) {
      row.type = 0
      submit(row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    onLoad () {
      getDeptTree(this.search).then(res => {
        this.data = res.data
      })
      if (this.$refs.crud) {
        this.$refs.crud.updateDic('parentId')
      }
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return remove(this.module, row.id).then(res => {
          if (res.success) {
            this.onLoad(this.page)
            this.$message({
              type: 'success',
              message: '操作成功!',
              duration: 2000
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        })
      })
    },
    // 添加对应行下的子菜单
    addDept (row) {
      this.form.parentId = row.id
      this.disableParent = true
      this.$refs.crud.rowAdd(this.form)
    },
    beforeClose (done, type) {
      this.disableParent = false
      done()
    }
  }
}
</script>

<style>
</style>
