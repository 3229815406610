<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            size="small"
            icon="el-icon-bottom"
            @click.prevent="exportOrder"
        >导 出
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toView(row)">查看详情
        </el-button>
      </template>
      <template slot="dateRangeSearch">
        <el-date-picker
            v-model="search.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/common'
import { exportExcel } from '@/api/mall/orderitem'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/order',
      form: {},
      search: {
        dateRange: []
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '所属市场',
            prop: 'marketId',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            cascader: ['merchantId'],
            search: true,
            hide: true
          },
          {
            label: '所属商户',
            prop: 'merchantId',
            search: true,
            filterable: true,
            dicUrl: '/crm/merchant/dict?deptId={{key}}',
            type: 'select'
          },
          {
            label: '订单编号',
            prop: 'orderSn',
            search: true
          },
          {
            label: '订单总金额',
            prop: 'totalAmount'
          },
          {
            label: '应付金额',
            prop: 'payAmount'
          },
          {
            label: '支付方式',
            prop: 'payType',
            type: 'select',
            dataType: 'number',
            dicUrl: 'sys/dict/code/pay_type'
          },
          {
            label: '订单来源',
            prop: 'sourceType',
            type: 'select',
            dataType: 'number',
            dicData: [{
              value: 0,
              label: '电子秤'
            }]
          },
          {
            label: '订单状态',
            prop: 'status',
            type: 'select',
            search: true,
            dataType: 'number',
            dicUrl: 'sys/dict/code/order_status'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '订单备注',
            prop: 'note'
          },
          {
            label: '时间范围',
            prop: 'dateRange',
            search: true,
            searchSpan: 12,
            searchSlot: true,
            hide: true,
            viewDisplay: false,
            editDisplay: false
          }
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_order_add,
        viewBtn: this.permission.mall_order_view,
        delBtn: this.permission.mall_order_delete,
        editBtn: this.permission.mall_order_edit
      }
    }
  },
  methods: {
    onLoad () {
      if (!this.search.marketId) {
        this.form = null
        this.$message({
          type: 'warning',
          message: '选择所属市场后查看数据'
        })
      } else {
        if (this.search.dateRange && this.search.dateRange.length === 2) {
          this.search.start = this.search.dateRange[0]
          this.search.end = this.search.dateRange[1]
        }
        getList(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
          const data = res.data
          this.page.total = data.total
          if (data.total === 0) {
            this.data = []
          } else {
            this.data = data.records
          }
          this.$nextTick(() => {
            this.$refs.crud.dicInit('cascader')
          })
        })
      }
    },
    toView (row) {
      this.$router.push({
        path: '/mall/orderview',
        query: { orderSn: row.orderSn }
      })
    },
    searchReset () {
      this.search = {
        start: undefined,
        end: undefined,
        dateRange: []
      }
      this.data = []
      this.page.total = 0
      this.onLoad(this.page)
    },
    exportOrder () {
      if (!this.search.marketId) {
        this.$message({
          type: 'warning',
          message: '选择所属市场后导出数据'
        })
      } else {
        exportExcel(this.search).then(res => {
          FileSaver.saveAs(res, '订单信息导出.xlsx')
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }
        )
      }
    }
  }
}
</script>
