<template>
  <basic-container>
    <avue-form
        :option="option"
        :data="data"
        ref="form"
        v-model="form"
        :defaults.sync="defaults"
        :permission="permissionList"
        @submit="rowSave">
      <template slot="menu" slot-scope="{row}">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="permission['sys_dept_add'] || permission['sys_dept_edit']"
            @click.stop="toEnterprise(row)">补充信息
        </el-button>
      </template>
    </avue-form>
  </basic-container>
</template>

<script>
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import { getDept } from '@/api/sys/dept'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/dept',
      form: {},
      defaults: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        tree: true,
        border: true,
        index: true,
        viewBtn: false,
        searchMenuSpan: 5,
        column: [
          {
            label: '类型',
            prop: 'type',
            value: 2,
            display: false
          },
          {
            label: '市场名称',
            prop: 'name',
            search: true,
            placeholder: '请输入市场名称',
            rules: [{
              required: true,
              message: '请输入市场名称'
            }]
          },
          {
            label: '市场全称',
            prop: 'fullName',
            search: true,
            placeholder: '请输入市场全称',
            rules: [{
              required: true,
              message: '请输入市场全称'
            }]
          },
          {
            label: '管理员',
            prop: 'primaryPerson',
            type: 'select',
            placeholder: '请选择主管理员',
            dataType: 'string',
            dicUrl: 'sys/user/dict',
            rules: [{
              required: true,
              message: '请选择主管理员'
            }]
          },
          {
            label: '所属租户',
            prop: 'tenantName',
            editDisplay: false,
            addDisplay: false
          },
          {
            label: '所属租户',
            prop: 'tenantId',
            type: 'select',
            dataType: 'number',
            dicUrl: '/sys/tenant/dic',
            search: true,
            placeholder: '请设置所属租户',
            hide: true,
            rules: [{
              required: true
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            span: 24,
            hide: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_dept_add, false),
        viewBtn: this.vaildData(this.permission.sys_dept_view, false),
        delBtn: this.vaildData(this.permission.sys_dept_delete, false),
        editBtn: this.vaildData(this.permission.sys_dept_edit, false)
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      getDept(this.$route.query.id).then(res => {
        if (res.success) {
          this.form = res.data
        }
      })
    } else {
      this.form.parentId = this.$route.query.parentId
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
