import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/mall/goods/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/goods/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/goods/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/goods/submit',
    method: 'post',
    data: row
  })
}

export const isValidateName = (name, id) => {
  return request({
    url: '/mall/goods/isValid',
    method: 'get',
    params: {
      name,
      id
    }
  })
}

export const getPlatList = (current, size, params) => {
  return request({
    url: '/mall/goods/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const platExportExcel = (params) => {
  return request({
    url: '/mall/goods/plat/export',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

export const platModelExcel = (current, size, params) => {
  return request({
    url: '/mall/goods/plat/model',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getGoodsStatList = (current, size, params) => {
  return request({
    url: '/mall/goods/com/goodsstat',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getGoodsLevelStatList = (current, size, params) => {
  return request({
    url: '/mall/goods/com/goodslevelstat',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getGoodsRankingList = (current, size, params) => {
  return request({
    url: '/mall/goods/com/goodsranking',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getGoodsLevelRankingList = (current, size, params) => {
  return request({
    url: '/mall/goods/com/goodslevelranking',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

