<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.mall_mapaynotify_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/mapaynotify',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '订单id',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入订单id'
            }]
          },
          {
            label: '订单id',
            prop: 'orderId',
            rules: [{
              required: true,
              message: '请输入订单id'
            }]
          },
          {
            label: '应用ID',
            prop: 'appid',
            rules: [{
              required: true,
              message: '请输入应用ID'
            }]
          },
          {
            label: '商户号',
            prop: 'mchid',
            rules: [{
              required: true,
              message: '请输入商户号'
            }]
          },
          {
            label: '商户订单号',
            prop: 'outTradeNo',
            rules: [{
              required: true,
              message: '请输入商户订单号'
            }]
          },
          {
            label: '微信支付订单号',
            prop: 'transactionId',
            rules: [{
              required: true,
              message: '请输入微信支付订单号'
            }]
          },
          {
            label: '交易类型',
            prop: 'tradeType',
            rules: [{
              required: true,
              message: '请输入交易类型'
            }]
          },
          {
            label: '交易状态',
            prop: 'tradeState',
            rules: [{
              required: true,
              message: '请输入交易状态'
            }]
          },
          {
            label: '交易状态描述',
            prop: 'tradeStateDesc',
            rules: [{
              required: true,
              message: '请输入交易状态描述'
            }]
          },
          {
            label: '付款银行',
            prop: 'bankType',
            rules: [{
              required: true,
              message: '请输入付款银行'
            }]
          },
          {
            label: '附加数据',
            prop: 'attach',
            rules: [{
              required: true,
              message: '请输入附加数据'
            }]
          },
          {
            label: '支付完成时间',
            prop: 'successTime',
            rules: [{
              required: true,
              message: '请输入支付完成时间'
            }]
          },
          {
            label: '支付人',
            prop: 'openid',
            rules: [{
              required: true,
              message: '请输入支付人'
            }]
          },
          {
            label: '总金额',
            prop: 'total',
            rules: [{
              required: true,
              message: '请输入总金额'
            }]
          },
          {
            label: '用户支付金额',
            prop: 'payerTotal',
            rules: [{
              required: true,
              message: '请输入用户支付金额'
            }]
          },
          {
            label: '终端设备号',
            prop: 'deviceId',
            rules: [{
              required: true,
              message: '请输入终端设备号'
            }]
          },
          {
            label: '优惠功能',
            prop: 'promotionDetails',
            rules: [{
              required: true,
              message: '请输入优惠功能'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_mapaynotify_add,
        viewBtn: this.permission.mall_mapaynotify_view,
        delBtn: this.permission.mall_mapaynotify_delete,
        editBtn: this.permission.mall_mapaynotify_edit
      }
    }
  },
  methods: {
  }
}
</script>
