<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.crm_merchantuser_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/merchantuser',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '租户id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入租户id'
            }]
          },
          {
            label: '商户id',
            prop: 'merchantId',
            rules: [{
              required: true,
              message: '请输入商户id'
            }]
          },
          {
            label: '手机号码',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入手机号码'
            }]
          },
          {
            label: '密码',
            prop: 'password',
            rules: [{
              required: true,
              message: '请输入密码'
            }]
          },
          {
            label: 'union_id',
            prop: 'unionid',
            rules: [{
              required: true,
              message: '请输入union_id'
            }]
          },
          {
            label: '微信昵称',
            prop: 'nickName',
            rules: [{
              required: true,
              message: '请输入微信昵称'
            }]
          },
          {
            label: '微信头像',
            prop: 'avatarUrl',
            rules: [{
              required: true,
              message: '请输入微信头像'
            }]
          },
          {
            label: '用户类型，0：户主，1：员工',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入用户类型，0：户主，1：员工'
            }]
          },
          {
            label: '员工与户主关系，参见字典表relation_type',
            prop: 'relation',
            rules: [{
              required: true,
              message: '请输入员工与户主关系，参见字典表relation_type'
            }]
          },
          {
            label: '删除标记',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入删除标记'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_merchantuser_add,
        viewBtn: this.permission.crm_merchantuser_view,
        delBtn: this.permission.crm_merchantuser_delete,
        editBtn: this.permission.crm_merchantuser_edit
      }
    }
  },
  methods: {
  }
}
</script>
