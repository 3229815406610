<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.mall_orderitem_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/orderitem',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '订单id',
            prop: 'orderId',
            rules: [{
              required: true,
              message: '请输入订单id'
            }]
          },
          {
            label: '订单编号',
            prop: 'orderSn',
            rules: [{
              required: true,
              message: '请输入订单编号'
            }]
          },
          {
            label: '重量',
            prop: 'weight',
            rules: [{
              required: true,
              message: '请输入重量'
            }]
          },
          {
            label: '价格',
            prop: 'price',
            rules: [{
              required: true,
              message: '请输入价格'
            }]
          },
          {
            label: '总金额',
            prop: 'totalAmount',
            rules: [{
              required: true,
              message: '请输入总金额'
            }]
          },
          {
            label: '支付金额',
            prop: 'payAmount',
            rules: [{
              required: true,
              message: '请输入支付金额'
            }]
          },
          {
            label: '折扣金额',
            prop: 'discountAmount',
            rules: [{
              required: true,
              message: '请输入折扣金额'
            }]
          },
          {
            label: '商品',
            prop: 'goodsId',
            rules: [{
              required: true,
              message: '请输入商品'
            }]
          },
          {
            label: '商家ID',
            prop: 'deviceId',
            rules: [{
              required: true,
              message: '请输入商家ID'
            }]
          },
          {
            label: '商家ID',
            prop: 'merchantId',
            rules: [{
              required: true,
              message: '请输入商家ID'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_orderitem_add,
        viewBtn: this.permission.mall_orderitem_view,
        delBtn: this.permission.mall_orderitem_delete,
        editBtn: this.permission.mall_orderitem_edit
      }
    }
  },
  methods: {}
}
</script>
