import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/mall/instrumentaccount/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/instrumentaccount/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/instrumentaccount/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/instrumentaccount/submit',
    method: 'post',
    data: row
  })
}

export const getDeviceAccount = (current, size, params) => {
  return request({
    url: '/mall/instrumentaccount/deviceaccount',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const exportDeviceAccount = (current, size, params) => {
  return request({
    url: '/mall/instrumentaccount/deviceaccount/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

