<template>
    <basic-container>
        <avue-crud
                ref="crud"
                v-model="form"
                :before-open="beforeOpen"
                :data="data"
                :defaults.sync="defaults"
                :option="option"
                :page.sync="page"
                :permission="permissionList"
                :search.sync="search"
                @row-del="rowDel"
                @row-update="rowUpdate"
                @row-save="rowSave"
                @search-change="searchChange"
                @search-reset="searchReset"
                @refresh-change="refreshChange"
                @selection-change="selectionChange"
                @current-change="currentChange"
                @size-change="sizeChange"
                @on-load="onLoad">
            <template slot="menuLeft">
                <el-button
                        icon="el-icon-bottom"
                        size="small"
                        type="success"
                        @click.prevent="handleExport"
                >导 出
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { exportDeviceAccount, getDeviceAccount } from '@/api/mall/instrumentaccount'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/instrumentaccount',
      form: {},
      search: {
        holdStatus: 0
      },
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: false,
        menu: false,
        searchMenuSpan: 130,
        menuWidth: 140,
        indexLabel: '序号',
        column: [
          {
            label: '单位名称',
            prop: 'deptId',
            type: 'select',
            hide: true,
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            search: true,
            labelWidth: 120
          },
          {
            label: '单位名称',
            prop: 'deptName',
            labelWidth: 120
          },
          {
            label: '地址',
            prop: 'address',
            labelWidth: 120
          },
          {
            label: '摊位号',
            prop: 'boothSn',
            labelWidth: 120
          },
          {
            label: '商户名',
            prop: 'merchantName',
            labelWidth: 120
          },
          {
            label: '联系电话',
            prop: 'mobile',
            labelWidth: 120
          },
          {
            label: '名称',
            prop: 'deviceName',
            labelWidth: 120
          },
          {
            label: '型号',
            prop: 'model',
            labelWidth: 120,
            width: 60
          },
          {
            label: '出厂编号sn',
            prop: 'deviceSn',
            search: true,
            labelWidth: 120
          },
          {
            label: '生产厂家',
            prop: 'manufacturer',
            labelWidth: 120
          },
          {
            label: '检定日期',
            prop: 'lastTestTime',
            labelWidth: 120,
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '检定状态',
            prop: 'status',
            type: 'select',
            dataType: 'number',
            labelWidth: 120,
            dicUrl: '/sys/dict/code/instrument_device_status'
          },
          {
            label: '铅封状态',
            prop: 'sealStatus',
            dicUrl: '/sys/dict/code/instrument_seal_status',
            labelWidth: 120,
            dataType: 'number',
            type: 'select'
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_instrumentaccount_add,
        viewBtn: this.permission.mall_instrumentaccount_view,
        delBtn: this.permission.mall_instrumentaccount_delete,
        editBtn: this.permission.mall_instrumentaccount_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      if (!this.search.deptId) {
        this.$message({
          type: 'warning',
          message: '选择所属市场后查看数据'
        })
      } else {
        getDeviceAccount(page.currentPage, page.pageSize, this.search).then(res => {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
        })
      }
    },
    handleExport () {
      if (this.selectionList.length > 0) {
        this.search.indexList = this.selectionList.map(item => item.id).join(',')
      } else {
        this.search.indexList = null
      }
      exportDeviceAccount(this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '设备台账导出.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    }
  }
}
</script>
<style>
</style>
