<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportLevelStat()">导出
        </el-button>
      </template>
      <template slot="dateRangeSearch">
        <el-date-picker
            v-model="search.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getGoodsLevelStatList } from '@/api/mall/goods'
import { exportExcel } from '@/api/common'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/goods',
      form: {},
      search: {
        dateType: 10,
        orderType: 0,
        dateRange: []
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        menu: false,
        addBtn: false,
        selection: true,
        searchMenuSpan: 150,
        indexLabel: '序号',
        column: [
          {
            label: '市场名称',
            prop: 'marketId',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            search: true,
            rules: [{
              required: true,
              message: '请输入所属市场'
            }]
          },
          {
            label: '时间',
            prop: 'time'
          },
          {
            label: '分类',
            prop: 'levelName'
          },
          {
            label: '订单数（笔）',
            prop: 'orderNum'
          },
          {
            label: '销售重量(千克)',
            prop: 'weight'
          },
          {
            label: '平均菜价(千克/元)',
            prop: 'avgPrice'
          },
          {
            label: '商品总额(元)',
            prop: 'payAmount'
          },
          {
            label: '查询日期类型',
            prop: 'dateType',
            type: 'select',
            clearable: false,
            dataType: 'number',
            dicData: [{
              label: '天',
              value: '10'
            }, {
              label: '月',
              value: '7'
            }, {
              label: '年',
              value: '4'
            }],
            search: true,
            hide: true,
            searchLabelWidth: 100,
            viewDisplay: false,
            editDisplay: false
          },
          {
            label: '时间',
            prop: 'dateRange',
            search: true,
            searchSpan: 12,
            searchSlot: true,
            hide: true,
            viewDisplay: false,
            editDisplay: false
          },
          {
            label: '订单状态',
            prop: 'orderType',
            type: 'select',
            clearable: false,
            dataType: 'number',
            dicUrl: '/sys/dict/code/order_stat_type',
            search: true,
            searchLabelWidth: 100,
            viewDisplay: false,
            editDisplay: false
          }
        ]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        viewBtn: this.permission.mall_goodsstat_view
      }
    }
  },
  methods: {
    onLoad (page) {
      if (!this.search.marketId) {
        this.form = null
        this.$message({
          type: 'warning',
          message: '选择所属市场后查看数据'
        })
      } else {
        if (this.search.dateRange && this.search.dateRange.length === 2) {
          this.search.start = this.search.dateRange[0]
          this.search.end = this.search.dateRange[1]
        }
        getGoodsLevelStatList(page.currentPage, page.pageSize, this.search).then(res => {
          const data = res.data
          this.page.total = data.total
          if (data.total === 0) {
            this.data = []
          } else {
            this.data = data.records
          }
        })
      }
    },
    searchReset () {
      this.search = {
        dateType: 10,
        orderType: 0,
        start: undefined,
        end: undefined,
        dateRange: []
      }
      this.data = []
      this.page.total = 0
      this.onLoad(this.page)
    },
    exportLevelStat () {
      this.handleExport('商品分类销量统计导出')
    },
    handleExport () {
      if (!this.search.marketId) {
        this.$message({
          type: 'warning',
          message: '选择所属市场后导出数据'
        })
      } else {
        exportExcel(this.module + '/goodslevel', this.page.currentPage, this.page.pageSize, this.search).then(res => {
          let type = ''
          if (this.search.orderType === 0) {
            type = '全部'
          } else if (this.search.orderType === 1) {
            type = '过磅'
          } else if (this.search.orderType === 2) {
            type = '清屏'
          } else {
            type = '结算'
          }
          FileSaver.saveAs(res, type + '数据导出' + '.xlsx')
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }
        )
      }
    }
  }
}
</script>
