<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAddDeviceType()"
        >新增类型
        </el-button>
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAddDeviceBrand()"
        >新增品牌
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { tree } from '@/api/crm/devicemodel'
import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/devicemodel',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        viewBtn: true,
        selection: true,
        align: 'center',
        column: [
          {
            label: '型号名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入型号名称'
            }],
            order: 20,
            row: true
          },
          {
            label: '标志图片',
            prop: 'icon',
            type: 'upload',
            listType: 'picture-img',
            oss: oss,
            tip: '上传图片不超过500kb',
            cropperOption: {
              autoCropWidth: 100,
              autoCropHeight: 100,
              fixed: true,
              fixedNumber: [100, 100]
            },
            order: 1,
            row: true
          },
          {
            label: '设备分类',
            prop: 'typeId',
            type: 'tree',
            rules: [{
              required: true,
              message: '请选择分类'
            }],
            props: {
              label: 'name',
              value: 'id'
            },
            dicUrl: '/crm/devicetype/tree',
            order: 19
          },
          {
            label: '设备品牌',
            prop: 'brandId',
            type: 'tree',
            rules: [{
              required: true,
              message: '请选择设备品牌'
            }],
            props: {
              label: 'name',
              value: 'id'
            },
            dicUrl: '/crm/devicebrand/tree',
            order: 18
          },
          {
            label: '开发包',
            prop: 'sdk',
            rules: [{
              required: false,
              message: '请输入开发包名称'
            }],
            order: 17
          },
          {
            label: '说明',
            prop: 'description',
            rules: [{
              required: false,
              message: '请输入说明'
            }],
            order: 16
          },
          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            value: 1,
            min: 1,
            order: 15
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            order: 14
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_devicemodel_add,
        viewBtn: this.permission.crm_devicemodel_view,
        delBtn: this.permission.crm_devicemodel_delete,
        editBtn: this.permission.crm_devicemodel_edit
      }
    }
  },
  methods: {
    onLoad (page, params = {}) {
      tree(page.currentPage, page.pageSize, params).then(res => {
        if (res.success && res.data) {
          this.data = res.data
          this.page.total = res.data.total
        } else {
          this.data = []
          this.page.total = 0
        }
      })
    },
    toAdd () {
      // this.$router.push({
      //   path: '/crm/deviceModelEdit'
      // })

      this.$refs.crud.rowAdd(this.form)
    },
    toEdit (row) {
      this.$refs.crud.rowEdit(row)
      // this.$router.push({
      //   path: '/crm/deviceModelEdit',
      //   query: { id: row.id }
      // })
    },
    toAddDeviceType () {
      this.$router.push({
        path: '/crm/devicetype'
      })
    },
    toAddDeviceBrand () {
      this.$router.push({
        path: '/crm/devicebrand'
      })
    }

  }
}
</script>
