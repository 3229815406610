<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.sys_menu_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button
            v-if="permission.role_admin"
            type="success"
            size="small"
            @click="handleRole(-1)">公司初始化菜单
        </el-button>
        <el-button
            v-if="permission.role_admin"
            type="success"
            size="small"
            @click="handleRole(-2)">市场初始化菜单
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="icon">
        <div style="text-align:center">
          <i :class="row.icon"></i>
        </div>
      </template>

      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>
    </avue-crud>
    <RoleBasic
        :forms="form"
        :permissions="permission"
        :roleList="list"
        :defaultKeys="defaultKeys"
        :pages="page"
        @cancel="cancel"
        v-if="box"
    ></RoleBasic>
  </basic-container>
</template>

<script>
import { getList, tree } from '@/api/sys/menu'
import { mapGetters } from 'vuex'
import iconList from '@/config/iconList'
import mixin from '@/util/mixin'
import { getByRole } from '@/api/sys/role'
import RoleBasic from '@/components/role/index'

export default {
  components: { RoleBasic },
  mixins: [mixin],
  data () {
    const validatePath = (rule, value, callback) => {
      if (value === '' && this.form.category === 1) {
        callback(new Error('请输入路由地址'))
      } else {
        callback()
      }
    }
    return {
      module: 'sys/menu',
      box: false,
      list: [],
      roleId: undefined,
      defaultObj: [],
      defaultKeys: [],
      form: {
        isPlat: 0
      },
      search: {
        isPlat: 0
      },
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        dialogWidth: '60%',
        tree: true,
        border: true,
        viewBtn: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '是否平台',
            prop: 'isPlat',
            type: 'radio',
            dataType: 'number',
            dicUrl: '/sys/dict/code/yes_no',
            search: true,
            searchValue: 0,
            hide: true
          },
          {
            label: '菜单名称',
            prop: 'name',
            search: true,
            rules: [
              {
                required: true,
                message: '请输入菜单名称'
              }
            ]
          },
          {
            label: '路由地址',
            prop: 'path',
            rules: [
              {
                required: true,
                validator: validatePath
              }
            ]
          },
          {
            label: '上级菜单',
            prop: 'parentId',
            type: 'tree',
            dicUrl: '/sys/menu/tree',
            hide: true,
            props: {
              value: 'id',
              label: 'name'
            },
            rules: [
              {
                required: false,
                message: '请选择上级菜单',
                trigger: 'click'
              }
            ]
          },
          {
            label: '菜单图标',
            prop: 'icon',
            type: 'icon',
            slot: true,
            width: 100,
            align: 'center',
            iconList: iconList,
            rules: [
              {
                required: true,
                message: '请输入菜单图标',
                trigger: 'click'
              }
            ]
          },
          {
            label: '权限标识',
            prop: 'code',
            search: true,
            rules: [
              {
                required: true,
                message: '请输入权限标识'
              }
            ]
          },
          {
            label: '菜单类型',
            prop: 'category',
            type: 'radio',
            dicData: [
              {
                label: '菜单',
                value: 1
              },
              {
                label: '按钮',
                value: 2
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择菜单类型'
              }
            ]
          },
          {
            label: '菜单排序',
            prop: 'sort',
            type: 'number',
            rules: [
              {
                required: true,
                message: '请输入菜单排序'
              }
            ]
          },
          {
            label: '新窗口',
            prop: 'open',
            type: 'radio',
            dataType: 'number',
            dicUrl: '/sys/dict/code/yes_no',
            hide: true
          },
          {
            label: '显示',
            prop: 'display',
            type: 'radio',
            dataType: 'number',
            dicUrl: '/sys/dict/code/yes_no',
            hide: true
          }
        ]
      },
      data: []
    }
  },
  watch: {
    box: {
      handler (val) {
        if (val === false) {
          this.defaultKeys = []
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_menu_add, false),
        viewBtn: this.vaildData(this.permission.sys_menu_view, false),
        delBtn: this.vaildData(this.permission.sys_menu_delete, false),
        editBtn: this.vaildData(this.permission.sys_menu_edit, false)
      }
    }
  },
  methods: {
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.data = data
      })
    },
    cancel () {
      this.box = false
    },
    handleRole (id) {
      this.roleId = id
      // 修复点击修改权限，已选中项的bug
      this.defaultObj = []
      this.form.id = id
      tree(null, id, 0)
        .then(res => {
          this.list = res.data
          this.updateObj(res.data)
          return getByRole(id)
        })
        .then(res => {
          this.defaultObj = res.data
          this.box = true
        })
    },
    updateObj (obj) {
      const defaultKeys = this.defaultKeys
      for (const key in obj) {
        if (key === 'checked' && obj[key] === true) {
          this.$set(this, 'defaultKeys', defaultKeys.concat(obj.id))
        }
        if (typeof obj[key] === 'object') {
          this.updateObj(obj[key])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
