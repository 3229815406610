import { request } from '@/util/http'

export const remove = (id, type) => {
  return request({
    url: '/sys/dept/remove',
    method: 'post',
    params: {
      id,
      type
    }
  })
}

export const updateStatus = (id, status) => {
  return request({
    url: '/sys/dept/status',
    method: 'post',
    params: {
      id,
      status
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/sys/dept/submit',
    method: 'post',
    data: row
  })
}

export const getDept = (id) => {
  return request({
    url: '/sys/dept/detail',
    method: 'get',
    params: {
      id
    }
  })
}
export const getDeptTree = (params) => {
  return request({
    url: '/sys/dept/tree',
    method: 'get',
    params
  })
}

export const getParentId = (parentId) => {
  return request({
    url: '/sys/dept/parentId',
    method: 'get',
    params: {
      parentId
    }
  })
}

export const getMarkets = () => {
  return request({
    url: '/sys/dept/dict/market',
    method: 'get'
  })
}
