<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.crm_scaleconfig_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/scaleconfig',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键，与t_crm_device主键一致',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键，与t_crm_device主键一致'
            }]
          },
          {
            label: '设备型号',
            prop: 'modelId',
            rules: [{
              required: true,
              message: '请输入设备型号'
            }]
          },
          {
            label: '对应的开发包',
            prop: 'sdk',
            rules: [{
              required: true,
              message: '请输入对应的开发包'
            }]
          },
          {
            label: '是否锁屏 0：不锁屏 1：锁屏',
            prop: 'lockFlag',
            rules: [{
              required: true,
              message: '请输入是否锁屏 0：不锁屏 1：锁屏'
            }]
          },
          {
            label: '是否自动累计 0:不自动 1：自动',
            prop: 'cumulativeFlag',
            rules: [{
              required: true,
              message: '请输入是否自动累计 0:不自动 1：自动'
            }]
          },
          {
            label: '是否拍照 0:正式版本 1：拍照版本',
            prop: 'showCameraFlag',
            rules: [{
              required: true,
              message: '请输入是否拍照 0:正式版本 1：拍照版本'
            }]
          },
          {
            label: 'ai识别。 0:不自动识别 1：ai自动识别',
            prop: 'aiFlag',
            rules: [{
              required: true,
              message: '请输入ai识别。 0:不自动识别 1：ai自动识别'
            }]
          },
          {
            label: 'ai上传云端开关 0：不显示， 1：显示',
            prop: 'showAiUpload',
            rules: [{
              required: true,
              message: '请输入ai上传云端开关 0：不显示， 1：显示'
            }]
          },
          {
            label: '订单抹零方式。0：单品抹零；1：整单抹零',
            prop: 'resetOrderType',
            rules: [{
              required: true,
              message: '请输入订单抹零方式。0：单品抹零；1：整单抹零'
            }]
          },
          {
            label: '是否自动清零 0:不自动 1: 自动',
            prop: 'resetFlag',
            rules: [{
              required: true,
              message: '请输入是否自动清零 0:不自动 1: 自动'
            }]
          },
          {
            label: '抹零方式 1：直接抹零 2：四舍五入',
            prop: 'resetType',
            rules: [{
              required: true,
              message: '请输入抹零方式 1：直接抹零 2：四舍五入'
            }]
          },
          {
            label: '清零单位 1:元 2:角 3:分',
            prop: 'resetUnit',
            rules: [{
              required: true,
              message: '请输入清零单位 1:元 2:角 3:分'
            }]
          },
          {
            label: '单品抹零方式 1：直接抹零 2：四舍五入',
            prop: 'singleResetType',
            rules: [{
              required: true,
              message: '请输入单品抹零方式 1：直接抹零 2：四舍五入'
            }]
          },
          {
            label: '清零单位 1:元 2:角 3:分',
            prop: 'singleResetUnit',
            rules: [{
              required: true,
              message: '请输入清零单位 1:元 2:角 3:分'
            }]
          },
          {
            label: '是否显示价格 1显示 0不显示',
            prop: 'showPrice',
            rules: [{
              required: true,
              message: '请输入是否显示价格 1显示 0不显示'
            }]
          },
          {
            label: '是否语音播报 1播报，0不播报',
            prop: 'voice',
            rules: [{
              required: true,
              message: '请输入是否语音播报 1播报，0不播报'
            }]
          },
          {
            label: '是否同步sku零售价格 0:不同步 1: 同步',
            prop: 'skuSyncFlag',
            rules: [{
              required: true,
              message: '请输入是否同步sku零售价格 0:不同步 1: 同步'
            }]
          },
          {
            label: '二维码支付方式 1:静态码 2:动态码  默认（静态码）',
            prop: 'qrCodePayType',
            rules: [{
              required: true,
              message: '请输入二维码支付方式 1:静态码 2:动态码  默认（静态码）'
            }]
          },
          {
            label: '备注信息',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注信息'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_scaleconfig_add,
        viewBtn: this.permission.crm_scaleconfig_view,
        delBtn: this.permission.crm_scaleconfig_delete,
        editBtn: this.permission.crm_scaleconfig_edit
      }
    }
  },
  methods: {
  }
}
</script>
