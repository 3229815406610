<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :defaults.sync="defaults"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        :upload-after="uploadAfter"
        :upload-before="uploadBefore"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="aliFileType">
        <span>{{ form.fileMd5 }}</span>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import SparkMD5 from 'spark-md5'
import { oss } from '@/config'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/scaleversion',
      form: {
        marketIds: [],
        marketNames: ''
      },
      search: {},
      marketTree: [],
      treeProps: {
        label: 'name',
        value: 'id'
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      defaults: {},
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        selection: false,
        indexLabel: '序号',
        column: [
          {
            label: '版本名称',
            prop: 'versionName',
            rules: [{
              required: true,
              message: '请输入版本名称'
            }]
          },
          {
            label: '类型',
            prop: 'deviceType',
            type: 'select',
            dicData: [{
              value: 1,
              label: '秤'
            }, {
              value: 2,
              label: '吊屏'
            }],
            rules: [{
              required: true,
              message: '请输入类型'
            }]
          },
          {
            label: '版本号',
            prop: 'versionCode',
            rules: [{
              required: true,
              message: '请输入版本号'
            }]
          },
          {
            label: '强制更新',
            prop: 'forceUpdate',
            type: 'switch',
            value: 1,
            dicUrl: '/sys/dict/code/switch',
            dataType: 'number',
            rules: [{
              required: true,
              message: '请输入强制更新'
            }]
          },
          {
            label: 'ai类型',
            prop: 'aiType',
            type: 'select',
            display: true,
            value: '1',
            dicUrl: '/sys/dict/code/ai_type',
            dataType: 'string',
            rules: [{
              required: true,
              message: '请选择ai类型'
            }]
          },
          {
            label: '更新范围',
            prop: 'scope',
            type: 'select',
            dataType: 'number',
            select: true,
            dicUrl: 'sys/dict/code/scale_version_scope',
            rules: [{
              required: true,
              message: '请选择更新范围'
            }]
          },
          {
            label: '市场',
            prop: 'marketIds',
            type: 'tree',
            dicUrl: '/sys/dept/tree/market',
            props: {
              label: 'name',
              value: 'id'
            },
            hide: true,
            viewDisplay: false,
            addDisplay: false,
            leafOnly: true,
            multiple: true
          },
          {
            label: '市场',
            prop: 'marketNames',
            addDisplay: false,
            span: 24,
            hide: true
          },
          {
            label: '更新内容',
            prop: 'updateContent',
            type: 'textarea',
            span: 24,
            rules: [{
              required: true,
              message: '请输入更新内容'
            }]
          },
          {
            label: '安装包',
            prop: 'aliFile',
            type: 'upload',
            hide: true,
            span: 24,
            limit: 1,
            dragFile: true,
            accept: '.apk',
            tip: '只能上传apk文件',
            oss: oss,
            rules: [{
              required: true,
              message: '请上传安装包'
            }]
          },
          {
            label: 'md5',
            prop: 'fileMd5',
            addDisplay: false,
            hide: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_scaleversion_add,
        viewBtn: this.permission.crm_scaleversion_view,
        delBtn: this.permission.crm_scaleversion_delete,
        editBtn: this.permission.crm_scaleversion_edit
      }
    }
  },

  watch: {
    'form.deviceType' (val1) {
      if (val1 === 1) {
        this.defaults.aiType.display = true
      } else if (val1 === 2) {
        this.defaults.aiType.display = false
      }
    },
    'form.scope' (val) {
      if (val === 1) {
        // 市场
        this.defaults.marketIds.addDisplay = true
        this.defaults.marketNames.viewDisplay = true
      } else {
        this.defaults.marketNames.viewDisplay = false
        this.defaults.marketIds.addDisplay = false
      }
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'market-scale-apk')
  },
  methods: {
    uploadBefore (file, done) {
      done()
      const reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = (event) => {
        this.form.fileMd5 = SparkMD5.hashBinary(event.target.result)
      }
      this.form.fileSize = file.size / 1024
    },
    uploadAfter (res, done) {
      this.form.fileUrl = res.url
      done()
    }
  }
}
</script>
