<template>
  <basic-container>
    <avue-crud
        :rules="goodsRules"
        :option="option"
        :data="data"
        :defaults.sync="defaults"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-upload
            class="filter-item"
            :show-file-list="false"
            :auto-upload="false"
            size="small"
            :on-change='importPlatExcel'
            action="#"
            style="display: inline-block">
          <el-button type="warning" icon="el-icon-top">导 入</el-button>
        </el-upload>
        <el-button type="warning" plain @click.stop="handleGoodsModel()">模板下载</el-button>
        <el-button
            type="success"
            size="small"
            icon="el-icon-bottom"
            @click.prevent="exportGoods"
        >导 出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { getPlatList, isValidateName, platExportExcel, platModelExcel } from '@/api/mall/goods'
import { importExcel } from '@/api/common'
import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    const validateName = (rule, value, callback) => {
      const id = this.form.id
      if (!value) {
        callback(new Error('商品名不能为空'))
        return
      }
      isValidateName(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('商品名称不能重复'))
        } else {
          callback()
        }
      })
    }
    return {
      goodsRules: {
        name: [{ required: true, trigger: 'blur', validator: validateName }]
      },
      module: 'mall/goods',
      form: {},
      search: {},
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        searchMenuSpan: 150,
        indexLabel: '序号',
        column: [
          {
            label: '商品名称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              validator: validateName
            }]
          },
          {
            label: '大分类',
            prop: 'level1Id',
            type: 'select',
            search: true,
            filterable: true,
            dicUrl: '/mall/level1/dict',
            cascader: ['level2Id', 'level3Id'],
            rules: [{
              required: true,
              message: '请选择大分类'
            }]
          },
          {
            label: '中分类',
            prop: 'level2Id',
            type: 'select',
            cascader: ['level3Id'],
            search: true,
            dicUrl: '/mall/level2/dict?level1Id={{key}}',
            rules: [{
              required: true,
              message: '请选择中分类'
            }]
          },
          {
            label: '标准名',
            prop: 'level3Id',
            search: true,
            type: 'select',
            filterable: true,
            dicUrl: '/mall/level3/dict?level2Id={{key}}',
            rules: [{
              required: true,
              message: '请输入标准名'
            }]
          },
          {
            label: '商品图片',
            prop: 'pic',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            oss: oss,
            tip: '上传图片不超过500kb',
            cropperOption: {
              autoCropWidth: 500,
              autoCropHeight: 500
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goods_add,
        viewBtn: this.permission.mall_goods_view,
        delBtn: this.permission.mall_goods_delete,
        editBtn: this.permission.mall_goods_edit
      }
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
  },
  methods: {
    onLoad (page) {
      getPlatList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        this.$nextTick(() => {
          this.$refs.crud.dicInit('cascader')
        })
      })
    },
    handleGoodsModel () {
      platModelExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '商品信息模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    exportGoods () {
      if (this.selectionList.length > 0) {
        this.search.idList = this.selectionList.map(item => item.id).join(',')
      } else {
        this.search.idList = null
      }
      platExportExcel(this.search).then(res => {
        FileSaver.saveAs(res, '商品信息导出.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    importPlatExcel (file) {
      const module = 'mall/goods/plat'
      importExcel(module, file).then(res => {
        const that = this
        if (res.type === 'application/json') {
          const reader = new FileReader()
          reader.readAsText(res, 'utf-8')
          reader.onload = function () {
            res = JSON.parse(reader.result)
            if (res.success) {
              that.$message({
                type: 'success',
                message: '导入完成!'
              })
              that.onLoad(that.page)
            } else {
              that.$message({
                type: 'error',
                message: res.msg
              })
            }
          }
        } else {
          this.$message.warning('导入有部分失败数据，请查看导出的失败数据')
          that.onLoad(that.page)
          FileSaver.saveAs(res, '导入失败数据.xlsx')
        }
      })
    }
  }
}
</script>
