<template>
  <div>
    <basic-container>
      <el-tabs v-model="activeName">
        <el-tab-pane label="个人信息" name="info">
          <avue-form
              :option="option"
              v-model="form"
              :defaults.sync="defaults"
              @submit="handleSubmit">
          </avue-form>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="password">
          <avue-form
              :option="optionPsw"
              v-model="formPsw"
              @submit="handleSubmitPsw">
          </avue-form>
        </el-tab-pane>
        <el-tab-pane label="绑定微信登录" name="authWeChant">
          <wxlogin
              v-if="showWechat"
              :visible.sync="showWechat"
              style="float:left;"
              :appid=wxOpenKey()
              :scope="'snsapi_login'"
              :theme="'write'"
              :redirect_uri='encodeURIComponent(wxOpenUrl())'
              rel="external nofollow">
          </wxlogin>
          <div v-else>
            <div>
              <i
                  class="el-icon-check"
                  style="display: block;margin:0 0 20px 20px;color: green;font-weight: bolder;font-size: 35px;"></i>
              <span style="display: block;margin:0 0 20px 10px;">已绑定</span>
            </div>
            <el-button type="primary" @click="cancelOpen">取消绑定</el-button>
          </div>
          <div  v-show="showWechat" style="float: left; margin-top: -60px; height:60vh;width: 100%;background: white"></div>
        </el-tab-pane>
      </el-tabs>
    </basic-container>
  </div>
</template>

<script>
import { getUserInfo, updatePassword, bindOpen, cancelOpen, updateSelf } from '@/api/sys/user'
import wxlogin from 'vue-wxlogin'
import { wxOpenKey, wxOpenUrl, oss } from '@/config'

export default {
  components: {
    wxlogin
  },
  data () {
    return {
      idValue: undefined,
      showWechat: true,
      activeName: 'info',
      defaults: {},
      form: {},
      formPsw: {},
      option: {
        column: [
          {
            label: '头像',
            type: 'upload',
            listType: 'picture-img',
            oss: oss,
            span: 12,
            row: true,
            prop: 'avatarUrl'
          },
          {
            label: '用户昵称',
            span: 12,
            row: true,
            disabled: true,
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入用户昵称'
            }]
          },
          {
            label: '手机号',
            span: 12,
            disabled: true,
            row: true,
            prop: 'mobile'
          },
          {
            label: '身份证号码',
            span: 12,
            row: true,
            prop: 'identity'
          },
          {
            label: '年龄',
            span: 12,
            row: true,
            prop: 'age'
          },
          {
            label: '性别',
            span: 12,
            row: true,
            dataType: 'number',
            type: 'radio',
            dicUrl: 'sys/dict/code/gender',
            prop: 'sex'
          },
          {
            label: '证件照',
            span: 12,
            row: true,
            prop: 'identityPic',
            type: 'upload',
            listType: 'picture-card',
            dataType: 'string',
            oss: 'ali'
          }
        ]
      },
      optionPsw: {
        column: [
          {
            label: '原密码',
            span: 12,
            row: true,
            type: 'password',
            prop: 'oldPassword'
          }, {
            label: '新密码',
            span: 12,
            row: true,
            type: 'password',
            prop: 'newPassword',
            placeholder: '密码至少六位',
            rules: [{
              required: true,
              message: '请输入新密码'
            }]
          }, {
            label: '确认密码',
            span: 12,
            row: true,
            type: 'password',
            prop: 'newPassword1',
            rules: [{
              required: true,
              message: '请输入确认密码'
            }]
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
    this.loadData()
  },
  computed: {
    code () {
      return this.$route.query.codes
    },
    tagList () {
      return this.$store.getters.tagList
    }
  },
  methods: {
    wxOpenKey () {
      return wxOpenKey
    },
    wxOpenUrl () {
      return wxOpenUrl
    },
    handleSubmit (form, done) {
      updateSelf(form).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '修改信息成功!'
          })
          this.$store.dispatch('GetUserInfo')
          this.loadData()
          this.activeName = 'info'
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }, error => {
        window.console.log(error)
        done()
      })
    },
    handleSubmitPsw (form, done) {
      updatePassword(form.oldPassword, form.newPassword, form.newPassword1).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '修改密码成功!'
          })
          this.loadData()
          this.activeName = 'password'
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }, error => {
        window.console.log(error)
        done()
      })
    },
    loadData () {
      getUserInfo({ db: true }).then(res => {
        this.form = res.data
        if (this.code) {
          if (res.data.unionid) {
            this.showWechat = false
          } else {
            bindOpen(this.code, this.wxOpenKey()).then(bind => {
              if (bind.success) {
                this.$store.dispatch('GetUserInfo', { db: true })
                this.duplicate()
                this.$router.push({ path: '/user/info' })
                this.activeName = 'authWeChant'
                this.showWechat = false
              } else {
                this.duplicate()
                this.$router.push({ path: '/user/info' })
                this.activeName = 'authWeChant'
                this.$message.error('请重新绑定')
              }
            })
          }
        } else {
          if (res.data.unionid) {
            this.showWechat = false
          } else {
            this.showWechat = true
          }
        }
      })
    },
    async cancelOpen () {
      const res = await cancelOpen()
      if (res.success) {
        this.$store.dispatch('GetUserInfo', { db: true })
        this.showWechat = true
      }
    },
    duplicate () {
      this.tagList.forEach((item) => {
        if (item.value.includes('/user/info?')) {
          this.$store.commit('DEL_TAG', item)
        }
      })
    }
  }
}
</script>

<style>
</style>
