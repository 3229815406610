<template>
    <basic-container>
        <el-form ref="form" :model="form" label-width="80px">
            <el-row type="flex" class="row-bg" justify="end">
                <el-form-item>
                    <el-button @click="handleCancel">关闭</el-button>
                </el-form-item>
            </el-row>
            <el-card shadow="hover">
                <div slot="header">
                    <span>审批信息</span>
                </div>
                <el-form-item label="申请人">
                    <el-input :disabled="true" v-model="form.flow.assigneeName" />
                </el-form-item>
                <el-form-item label="设备名称：" prop="deviceName">
                    <el-input :disabled="true" v-model="form.deviceName" ></el-input>
                </el-form-item>
                <el-form-item label="故障描述：" prop="malfunction">
                    <el-input :disabled="true" v-model="form.malfunction" ></el-input>
                </el-form-item>
            </el-card>
            <el-card shadow="hover">
                <div slot="header">
                    <span>流程信息</span>
                </div>
                <el-row type="flex" class="row-bg">
                    <el-timeline>
                        <el-timeline-item :key="flow.id" :timestamp="flow.createTime" v-for="flow in flowList" placement="top">
                            <el-card shadow="hover">
                                <p>{{flow.assigneeName}} 在 [{{flow.createTime}}] 开始处理 [{{flow.historyActivityName}}] 环节</p>
                                <p v-if="flow.historyActivityDurationTime!==''">任务历时 [{{flow.historyActivityDurationTime}}]</p>
                                <p v-if="flow.comment!==''">审核意见: [{{flow.comment}}]</p>
                                <p v-if="flow.endTime!==''">结束时间: [{{flow.endTime}}]</p>
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </el-row>
            </el-card>
            <el-card shadow="hover">
                <div slot="header">
                    <span>流程跟踪</span>
                </div>
                <el-row type="flex" class="row-bg">
                    <img :src="src" alt="design">
                </el-row>
            </el-card>
        </el-form>
    </basic-container>
</template>

<script>
import { historyFlowList } from '@/api/work/process'
import { getDetail } from '@/api/process/repairs'
import { flowUrl } from '@/config'

export default {
  data () {
    return {
      taskId: '',
      businessId: '',
      processInstanceId: '',
      src: '',
      flowList: [],
      form: {
        flow: {
          assigneeName: ''
        },
        deviceName: undefined,
        malfunction: undefined
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.taskId = this.$route.params.taskId
      this.processInstanceId = this.$route.params.processInstanceId
      this.businessId = this.$route.params.businessId
      this.src = `${flowUrl}${this.$route.params.processInstanceId}`
      historyFlowList(this.processInstanceId).then(res => {
        const data = res.data
        if (data.success) {
          this.flowList = data.data
        }
      })
      getDetail(this.businessId, this.taskId).then(res => {
        if (res.success) {
          this.form = res.data
        }
      })
    },
    handleCancel () {
      this.$router.push({ path: '/work/start' })
    }
  }
}
</script>
