<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :defaults.sync="defaults"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.cms_tvshow_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'cms/tvshow',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      defaults: {},
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }]
          },
          {
            label: '地址',
            prop: 'url',
            rules: [{
              required: true,
              message: '请输入地址'
            }]
          },
          {
            label: '媒体类型',
            prop: 'mediaType',
            type: 'select',
            rules: [{
              required: true,
              message: '请输入媒体类型'
            }]
          },
          {
            label: '图片',
            prop: 'img',
            type: 'upload',
            row: true,
            display: false,
            dataType: 'string',
            oss: oss,
            listType: 'picture-img',
            rules: [{
              required: true,
              message: '请输入图片'
            }]
          },
          {
            label: '轮播图',
            prop: 'card',
            type: 'upload',
            row: true,
            display: false,
            dataType: 'string',
            oss: oss,
            listType: 'picture-card',
            rules: [{
              required: true,
              message: '请输入轮播图'
            }]
          },
          {
            label: '视频',
            prop: 'video',
            type: 'upload',
            row: true,
            display: false,
            dataType: 'string',
            oss: oss,
            accept: 'video/mp4',
            rules: [{
              required: true,
              message: '请输入视频'
            }]
          },
          {
            label: '是否发布',
            prop: 'isRelease',
            rules: [{
              required: true,
              message: '请输入是否发布'
            }]
          },
          {
            label: '市场id',
            prop: 'marketId',
            rules: [{
              required: true,
              message: '请输入市场id'
            }]
          },
          {
            label: '租户id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入租户id'
            }]
          },
          {
            label: '删除标志',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入删除标志'
            }]
          }
        ]
      },
      data: []
    }
  },
  watch: {
    'form.mediaType' (val) {
      if (val === 0) {
        this.defaults.img.display = true
        this.defaults.card.display = false
        this.defaults.video.display = false
        this.onLoad()
      } else if (val === 1) {
        this.defaults.img.display = false
        this.defaults.card.display = true
        this.defaults.video.display = false
        this.onLoad()
      } else if (val === 2) {
        this.defaults.img.display = false
        this.defaults.card.display = false
        this.defaults.video.display = true
        this.onLoad()
      }
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
  },
  handleRest () {
    this.defaults.img.display = false
    this.defaults.card.display = false
    this.defaults.video.display = false
    this.form.mediaType = undefined
    this.form.img = undefined
    this.form.card = undefined
    this.form.card = undefined
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.cms_tvshow_add,
        viewBtn: this.permission.cms_tvshow_view,
        delBtn: this.permission.cms_tvshow_delete,
        editBtn: this.permission.cms_tvshow_edit
      }
    }
  },
  methods: {}
}
</script>
