<template>
  <basic-container>
    <el-card class="good-card">
      <div slot="header">
        <span v-if="type">编辑进件</span>
        <span v-else>新增进件</span>
      </div>
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="证件人像面照片：" prop="idCardCopyHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.idCardCopyHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :prefix="'szbank'"
                      :initData="form.idCardCopyHttp"
                      :uploadClass="'upload-pic'"
                      :canClose="false"
                      @updateList="deleteImageFace"
                      @handleSuccess="handleRecognizeFace"
                  >
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="证件国徽面照片：" prop="idCardNationalHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.idCardNationalHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :initData="form.idCardNationalHttp"
                      :prefix="'szbank'"
                      :uploadClass="'upload-pic'"
                      @updateList="deleteImageBack"
                      @handleSuccess="handleRecognizeBack">
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="银行卡正面：" prop="bankCardHttp">
                <div class="start top">
                  <good-cropper
                      v-model="form.bankCardHttp"
                      :showFileList="false"
                      :private="true"
                      :bucket="'zhsn-scale-private'"
                      :prefix="'szbank'"
                      :initData="form.bankCardHttp"
                      :uploadClass="'upload-pic'"
                      :canClose="false"
                      @updateList="deleteBankCard"
                      @handleSuccess="handleBankCard"
                  >
                  </good-cropper>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="证件号码：" prop="idCardNumber">
                <el-input v-model="form.idCardNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件有效期开始：" prop="idCardValidTimeStart">
                <el-input v-model.trim="form.idCardValidTimeStart"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="证件名称：" prop="idCardName">
                <el-input v-model="form.idCardName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="证件有效期结束：" prop="idCardValidTimeEnd">
                <el-input v-model.trim="form.idCardValidTimeEnd"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="开户名称：" prop="settleAcctName">
                <el-input v-model="form.settleAcctName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="银行账号：" prop="settleAcctBankAcctNo">
                <el-input v-model="form.settleAcctBankAcctNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

<!--          <el-row :gutter="20">-->
<!--            <el-col :span="8">-->
<!--          <el-form-item label="开户银行编码：" prop="settleAcctBankCode">-->
<!--            <el-input v-model="form.settleAcctBankCode"></el-input>-->
<!--          </el-form-item>-->
<!--            </el-col>-->
<!--              <el-col :span="8">-->
<!--          <el-form-item label="开户银行省编码：" prop="settleAcctBankAddressCode">-->
<!--            <el-input v-model="form.settleAcctBankAddressCode"></el-input>-->
<!--          </el-form-item>-->
<!--              </el-col>-->
<!--                <el-col :span="8">-->
<!--          <el-form-item label="开户银行联行号：" prop="settleAcctBankBranchCode">-->
<!--            <el-input v-model="form.settleAcctBankBranchCode"></el-input>-->
<!--          </el-form-item>-->
<!--          </el-col>-->
<!--          </el-row>-->

<!--          <el-row :gutter="20"  v-show="sign">-->
<!--            <el-col :span="12">-->
<!--              <el-form-item label="交易授权码：" prop="password">-->
<!--                <el-input v-model="form.password"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="12">-->
<!--              <el-form-item label="合同编号：" prop="extraContractNo">-->
<!--                <el-input v-model="form.extraContractNo"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="手机号码：" prop="mobileNumber">
                <el-input v-model="form.mobileNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="短信验证码：" prop="smsCode">
                <el-input v-model="form.smsCode"/>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-button
                  type="primary"
                  :disabled="sendModel.countFlag"
                  @click="getCode">
                {{ sendModel.btnMsg == null ? sendModel.countNum + 's后重新发送' : sendModel.btnMsg }}
              </el-button>

            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="save()">保存</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>

        </el-form>
      </div>

    </el-card>

  </basic-container>
</template>

<script>
import GoodCropper from '@/components/good-cropper/index'
import { getphone, createdcus, selectUserInfoById, updatecus, getImage, bankcardIdentification, recognize } from '@/api/szbank/szbank'
export default {
  components: { GoodCropper },
  data () {
    return {
      sendModel: {
        // 倒计时周期
        countNum: 60,
        // 用于倒计时标记，true-正在倒计时
        countFlag: false,
        // 定时器
        intervalBtn: {},
        // 默认按钮的值
        btnMsg: '获取验证码'
      },
      addflag: true,
      loading: {
        add: false,
        addAg: false
      },
      names: [],
      imgVisible: false,
      form: {
        // 证件人像面照片http
        idCardCopyHttp: undefined,
        // 证件人像面照片http
        idCardNationalHttp: undefined,
        // 证件人像面照片
        idCardCopy: undefined,
        // 证件人像面照片
        idCardNational: undefined,
        // 证件号码
        idCardNumber: undefined,
        // 证件名称
        idCardName: undefined,
        // 证件有效期开始
        idCardValidTimeStart: undefined,
        // 证件有效期结束
        idCardValidTimeEnd: undefined,
        // 手机号码
        mobileNumber: undefined,
        // 开户银行编码
        settleAcctBankCode: undefined,
        // 开户名称
        settleAcctName: undefined,
        // 开户银行省编码
        settleAcctBankAddressCode: undefined,
        // 开户银行联行号
        settleAcctBankBranchCode: undefined,
        // 银行账号
        settleAcctBankAcctNo: undefined,
        // 短信验证码
        smsCode: undefined,
        // 交易授权码
        password: undefined,
        // 自定义参数
        metadata: undefined,
        // 合同编号
        extraContractNo: undefined,
        // 银行卡照片
        bankCardHttp: undefined
      },
      updateFrom: {},
      rules: {
        idCardCopyHttp: [{ required: true, trigger: 'blur', message: '请上传身份证人面' }],
        idCardNationalHttp: [{ required: true, trigger: 'blur', message: '请上传身份证国徽面' }]
      },
      unitDisable: true,
      type: undefined,
      sign: true
    }
  },
  created () {
    this.type = this.$route.query.type
    if (this.type === '1') {
      this.sign = false
    }
    this.getUserInfo()
  },

  methods: {
    chooseImg (pic) {
      this.form.pic = pic
      this.imgVisible = false
    },

    async getUserInfo () {
      if (this.type === '1') {
        const res = await selectUserInfoById(this.$route.query.id)
        this.form = res.data
        this.form.idCardValidTimeStart = res.data.idCardValidTime.split(',')[0]
        this.form.idCardValidTimeEnd = res.data.idCardValidTime.split(',')[1]
        this.form.smsCode = undefined
      }
    },

    save () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.type === '1') {
            this.updateFrom = { ...this.form }
            this.$set(this.updateFrom, 'cusId', this.$route.query.cusId)
            const res = await updatecus(this.updateFrom)
            if (res.success) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.$router.push({ path: '/szbank/cusapplicationsdetails' })
            }
          } else {
            const res = await createdcus(this.form)
            if (res.success) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.$router.push({ path: '/szbank/cusapplicationsdetails' })
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          }
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/szbank/cusapplicationsdetails' })
    },
    async waitphone () {
      try {
        const res = await getphone(this.form.mobileNumber)
        if (res.success) {
          this.$message({
            type: 'success',
            message: res.msg
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      } catch (res) {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },

    countDown () {
      // 设置btn倒计时时显示的信息
      this.sendModel.btnMsg = null
      // 更改btn状态
      this.sendModel.countFlag = !this.sendModel.countFlag
      this.waitphone()
      // 设置倒计时
      this.sendModel.intervalBtn = setInterval(() => {
        if (this.sendModel.countNum <= 0) {
          // 重置btn提示信息
          this.sendModel.btnMsg = '获取验证码'
          // 清除定时器
          clearInterval(this.sendModel.intervalBtn)
          // 更改btn状态
          this.sendModel.countFlag = !this.sendModel.countFlag
          // 重置倒计时状态
          this.sendModel.countNum = 60
        }
        // 倒计时
        this.sendModel.countNum--
      }, 1000)
    },
    getCode () {
      this.countDown()
    },
    handleRecognizeFace (imageUrl, bucket) {
      this.handleRecognize(imageUrl, 'face', bucket)
    },
    handleRecognizeBack (imageUrl, bucket) {
      this.handleRecognize(imageUrl, 'back', bucket)
    },
    async handleRecognize (imageUrl, side, bucket) {
      const resImage = await getImage(imageUrl)
      if (resImage.success) {
        recognize(imageUrl, side, bucket).then(res => {
          if (res.success) {
            if (res.data !== undefined) {
              if (side === 'face') {
                this.form.idCardName = res.data.name
                this.form.idCardNumber = res.data.idCardNo
                this.form.settleAcctName = res.data.name
                this.form.idCardCopy = resImage.data
              }
              if (side === 'back') {
                this.form = { ...this.form }
                this.form.idCardValidTimeStart = res.data.validFrom
                this.form.idCardValidTimeEnd = res.data.validTo
                this.form.idCardNational = resImage.data
              }
              this.$message({
                type: 'success',
                message: '成功',
                duration: 2000
              })
            } else {
              this.$message({
                type: 'error',
                message: '识别结果为空',
                duration: 2000
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        }).catch((e) => {

        })
      } else {
        if (side === 'face') {
          this.deleteImageFace()
        }
        if (side === 'back') {
          this.deleteImageBack()
        }
        this.$message({
          type: 'error',
          message: '请重新上传',
          duration: 2000
        })
      }
    },
    deleteImageFace () {
      this.form.idCardName = undefined
      this.form.idCardNumber = undefined
      this.form.settleAcctName = undefined
      this.form.idCardCopyHttp = undefined
      this.form.idCardCopy = undefined
    },
    deleteImageBack () {
      this.form.idCardValidTimeStart = undefined
      this.form.idCardValidTimeEnd = undefined
      this.form.idCardNationalHttp = undefined
      this.form.idCardValidTime = undefined
      this.form.idCardNational = undefined
    },
    async  handleBankCard (imageUrl, bucket) {
      try {
        const res = await bankcardIdentification(imageUrl, bucket)
        this.form.settleAcctBankAcctNo = res.data
      } catch (e) {
        this.form.bankCardHttp = undefined
        this.$message({
          type: 'error',
          message: '请重新上传',
          duration: 2000
        })
      }
    },
    deleteBankCard () {
      this.form.settleAcctBankAcctNo = undefined
    }
  }
}
</script>

<style lang="scss">
.choose-img {
  margin-left: 15px;
}

.img-box {
  width: 200px;
  height: 200px;
  margin: 15px;
  border: 2px solid #eee;

  img {
    height: 100%;
  }
}
</style>
