<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.icbc_suborderitem_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'icbc/suborderitem',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '工行清分记录id',
            prop: 'subOrderId',
            rules: [{
              required: true,
              message: '请输入工行清分记录id'
            }]
          },
          {
            label: '原交易消费订单号',
            prop: 'seqNo',
            rules: [{
              required: true,
              message: '请输入原交易消费订单号'
            }]
          },
          {
            label: '工行子订单序号',
            prop: 'subOrderSeq',
            rules: [{
              required: true,
              message: '请输入工行子订单序号'
            }]
          },
          {
            label: '顺序号',
            prop: 'recNum',
            rules: [{
              required: true,
              message: '请输入顺序号'
            }]
          },
          {
            label: '交易流水号',
            prop: 'subOrderTrxid',
            rules: [{
              required: true,
              message: '请输入交易流水号'
            }]
          },
          {
            label: '0-成功;1-失败;2-未知',
            prop: 'recResult',
            rules: [{
              required: true,
              message: '请输入0-成功;1-失败;2-未知'
            }]
          },
          {
            label: '原交易日期',
            prop: 'oriTrxDate',
            rules: [{
              required: true,
              message: '请输入原交易日期'
            }]
          },
          {
            label: '录入失败或未知时输出错误信息',
            prop: 'retMsg',
            rules: [{
              required: true,
              message: '请输入录入失败或未知时输出错误信息'
            }]
          },
          {
            label: '上送本笔子订单需要分账的二级商户编号',
            prop: 'subMerId',
            rules: [{
              required: true,
              message: '请输入上送本笔子订单需要分账的二级商户编号'
            }]
          },
          {
            label: '清算金额',
            prop: 'classifyAmt',
            rules: [{
              required: true,
              message: '请输入清算金额'
            }]
          },
          {
            label: '原交易消费订单类型，1-使用外部订单号 2-使用工行订单号',
            prop: 'busiType',
            rules: [{
              required: true,
              message: '请输入原交易消费订单类型，1-使用外部订单号 2-使用工行订单号'
            }]
          },
          {
            label: '与输入数据的sub_order_id相同',
            prop: 'subOrderNo',
            rules: [{
              required: true,
              message: '请输入与输入数据的sub_order_id相同'
            }]
          },
          {
            label: '工行订单号',
            prop: 'orderId',
            rules: [{
              required: true,
              message: '请输入工行订单号'
            }]
          },
          {
            label: '录入失败或未知时输出错误信息',
            prop: 'retCode',
            rules: [{
              required: true,
              message: '请输入录入失败或未知时输出错误信息'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.icbc_suborderitem_add,
        viewBtn: this.permission.icbc_suborderitem_view,
        delBtn: this.permission.icbc_suborderitem_delete,
        editBtn: this.permission.icbc_suborderitem_edit
      }
    }
  },
  methods: {}
}
</script>
