<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        >
      <template slot="menuLeft">
        <el-button type="success" @click="toOrder">返回</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getItem } from '@/api/mall/orderitem'
import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/orderitem',
      form: {},
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        indexLabel: '序号',
        menu: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '订单编号',
            prop: 'orderSn'
          },
          {
            label: '商户名称',
            prop: 'merchantName'
          },
          {
            label: '商户全称',
            prop: 'merchantFullName'
          },
          {
            label: '商品名称',
            prop: 'goodsName'
          },
          {
            label: '商品图片',
            prop: 'pic',
            type: 'upload',
            listType: 'picture-img',
            oss: oss,
            tip: '上传图片不超过500kb'
          },
          {
            label: '大分类',
            prop: 'level1Name'
          },
          {
            label: '中分类',
            prop: 'level2Name'
          },
          {
            label: '标准名',
            prop: 'level3Name'
          },
          {
            label: '重量',
            prop: 'weight'
          },
          {
            label: '价格',
            prop: 'price'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '支付金额',
            prop: 'payAmount'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
    this.form.orderSn = this.$route.query.orderSn
    if (this.form.orderSn) {
      this.loadData()
    }
  },
  methods: {
    toOrder () {
      this.$router.push({ path: '/mall/order' })
    },
    async loadData () {
      const res = await getItem(this.form.orderSn)
      this.data = res.data
    }
  }
}
</script>
