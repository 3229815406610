<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                v-if="permission.base_contact_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/contact',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: 'sys_tenant.id',
            prop: 'tenantId',
            rules: [{
              required: true,
              message: '请输入sys_tenant.id'
            }]
          },
          {
            label: '账户编号，type=0，取sys_user.id，type=1，取t_crm_merchant_user.id',
            prop: 'userId',
            rules: [{
              required: true,
              message: '请输入账户编号，type=0，取sys_user.id，type=1，取t_crm_merchant_user.id'
            }]
          },
          {
            label: '账户类型，0：sys_user，1：t_crm_merchant_user',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入账户类型，0：sys_user，1：t_crm_merchant_user'
            }]
          },
          {
            label: '姓名',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入姓名'
            }]
          },
          {
            label: '性别',
            prop: 'gender',
            rules: [{
              required: true,
              message: '请输入性别'
            }]
          },
          {
            label: '生日',
            prop: 'birthday',
            rules: [{
              required: true,
              message: '请输入生日'
            }]
          },
          {
            label: '年龄',
            prop: 'age',
            rules: [{
              required: true,
              message: '请输入年龄'
            }]
          },
          {
            label: '联系方式',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入联系方式'
            }]
          },
          {
            label: '法人身份证号',
            prop: 'idCardNo',
            rules: [{
              required: true,
              message: '请输入法人身份证号'
            }]
          },
          {
            label: '身份证正面',
            prop: 'idCardFace',
            rules: [{
              required: true,
              message: '请输入身份证正面'
            }]
          },
          {
            label: '身份证背面',
            prop: 'idCardBack',
            rules: [{
              required: true,
              message: '请输入身份证背面'
            }]
          },
          {
            label: '身份证合并图片',
            prop: 'idCard',
            rules: [{
              required: true,
              message: '请输入身份证合并图片'
            }]
          },
          {
            label: '身份证有效期起',
            prop: 'validFrom',
            rules: [{
              required: true,
              message: '请输入身份证有效期起'
            }]
          },
          {
            label: '身份证有效期止',
            prop: 'validTo',
            rules: [{
              required: true,
              message: '请输入身份证有效期止'
            }]
          },
          {
            label: '健康证号',
            prop: 'healthLicence',
            rules: [{
              required: true,
              message: '请输入健康证号'
            }]
          },
          {
            label: '健康证图片',
            prop: 'healthLicenceUrl',
            rules: [{
              required: true,
              message: '请输入健康证图片'
            }]
          },
          {
            label: '健康证有效期起',
            prop: 'healthLicenceValidFrom',
            rules: [{
              required: true,
              message: '请输入健康证有效期起'
            }]
          },
          {
            label: '身份证有效期止',
            prop: 'healthLicenceValidTo',
            rules: [{
              required: true,
              message: '请输入身份证有效期止'
            }]
          },
          {
            label: '住址',
            prop: 'address',
            rules: [{
              required: true,
              message: '请输入住址'
            }]
          },
          {
            label: '删除标记',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入删除标记'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_contact_add,
        viewBtn: this.permission.base_contact_view,
        delBtn: this.permission.base_contact_delete,
        editBtn: this.permission.base_contact_edit
      }
    }
  },
  methods: {
  }
}
</script>
