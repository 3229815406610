<template>
  <basic-container>
    <avue-form
        :option="option"
        v-model="form"
        :defaults.sync="defaults"
        @reset-change="resetBack"
        :upload-after="uploadAfter"
        :upload-before="uploadBefore"
        @submit="handleSubmit">
    </avue-form>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getTree } from '@/api/sys/area'
import { getDetail, recognize, submit } from '@/api/base/enterprise'
import { deepClone } from '@/util'
import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/enterprise',
      form: {},
      option: {
        border: true,
        index: true,
        viewBtn: true,
        emptyBtn: true,
        emptyText: '清空并返回',
        selection: true,
        indexLabel: '序号',
        align: 'center',
        labelWidth: 150,
        column: [
          {
            label: '',
            prop: 'title',
            type: 'title',
            span: 24,
            styles: {
              fontSize: '24px',
              paddingLeft: '30%',
              paddingBottom: '30px'
            }
          },
          // {
          //   label: '营业执照',
          //   width: 160,
          //   prop: 'creditUrl',
          //   type: 'upload',
          //   dataType: 'string',
          //   listType: 'picture-img',
          //   accept: 'image/png,image/jpeg',
          //   tip: '只能上传jpg/png图片,且不能超过3MB',
          //   loadText: '图片上传中，请稍等',
          //   headers: {
          //     'Content-Type': 'application/octet-stream;charset=utf-8'
          //   },
          //   data: {
          //     bucket: 'zhsn-market-file',
          //     prefix: 'enterprise'
          //   },
          //   propsHttp: {
          //     res: 'data'
          //   },
          //   action: '/base/enterprise/oss'
          // },
          // {
          //   label: '支付码',
          //   width: 160,
          //   prop: 'payCode',
          //   dataType: 'string',
          //   type: 'upload',
          //   listType: 'picture-img',
          //   accept: 'image/png,image/jpeg',
          //   tip: '只能上传jpg/png图片,且不能超过3MB',
          //   loadText: '图片上传中，请稍等',
          //   headers: {
          //     'Content-Type': 'application/octet-stream;charset=utf-8'
          //   },
          //   data: {
          //     bucket: 'zhsn-market-file',
          //     prefix: 'enterprise'
          //   },
          //   propsHttp: {
          //     res: 'data'
          //   },
          //   action: '/base/enterprise/oss'
          // },

          {
            label: '营业执照',
            width: 160,
            prop: 'creditUrl',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-img',
            accept: 'image/png,image/jpeg',
            loadText: '图片上传中，请稍等',
            span: 24,
            oss: oss,
            tip: '只能上传jpg/png图片,且不能超过3MB'
          },
          {
            label: '统一社会信用代码',
            prop: 'creditCode',
            placeholder: '请输入统一社会信用代码'
          },
          {
            label: '法定代表人姓名',
            prop: 'legalPersonName',
            placeholder: '请输入法定代表人姓名'
          },
          {
            label: '成立时间',
            prop: 'beginAt',
            type: 'date',
            placeholder: '请设置企业成立时间'
          },
          {
            label: '有效期止',
            prop: 'operatingTo',
            type: 'date',
            placeholder: '请设置企业注册有效期止'
          },
          {
            label: '是否长期',
            prop: 'isLongTerm',
            type: 'radio',
            value: '1',
            dicUrl: '/sys/dict/code/yes_no',
            dataType: 'string',
            placeholder: '请设置企业是否长期有效'
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            dicUrl: 'sys/dict/code/present_status',
            dataType: 'string',
            placeholder: '请设置企业当前状态'
          },
          {
            label: '所属区域',
            prop: 'areaId',
            type: 'cascader',
            dataType: 'string',
            props: {
              label: 'name',
              value: 'id'
            },
            lazy: true,
            lazyLoad (node, resolve) {
              if (node.level === 0) {
                getTree({ parentId: node.level, enterpriseCompanyId: this.$route.query.companyId }).then(res => {
                  resolve(res.data)
                })
              } else {
                getTree({ parentId: node.data.id }).then(res => {
                  resolve(res.data)
                })
              }
            }
          },
          {
            label: '注册地址',
            prop: 'address',
            placeholder: '请输入注册地址'
          },
          {
            label: '经纬度',
            prop: 'map',
            type: 'map'
          },
          {
            label: '联系电话',
            prop: 'phone',
            placeholder: '请输入联系电话'
          },
          {
            label: '企业性质',
            prop: 'type',
            type: 'select',
            dataType: 'string',
            dicUrl: '/sys/dict/code/enterprise_type',
            placeholder: '请设置企业性质'
          },
          {
            label: '注册资本',
            prop: 'registeredCapital',
            append: '万元',
            placeholder: '请设置企业注册资本'
          }
        ]
      },
      data: [],
      defaults: {}
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
    this.init()
  },
  computed: {
    // permissionList () {
    //   return {
    //     addBtn: this.permission.base_enterprise_add,
    //     viewBtn: this.permission.base_enterprise_view,
    //     delBtn: this.permission.base_enterprise_delete,
    //     editBtn: this.permission.base_enterprise_edit
    //   }
    // }
  },
  methods: {
    loadData () {
      getDetail(this.form).then(res => {
        if (res.data !== undefined) {
          this.form = res.data
        }
        this.form.title = this.$route.query.title
      })
    },
    handleRecognize (imageUrl, bucket) {
      this.creditUrl = imageUrl
      recognize(imageUrl, bucket).then((res) => {
        if (res.success) {
          const legalPerson = this.form.legalPerson
          this.form = res.data
          this.form.legalPerson = legalPerson
          this.form.legalPerson.name = this.form.legalPersonName
          this.form.creditUrl = this.creditUrl
          this.typeRefresh = true
          this.$emit('set-enterprise', this.form)
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            duration: 2000
          })
        }
      }).catch((e) => {
        this.$message({
          type: 'error',
          message: e
        })
      })
    },
    handleSubmit (done, loading) {
      const form = deepClone(this.form)
      const map = form.map
      if (map) {
        if (map.length) {
          form.center = map[1] + ' ' + map[0]
          form.address = map[2]
        }
        delete form.map
      }
      submit(form).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$router.go(-1)
          done()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    resetBack () {
      this.form = {}
      this.$router.go(-1)
    },
    init () {
      if (this.$route.query.companyId) {
        // 公司
        this.form.deptId = this.$route.query.companyId
        this.loadData()
      } else if (this.$route.query.marketId) {
        // 市场
        this.form.deptId = this.$route.query.marketId
        this.loadData()
      } else if (this.$route.query.merchantId) {
        // 商户
        this.form.deptId = null
        this.form.merchantId = this.$route.query.merchantId
        this.form.id = this.$route.query.enterpriseId
        this.loadData()
      } else {
        this.$message.warning('公司、市场、商户信息错误')
        this.$router.go(-1)
      }
    },

    uploadAfter (file, done, loading, column) {
      if (column.prop === 'payCode') {
        this.form.payCode = file.url
      }
      if (column.prop === 'creditUrl') {
        done()
        recognize(file.url).then((res) => {
          if (res.success) {
            this.form = { ...this.form, ...res.data }
          } else {
            this.form.creditUrl = undefined
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: e
          })
        })
      }
      loading()
    },
    uploadBefore (file, done, loading, column) {
      const newFile = new File([file], 'enterprise/' + this.getNowDate() + '/' + file.name, { type: file.type })
      done(newFile)
    },
    getNowDate () {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      return `${year}-${month}-${day}`
    }

  }
}
</script>
