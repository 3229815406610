<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @on-load="onLoad">
      <template slot-scope="{row}" slot="menu">
        <el-button
            v-if="permission.sys_dept_edit"
            size="small"
            icon="el-icon-unlock"
            type="text"
            @click="updateStatus(row)">{{ row.status === 0 ? '禁用' : '恢复'}}
        </el-button>
        <el-button
            size="small"
            type="text"
            @click="handleRole(row.adminRoleId)"
        >超管权限
        </el-button>
        <el-button
            size="small"
            icon="el-icon-full-screen"
            type="text"
            @click="toEnterpriseEdit(row)"
        >扩展信息
        </el-button>
      </template>
    </avue-crud>

    <RoleBasic
        :forms="form"
        :permissions="permission"
        :roleList="list"
        :defaultKeys="defaultKeys"
        :pages="page"
        @cancel="cancel"
        v-if="box"
    ></RoleBasic>
  </basic-container>
</template>

<script>
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import { submit, updateStatus } from '@/api/sys/dept'
import { tree } from '@/api/sys/menu'
import { getByRole } from '@/api/sys/role'
import RoleBasic from '@/components/role/index'
import { getList } from '@/api/common'

export default {
  components: { RoleBasic },
  mixins: [mixin],
  data () {
    return {
      module: 'sys/dept',
      box: false,
      list: [],
      roleId: undefined,
      defaultObj: [],
      defaultKeys: [],
      form: {
        type: 1
      },
      search: {
        type: 1
      },
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        border: true,
        viewBtn: false,
        delBtn: false,
        searchMenuSpan: 5,
        menuWidth: 300,
        labelWidth: 120,
        column: [
          {
            label: '公司名称',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入公司名称'
            }]
          },
          {
            label: '公司全称',
            prop: 'fullName',
            search: true,
            rules: [{
              required: true,
              message: '请输入公司全称'
            }]
          },
          {
            label: '所属租户',
            prop: 'tenant',
            type: 'select',
            dicUrl: '/sys/tenant/dic?unUsed=false',
            search: true,
            hide: true,
            editDisplay: false,
            addDisplay: false
          },
          {
            label: '所属租户',
            prop: 'tenantId',
            type: 'select',
            dicUrl: '/sys/tenant/dic',
            editDisplay: false,
            rules: [{
              required: true,
              message: '请选择公司域名'
            }]
          },
          {
            label: '超管手机',
            prop: 'mobile',
            maxlength: 11,
            showWordLimit: true,
            search: true,
            rules: [{
              required: true,
              message: '请输入超管手机号码'
            }]
          },
          {
            label: '是否禁用',
            prop: 'status',
            dicUrl: '/sys/dict/code/yes_no',
            dataType: 'number',
            type: 'select',
            addDisplay: false,
            editDisplay: false,
            search: true,
            rules: [{
              required: true,
              message: '请选择状态'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            span: 24,
            hide: true
          }
        ]
      },
      data: []
    }
  },
  watch: {
    box: {
      handler (val) {
        if (val === false) {
          this.defaultKeys = []
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_dept_add, false),
        viewBtn: this.vaildData(this.permission.sys_dept_view, false),
        delBtn: this.vaildData(this.permission.sys_dept_delete, false),
        editBtn: this.vaildData(this.permission.sys_dept_edit, false)
      }
    },
    tagList () {
      return this.$store.getters.tagList
    }
  },
  created () {
    this.duplicate()
  },
  methods: {
    onLoad (page) {
      if (this.search.tenant !== undefined) {
        this.search.tenantId = this.search.tenant
      }
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
        this.$refs.crud.updateDic('tenantId')
      })
    },
    updateStatus (row) {
      const type = row.status === 0 ? '禁用' : '恢复'
      this.$confirm('确定' + type + '当前公司？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const status = row.status === 0 ? 1 : 0
          return updateStatus(row.id, status)
        })
        .then((res) => {
          if (res.success) {
            this.onLoad(this.page)
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
    },
    rowSave (row, done, loading) {
      row.type = 1
      submit(row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    duplicate () {
      this.tagList.forEach((item) => {
        if (item.value.includes('/base/enterprise')) {
          this.$store.commit('DEL_TAG', item)
        }
      })
    },
    toEnterpriseEdit (e) {
      this.$router.push({ path: '/base/enterprise', query: { title: e.fullName + '信息扩展', companyId: e.id }})
    },
    searchReset () {
      this.search = {
        type: 1
      }
      this.onLoad(this.page)
    },
    cancel () {
      this.box = false
    },
    handleRole (id) {
      this.roleId = id
      // 修复点击修改权限，已选中项的bug
      this.defaultObj = []
      this.form.id = id
      tree(null, id, 0)
        .then(res => {
          this.list = res.data
          this.updateObj(res.data)
          return getByRole(id)
        })
        .then(res => {
          this.defaultObj = res.data
          this.box = true
        })
    },
    updateObj (obj) {
      const defaultKeys = this.defaultKeys
      for (const key in obj) {
        if (key === 'checked' && obj[key] === true) {
          this.$set(this, 'defaultKeys', defaultKeys.concat(obj.id))
        }
        if (typeof obj[key] === 'object') {
          this.updateObj(obj[key])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
