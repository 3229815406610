import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/mall/goods/fix/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/goods/fix/submit',
    method: 'post',
    data: row
  })
}

