<template>
    <basic-container>
        <avue-crud
                ref="crud"
                v-model="form"
                :before-open="beforeOpen"
                :data="data"
                :option="option"
                :page.sync="page"
                :permission="permissionList"
                :search.sync="search"
                @row-del="rowDel"
                @row-update="rowUpdate"
                @row-save="rowSave"
                @search-change="searchChange"
                @search-reset="searchReset"
                @refresh-change="refreshChange"
                @selection-change="selectionChange"
                @on-load="onLoad">
            <template slot="menuLeft">
                <el-button
                        v-if="permission.base_vendor_delete"
                        icon="el-icon-delete"
                        size="small"
                        type="danger"
                        @click="handleDelete">删 除
                </el-button>
                <el-upload
                        :auto-upload="false"
                        :on-change='importPlatExcel'
                        :show-file-list="false"
                        action="#"
                        class="filter-item"
                        size="small"
                        style="display: inline-block">
                    <el-button icon="el-icon-top" type="warning">导 入</el-button>
                </el-upload>
                <el-button plain type="warning" @click.stop="handleVendorModel()">模板下载</el-button>
                <el-button
                        icon="el-icon-bottom"
                        size="small"
                        type="success"
                        @click.prevent="exportVendor"
                >导 出
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { platExportExcel, platModelExcel } from '@/api/base/vendor'
import { importExcel } from '@/api/common'

import { oss } from '@/config'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/vendor',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        selection: true,
        indexLabel: '序号',
        searchMenuSpan: 150,
        searchLabelWidth: 100,
        column: [
          {
            label: '供应商全称',
            prop: 'name',
            search: true,
            labelWidth: 100,
            width: 130,
            rules: [{
              required: true,
              message: '请输入供应商全称'
            }]
          },
          {
            label: '供应商简称',
            prop: 'shortName',
            search: true,
            labelWidth: 100,
            width: 100,
            rules: [{
              required: true,
              message: '请输入供应商简称'
            }]
          },
          {
            label: '地址',
            prop: 'address',
            labelWidth: 100
          },
          {
            label: '电话',
            prop: 'mobile',
            maxlength: 11,
            width: 130,
            showWordLimit: true,
            labelWidth: 100
          },
          {
            label: '相关凭证图片',
            prop: 'pic',
            type: 'upload',
            listType: 'picture-card',
            dataType: 'string',
            oss: oss,
            tip: '上传图片不超过500kb',
            labelWidth: 100
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_vendor_add,
        viewBtn: this.permission.base_vendor_view,
        delBtn: this.permission.base_vendor_delete,
        editBtn: this.permission.base_vendor_edit
      }
    }
  },
  created () {
    this.$store.dispatch('loadAli', 'zhsn-market-file')
  },
  methods: {
    handleVendorModel () {
      platModelExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '供应商信息模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    exportVendor () {
      platExportExcel(this.search).then(res => {
        FileSaver.saveAs(res, '供应商信息导出.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    importPlatExcel (file) {
      importExcel(this.module, file).then(res => {
        const that = this
        if (res.type === 'application/json') {
          const reader = new FileReader()
          reader.readAsText(res, 'utf-8')
          reader.onload = function () {
            res = JSON.parse(reader.result)
            if (res.success) {
              that.$message({
                type: 'success',
                message: '导入完成!'
              })
              that.onLoad(that.page)
            } else {
              that.$message({
                type: 'error',
                message: res.msg
              })
            }
          }
        } else {
          this.$message.warning('导入有部分失败数据，请查看导出的失败数据')
          that.onLoad(that.page)
          FileSaver.saveAs(res, '导入失败数据.xlsx')
        }
      })
    }
  }
}
</script>
