<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    const hour = (rule, value, callback) => {
      if (value >= 24) {
        callback(new Error('必须小于24'))
      } else {
        callback()
      }
    }
    const min = (rule, value, callback) => {
      if (value >= 60) {
        callback(new Error('必须小于60'))
      } else {
        callback()
      }
    }
    return {
      module: 'control/controlmarketinfo',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '市场名称',
            prop: 'marketId',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            search: true,
            rules: [{
              required: true,
              message: '请输入所属市场'
            }]
          },
          {
            label: '监控内容',
            prop: 'ctrCcontentList',
            type: 'checkbox',
            overHidden: true,
            span: 24,
            dicUrl: '/control/controluserinfo/ctrContentType',
            rules: [{
              required: true,
              message: '请选择监控内容'
            }]
          },
          {
            label: '开始小时',
            addDisplay: false,
            hide: true,
            prop: 'startHour',
            rules: [{
              required: true,
              validator: hour,
              trigger: 'blur'
            }]
          },
          {
            label: '开始分钟',
            addDisplay: false,
            hide: true,
            prop: 'startMinute',
            rules: [{
              required: true,
              validator: min,
              trigger: 'blur'
            }]
          },
          {
            label: '结束小时',
            addDisplay: false,
            hide: true,
            prop: 'endHour',
            rules: [{
              required: true,
              validator: hour,
              trigger: 'blur'
            }]
          },
          {
            label: '结束分钟',
            addDisplay: false,
            hide: true,
            prop: 'endMinute',
            rules: [{
              required: true,
              validator: min,
              trigger: 'blur'
            }]
          },

          {
            label: '状态',
            prop: 'status',
            type: 'select',
            clearable: false,
            dataType: 'number',
            dicData: [{
              label: '正常',
              value: '0'
            }, {
              label: '禁用',
              value: '1'
            }],
            search: true,
            addDisplay: false
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisabled: true,
            prop: 'createTime'
          },
          {
            label: '更新时间',
            addDisplay: false,
            editDisabled: true,
            prop: 'updateTime'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.control_controlmarketinfo_add,
        viewBtn: this.permission.control_controlmarketinfo_view,
        delBtn: this.permission.control_controlmarketinfo_delete,
        editBtn: this.permission.control_controlmarketinfo_edit
      }
    }
  },
  methods: {
  }
}
</script>
