<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.sys_cert_delete"
            icon="el-icon-delete"
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/cert',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        align: 'center',
        indexLabel: '序号',
        column: [
          {
            label: '阿里云账号',
            prop: 'account',
            width: 180,
            rules: [{
              required: true,
              message: '请输入阿里云账号'
            }]
          },
          {
            label: '域名',
            prop: 'domain',
            width: 180,
            rules: [{
              required: true,
              message: '请输入域名'
            }]
          },
          {
            label: '生效日期',
            prop: 'availableDate',
            type: 'datatime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            width: 150,
            row: true,
            rules: [{
              required: true,
              message: '请设置生效时间'
            }]
          },
          {
            label: '失效日期',
            prop: 'expireDate',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            width: 150,
            row: true,
            rules: [{
              required: true,
              message: '请设置失效时间'
            }]
          },
          {
            label: '负责人手机',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入负责人手机'
            }]
          },
          {
            label: '负责人unionid',
            prop: 'unionid',
            rules: [{
              required: true,
              message: '请输入负责人unionid'
            }]
          },
          {
            label: '负责人openid',
            prop: 'openid',
            rules: [{
              required: true,
              message: '请输入负责人openid'
            }]
          },
          {
            label: '启用状态',
            prop: 'status',
            type: 'switch',
            dicData: [
              { label: '否', value: 0 },
              { label: '是', value: 1 }
            ],
            value: 1
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.sys_cert_add,
        viewBtn: this.permission.sys_cert_view,
        delBtn: this.permission.sys_cert_delete,
        editBtn: this.permission.sys_cert_edit
      }
    }
  },
  methods: {}
}
</script>
