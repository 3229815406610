<template>
  <div>
    <basic-container>
    </basic-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { initPwd } from '@/api/sys/user'
import { minio } from '@/api/oss'
import axios from 'axios'

export default {
  name: 'Index',
  data () {
    const validatepwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    const validateRestPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      imgUrl: null,
      loading: false,
      progress: 0,
      show: false,
      form: {},
      option: {
        emptyBtn: false,
        enter: true,
        menuSpan: 20,
        menuPosition: 'right',
        submitText: '保 存',
        column: [
          {
            label: '密码',
            span: 18,
            row: true,
            type: 'password',
            prop: 'pwd',
            placeholder: '密码至少六位',
            rules: [{
              required: true,
              validator: validatepwd,
              trigger: 'blur'
            }]
          }, {
            label: '确认密码',
            span: 18,
            row: true,
            type: 'password',
            prop: 'restPwd',
            placeholder: '密码至少六位',
            rules: [{
              required: true,
              validator: validateRestPwd,
              trigger: 'blur'
            }]
          }]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    tagList () {
      return this.$store.getters.tagList
    }
  },
  created () {
    if (this.$route.query.active) {
      this.show = true
    }
  },
  methods: {
    toUpload (file) {
      minio(file.file.name, 'market-scale-rec').then(res => {
        const url = res.data
        this.loading = true
        // 将文件上传到后端返回的minio服务器
        axios.put(url, file.file, {
          headers: { 'Content-Type': file.file.type }, onUploadProgress: this.onUploadProgress
        }).then(r => {
          this.imgUrl = url.split('?')[0]
          this.loading = false
          console.log(r)
        })
      })
      return true
    },
    onUploadProgress (progressEvent) {
      this.progress = ((progressEvent.loaded * 100) / progressEvent.total).toFixed(2)
    },
    async submit (form, done) {
      const res = await initPwd(form.restPwd)
      console.log(res)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '修改密码成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
      done()
      this.show = false
      this.duplicate()
      this.$router.push('/index')
    },
    duplicate () {
      this.tagList.forEach((item) => {
        if (item.value.includes('/index?')) {
          this.$store.commit('DEL_TAG', item)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
</style>
