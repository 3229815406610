import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/base/enterprise/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (params) => {
  return request({
    url: '/base/enterprise/detail',
    method: 'post',
    data: params
  })
}

export const remove = (ids) => {
  return request({
    url: '/base/enterprise/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/base/enterprise/submit',
    method: 'post',
    data: row
  })
}

export const recognize = (url) => {
  return request({
    url: '/base/enterprise/ocr',
    method: 'post',
    params: {
      url
    }
  })
}
export const oss = (file, bucket, prefix) => {
  return request({
    url: '/base/enterprise/oss',
    method: 'post',
    params: {
      file,
      bucket,
      prefix
    }
  })
}

