import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/mall/level3/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/level3/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/level3/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/level3/submit',
    method: 'post',
    data: row
  })
}

export const getBig = (current, size, params) => {
  return request({
    url: '/mall/level1/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getMiddle = (current, size, params) => {
  return request({
    url: '/mall/level2/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getStandard = (current, size, params) => {
  return request({
    url: '/mall/level3/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const removeBig = (ids) => {
  return request({
    url: '/mall/level1/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const removeMiddle = (ids) => {
  return request({
    url: '/mall/level2/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const removeStandard = (ids, level2Id) => {
  return request({
    url: '/mall/level3/remove',
    method: 'post',
    params: {
      ids,
      level2Id
    }
  })
}

export const submitBig = (row) => {
  return request({
    url: '/mall/level1/submit',
    method: 'post',
    data: row
  })
}

export const submitMiddle = (row) => {
  return request({
    url: '/mall/level2/submit',
    method: 'post',
    data: row
  })
}

export const submitStandard = (row) => {
  return request({
    url: '/mall/level3/submit',
    method: 'post',
    data: row
  })
}

export const isValidBigName = (bigName, id) => {
  return request({
    url: '/mall/level1/isValid',
    method: 'get',
    params: {
      bigName,
      id
    }
  })
}

export const isValidMiddleName = (middleName, id) => {
  return request({
    url: '/mall/level2/isValid',
    method: 'get',
    params: {
      middleName,
      id
    }
  })
}

export const isValidStandardName = (standardName, id) => {
  return request({
    url: '/mall/level3/isValid',
    method: 'get',
    params: {
      standardName,
      id
    }
  })
}

