<template>
  <basic-container>
    <avue-crud
        :rules="roleRules"
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :page.sync="page"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            v-if="permission.sys_role_add"
            @click="addRow">新增
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.sys_role_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>

      <template slot-scope="{row}" slot="menu">
        <el-button
            v-if="permission.sys_role_edit"
            size="small"
            type="text"
            @click="handleRole(row)">权限
        </el-button>
      </template>
    </avue-crud>
    <RoleBasic
        :forms="form"
        :permissions="permission"
        :roleList="list"
        :defaultKeys="defaultKeys"
        :pages="page"
        @cancel="cancel"
        v-if="box"
    ></RoleBasic>
  </basic-container>
</template>

<script>
import { getByRole, isValidateCode } from '@/api/sys/role'
import { tree } from '@/api/sys/menu'
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import RoleBasic from '@/components/role/index'

export default {
  components: { RoleBasic },
  mixins: [mixin],
  data () {
    const validateCode = (rule, value, callback) => {
      const id = this.form.id
      isValidateCode(value, id).then(res => {
        const data = res.data
        if (!data) {
          callback(new Error('角色编码不能重复'))
        } else {
          callback()
        }
      })
    }
    return {
      roleRules: {
        code: [{ required: true, trigger: 'blur', validator: validateCode }]
      },
      module: 'sys/role',
      form: {},
      box: false,
      props: {
        label: 'name',
        value: 'id'
      },
      list: [],
      defaultObj: [],
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        addBtn: false,
        viewBtn: false,
        cellBtn: true,
        indexLabel: '序号',
        searchMenuSpan: 5,
        column: [
          {
            label: '角色名称',
            prop: 'name',
            search: true,
            span: 24,
            cell: true,
            rules: [
              {
                required: true,
                message: '请输入角色名称'
              }
            ]
          },
          {
            label: '角色编码',
            prop: 'code',
            search: true,
            span: 24,
            cell: true,
            rules: [
              {
                required: true,
                validator: validateCode
              }
            ]
          }
        ]
      },
      data: [],
      defaultKeys: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_role_add, false),
        viewBtn: this.vaildData(this.permission.sys_role_view, false),
        delBtn: this.vaildData(this.permission.sys_role_delete, false),
        editBtn: this.vaildData(this.permission.sys_role_edit, false)
      }
    }
  },
  created () {
  },
  watch: {
    box: {
      handler (val) {
        if (val === false) {
          this.defaultKeys = []
        }
      },
      deep: true
    }
  },
  methods: {
    cancel () {
      this.box = false
    },
    handleRole (row) {
      // 修复点击修改权限，已选中项的bug
      if (!row.id) {
        this.$message({
          type: 'error',
          message: '请编辑保存角色信息!',
          duration: 2000
        })
      } else {
        this.defaultObj = []
        this.form.id = row.id
        tree(null, row.id)
          .then(res => {
            this.list = res.data
            this.updateObj(res.data)
            return getByRole(row.id)
          })
          .then(res => {
            this.defaultObj = res.data
            this.box = true
          })
      }
    },
    updateObj (obj) {
      const defaultKeys = this.defaultKeys
      for (const key in obj) {
        if (key === 'checked' && obj[key] === true) {
          this.$set(this, 'defaultKeys', defaultKeys.concat(obj.id))
        }
        if (typeof obj[key] === 'object') {
          this.updateObj(obj[key])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
