<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            size="small"
            icon="el-icon-rank"
            v-if="permission.malleyes_camerainfo_init"
            @click="handleInit">更新客流仪
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { init } from '@/api/crm/malleyescamerainfo'

export default {
  mixins: [mixin],
  name: 'MallEyes',
  data () {
    return {
      module: '/malleyes/camerainfo',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        delBtn: false,
        addBtn: false,
        viewBtn: false,
        editBtn: false,
        menu: false,
        index: true,
        selection: true,
        indexLabel: '序号',
        column: [
          {
            label: '公司名称',
            editDisabled: true,
            prop: 'companyName'
          },
          {
            label: '市场名称',
            editDisabled: true,
            prop: 'mallName'
          },
          {
            label: '摄像头位置',
            prop: 'gateList',
            editDisplay: false,
            overHidden: true,
            formatter: function (row, column, cellValue, index) {
              if (cellValue.length === 0) {
                return null
              }
              const value = JSON.parse(cellValue)
              if (value.length > 0) {
                let str = null
                value.forEach(res => {
                  if (str === null) {
                    str = res
                  } else {
                    str = str + ',' + res
                  }
                })
                return str
              }
              return null
            }
          },
          {
            label: '市场摄像头',
            prop: 'cameraList',
            editDisplay: false,
            overHidden: true,
            formatter: function (row, column, cellValue, index) {
              if (cellValue.length === 0) {
                return null
              }
              const value = JSON.parse(cellValue)
              if (value.length > 0) {
                let str = null
                value.forEach(res => {
                  if (str === null) {
                    str = res
                  } else {
                    str = str + ',' + res
                  }
                })
                return str
              }
              return null
            }
          },
          {
            label: '设备绑定',
            prop: 'marketId',
            search: true,
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dept/dict/market',
            formatter: function (row, column, cellValue, index) {
              if (cellValue === '0') {
                return '未绑定'
              } else {
                return cellValue
              }
            }
          },
          {
            label: '修改日期',
            prop: 'updateTime',
            editDisabled: true
          },
          {
            label: '创建时间',
            editDisabled: true,
            prop: 'createTime'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        editBtn: this.permission.malleyes_camerainfo_edit
      }
    }
  },
  methods: {
    async handleInit () {
      const res = await init()
      if (res.code === 200) {
        this.onLoad(this.page)
        this.$message.success('初始化成功')
      } else {
        this.$message.error('初始化失败')
      }
    }
  }
}
</script>
