<template>
  <div>
    <el-card class="good-card">
      <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline-message="true"
          label-position="right"
          label-width="150px"
          class="small-space form-one">
        <el-form-item label="批次：" prop="type">
          <el-input v-model="form.batchNo" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="市场：" prop="deptId">
          <avue-select v-model="form.deptId" :dic="markets"></avue-select>
        </el-form-item>
        <el-form-item label="选择设备：" prop="purchasePrice">
          <avue-crud
              :option="option"
              :data="data"
              :page="page"
              :search.sync="search"
              ref="crud"
              @current-change="currentChangeDevices"
              @search-change="searchDevice"
              @selection-change="selectDevice"
              @on-load="loadDevices">
            <template slot="menuLeft">
                <el-tooltip content="请输入sn，多个以,间隔，例如001,003，表示2个SN；若为SN段则可以以~间隔，例如001~003，表示3个SN；也可以导入SN。" placement="top">
                  <el-input
                      placeholder="请输入sn，多个以,间隔，例如001,003，表示2个SN；若为SN段则可以以~间隔，例如001~003，表示3个SN；也可以导入SN。"
                      type="textarea"
                      v-model="snText"
                      class="sn-textarea"
                      :rows="4"></el-input>
                </el-tooltip>
                <el-button type="primary" :disabled="!this.data.length" @click.stop="handleCheckSns()">确认SN</el-button>
                <el-button type="warning" :disabled="!this.data.length" icon="el-icon-download" @click.stop="handleModel()">模板下载</el-button>
                <el-upload
                    class="filter-item"
                    :show-file-list="false"
                    :action="handleReadSn()"
                    :headers="uploadHeaders()"
                    :on-success="uploadSuc">
                  <el-button type="success" :disabled="!this.data.length" icon="el-icon-upload2">导入SN</el-button>
                </el-upload>
            </template>
          </avue-crud>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="!this.data.length" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import { getMarkets } from '@/api/sys/dept'
import { getDetail, sendOut } from '@/api/crm/devicepurchase'
import { getList } from '@/api/crm/device'
import { baseUrl } from '@/config'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/devicepurchase',
      loading: {
        form: false
      },
      form: {
        type: undefined,
        brand: undefined,
        model: undefined,
        marketId: undefined
      },
      rules: {
        deptId: [
          { required: true, message: '请选择市场', trigger: 'blur' }
        ]
      },
      markets: [],
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      search: {},
      divisionIds: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
        pageSizes: [20, 30, 50, 100],
        deviceList: []
      },
      option: {
        menu: false,
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        reserveSelection: true,
        align: 'center',
        column: [
          {
            label: '设备类型',
            prop: 'typeId',
            type: 'select',
            select: true,
            dicUrl: '/crm/devicetype/getDict'
          },
          {
            label: '品牌',
            prop: 'brandId',
            type: 'select',
            select: true,
            filterable: true,
            dicUrl: '/crm/devicebrand/getDict'
          },
          {
            label: '型号',
            prop: 'modelId',
            type: 'select',
            select: true,
            dicUrl: '/crm/devicemodel/getDict'
          },
          {
            label: '设备sn',
            prop: 'sn'
          }
        ]
      },
      data: [],
      snText: ''
    }
  },
  created () {
    this.form.id = this.$route.query.id
    this.form.level = this.$route.query.level
    if (this.form.id) {
      this.loadData(this.page)
    }
    getMarkets().then(res => {
      if (res.success) {
        this.markets = res.data
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  methods: {
    handleReadSn () {
      return baseUrl + '/' + this.module + '/read/sns'
    },
    uploadSuc (res) {
      if (res.success) {
        const snsStr = res.data
        this.toggleSelection(snsStr)
      } else {
        this.$message({ message: res.msg, type: 'error' })
      }
    },
    handleCheckSns () {
      this.toggleSelection(this.snText)
    },
    toggleSelection (val) {
      if (!val) {
        return
      }
      val = val.replaceAll('，', ',')
      const index1 = val.indexOf(',')
      const index2 = val.indexOf('~')
      if (index1 >= 0 && index2 >= 0) {
        this.$message.error('不能同时输入多个SN与SN段')
        return
      }
      let sns
      const selected = []
      if ((index1 < 0 && index2 < 0) || index1 >= 0) {
        sns = val.split(',')
        sns.forEach(sn => {
          const index = this.data.findIndex(item => item.sn === sn)
          if (index > -1) {
            selected.push(this.data[index])
          }
        })
      } else {
        sns = val.split('~')
        sns = sns.sort()
        this.data.forEach(item => {
          if (sns[0] <= item.sn && sns[1] >= item.sn) {
            console.log(item.sn)
            selected.push(item)
          }
        })
      }
      this.$refs.crud.toggleSelection(selected)
    },
    selectDevice (list) {
      this.divisionIds = list
    },
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    currentChangeDevices (val) {
      this.page.currentPage = val
      // this.loadDevices(this.page)
    },
    loadDevices (page) {
      if (this.form.id) {
        const deviceSearch = {}
        deviceSearch.purchaseId = this.form.id
        if (this.form.level === '1') {
          deviceSearch.isLeaf = false
        } else {
          deviceSearch.isLeaf = true
        }
        deviceSearch.stockStatus = 1
        getList(page.currentPage, page.pageSize, deviceSearch).then(res => {
          if (res.success) {
            this.page.total = res.data.total
            this.data = res.data.records
            if (!res.data.total) {
              this.$message.warning('设备已拆单入库，请更换下级采购单进行出库！！！')
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    searchDevice (form, done) {
      this.loadDevices()
      done()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.deviceList = this.divisionIds
          sendOut(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/crm/devicepurchase' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/crm/devicepurchase' })
    }
  }
}
</script>

<style>
.form-one {
  width: 100%;
}

.sn-textarea {
  width: 500px;
  margin-right: 20px;
}
</style>
